import { RumInitConfiguration } from '@datadog/browser-rum';
import DataWithEmpty from 'types/DataWithEmpty';

export const DATADOG_APP_ID = process.env.NEXT_PUBLIC_DATADOG_APP_ID ?? '';
export const DATADOG_CLIENT_TOKEN =
  process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN ?? '';
export const DATADOG_SERVICE = process.env.NEXT_PUBLIC_DATADOG_SERVICE ?? '';

const DATADOG_CONFIG: DataWithEmpty<RumInitConfiguration> =
  DATADOG_APP_ID && DATADOG_CLIENT_TOKEN && DATADOG_SERVICE
    ? {
        applicationId: DATADOG_APP_ID,
        clientToken: DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: DATADOG_SERVICE,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      }
    : undefined;

export default DATADOG_CONFIG;
