import { useWallet } from '@bifrost-platform/bifront-sdk-react-wallet';
import styled from '@emotion/styled';
import { useAtom } from 'jotai';
import { useTranslation } from 'next-i18next';
import { useCallback, useRef } from 'react';
import { ChainImage } from 'components/styled/tokenImage';
import CHAINS from 'configs/chains';
import useChainImageMap from 'hooks/api/biholder/useChainImageMap';
import useIsMounted from 'hooks/useIsMounted';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { changeChain } from 'lib/contract/dex';
import { isShowSelectChainAtom } from 'store/ui';
import Chain from 'types/Chain';
import { sortOrderedList } from 'utils/orderable';
import PopupContainer from './PopupContainer';
import PopupList, { PopupListItem as OriginPopupListItem } from './PopupList';
import PopupTitle from './PopupTitle';

const Root = styled(PopupContainer)`
  width: 200px;
`;
const PopupListItem = styled(OriginPopupListItem)`
  justify-content: flex-start;
  text-align: left;
  gap: 8px;
`;

const PopupSelectChain: React.FC = () => {
  const { t } = useTranslation('common');

  const { chainImageMap } = useChainImageMap();

  const { wallet } = useWallet();

  const ref = useRef(null);
  const [isShowSelectChain, setShowSelectChain] = useAtom(
    isShowSelectChainAtom
  );

  const isMounted = useIsMounted(isShowSelectChain);

  useOnClickOutside(ref, () => setShowSelectChain(false));

  const handleClick = useCallback(
    (chain: Chain) => async () => {
      changeChain(wallet, chain);
      setShowSelectChain(false);
    },
    [setShowSelectChain, wallet]
  );

  if (!isMounted) {
    return <></>;
  }

  return (
    <Root ref={ref} isShowing={isShowSelectChain}>
      <PopupTitle>{t('chain')}</PopupTitle>
      <PopupList>
        {sortOrderedList<Chain>(CHAINS).map((chain) => (
          <PopupListItem onClick={handleClick(chain)} key={chain.id}>
            <ChainImage size={24} image={chainImageMap[`${chain.id}`]} />
            {chain.name}
          </PopupListItem>
        ))}
      </PopupList>
    </Root>
  );
};

export default PopupSelectChain;
