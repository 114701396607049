import { isNormalPositive } from '@bifrost-platform/bifront-sdk-react-wallet';
import { getAnalytics } from 'firebase/analytics';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import FIREBASE_CONFIG, {
  FIREBASE_ERROR_CODE_NUMBER,
  FIREBASE_ERROR_CODE_PARSE_FAIL,
  FIREBASE_ERROR_CODE_STRING,
  FIREBASE_ERROR_CODE_UNKNOWN,
  FIREBASE_ERROR_MESSAGE_PARSE_FAIL,
  FIREBASE_ERROR_MESSAGE_UNKNOWN,
} from 'configs/firebase';
import ChainrunnerResponse, {
  ChainrunnerErrorResponse,
} from 'types/api/chainrunner/ChainrunnerResponse';
import { UserActionError } from 'types/firestore/FirebaseUserAction';

export const isUsableFirebase = () =>
  !!(typeof window === 'object' && window && FIREBASE_CONFIG);

export const getInitializedFirebase = () =>
  FIREBASE_CONFIG ? initializeApp(FIREBASE_CONFIG) : undefined;

export const getInitializedFirestore = (firebase: FirebaseApp) =>
  getFirestore(firebase);

export const getInitializedAnalytics = (firebase: FirebaseApp) =>
  getAnalytics(firebase);

export const parseUserActionError = (error?: any): UserActionError => {
  const result: UserActionError = {
    code: 0,
    message: '',
  };

  try {
    if (error) {
      if (typeof error === 'number') {
        result.code = FIREBASE_ERROR_CODE_NUMBER;
        result.message = `${error}`;
      } else if (typeof error === 'string') {
        result.code = FIREBASE_ERROR_CODE_STRING;
        result.message = error;
      } else if (typeof error === 'object') {
        if (isNormalPositive(error)) {
          result.code = FIREBASE_ERROR_CODE_NUMBER;
          result.message = `${error}`;
        } else if (
          (error as UserActionError)?.code &&
          (error as UserActionError)?.message
        ) {
          result.code = error?.code ?? FIREBASE_ERROR_CODE_UNKNOWN;
          result.message = error?.message ?? FIREBASE_ERROR_MESSAGE_UNKNOWN;
        } else if (
          (error as ChainrunnerErrorResponse)?.code &&
          (error as ChainrunnerErrorResponse)?.log
        ) {
          result.code = parseFloat(
            `${error?.code ?? FIREBASE_ERROR_CODE_UNKNOWN}`
          );
          result.message = error?.log ?? FIREBASE_ERROR_MESSAGE_UNKNOWN;
        } else if ((error as ChainrunnerResponse<boolean>)?.result?.error) {
          result.code = parseFloat(`${error?.result?.error?.code}`);
          result.message =
            error?.result?.error?.log ?? FIREBASE_ERROR_MESSAGE_UNKNOWN;
        }
      }

      if (!(result.code || result.message)) {
        result.code = FIREBASE_ERROR_CODE_UNKNOWN;
        result.message = FIREBASE_ERROR_MESSAGE_UNKNOWN;
      }
    }
  } catch (e) {
    result.code = FIREBASE_ERROR_CODE_PARSE_FAIL;
    result.message = FIREBASE_ERROR_MESSAGE_PARSE_FAIL.replace(
      '{0}',
      `${e}`
    ).replace('{1}', `${error}`);
  }

  return result;
};
