import { useWallet } from '@bifrost-platform/bifront-sdk-react-wallet';
import { ThemeProvider } from '@emotion/react';
import styled from '@emotion/styled';
import { useAtom } from 'jotai';
import { PropsWithChildren, useCallback, useEffect } from 'react';
import DialogLpDeposit from 'components/dialog/DialogLpDeposit';
import DialogLpStakeClaim from 'components/dialog/DialogLpStakeClaim';
import DialogLpStakeCta from 'components/dialog/DialogLpStakeCta';
import DialogLpStakeWithdraw from 'components/dialog/DialogLpStakeWithdraw';
import DialogPoolDepositCta from 'components/dialog/DialogPoolDepositCta';
import DialogSwapSetting from 'components/dialog/DialogSwapSetting';
import DialogEventNoticeLpReward from 'components/dialog/eventNotice/DialogEventNoticeLpReward';
import usePoolPositions from 'hooks/api/everdex/usePoolPositions';
import usePools from 'hooks/api/everdex/usePools';
import useConnectionErrorContent from 'hooks/useConnectionErrorContent';
import useEventNotice from 'hooks/useEventNotice';
import useLastLogin from 'hooks/useLastLogin';
import useNotice from 'hooks/useNotice';
import { deadlineAtom, slippageAtom } from 'store/swap';
import {
  isOpenDialogLpStakeCtaAtom,
  dialogPoolDepositCtaAtom,
  isOpenDialogSwapSettingAtom,
  dialogLpDepositAtom,
  dialogLpStakeClaimAtom,
  dialogLpStakeWithdrawAtom,
  isOpenDialogEventNoticeLpRewardAtom,
} from 'store/ui';
import theme from 'styles/theme';
import Header from './header';

export type Props = PropsWithChildren & {};

const Body = styled.div`
  width: 100%;
  min-width: 1200px;
`;
const Content = styled.div<{ isError?: boolean; isNotice?: boolean }>`
  width: 100%;
  min-height: 100vh;
  padding-top: ${({ isError, isNotice }) =>
    120 + (isError ? 56 : 0) + (isNotice ? 80 : 0)}px;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0 auto;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  transition: padding-top ${({ theme }) => theme.transition.single};
`;

const Layout: React.FC<Props> = ({ children }) => {
  const lastLogin = useLastLogin();

  const connectionErrorContent = useConnectionErrorContent();

  const { notice, closeNotice } = useNotice();

  const { eventNotices, closeEventNotice } = useEventNotice();

  const { sync: syncPools } = usePools();
  const { sync: syncPoolPositions } = usePoolPositions();

  const { account } = useWallet();

  const [isOpenDialogSwapSetting, setIsOpenedDialogSwapSetting] = useAtom(
    isOpenDialogSwapSettingAtom
  );
  const [isOpenDialogLpStakeCta, setIsOpenedDialogLpStakeCta] = useAtom(
    isOpenDialogLpStakeCtaAtom
  );
  const [dialogPoolDepositCta, setDialogPoolDepositCta] = useAtom(
    dialogPoolDepositCtaAtom
  );
  const [dialogLpDeposit, setDialogLpDeposit] = useAtom(dialogLpDepositAtom);
  const [dialogLpStakeClaim, setDialogLpStakeClaim] = useAtom(
    dialogLpStakeClaimAtom
  );
  const [dialogLpStakeWithdraw, setDialogLpStakeWithdraw] = useAtom(
    dialogLpStakeWithdrawAtom
  );
  const [isOpenDialogEventNoticeLpReward, setIsOpenDialogEventNoticeLpReward] =
    useAtom(isOpenDialogEventNoticeLpRewardAtom);

  const handleDialogSwapSettingClose = useCallback(
    () => setIsOpenedDialogSwapSetting(false),
    [setIsOpenedDialogSwapSetting]
  );
  const handleDialogLpStakeCtaClose = useCallback(
    () => setIsOpenedDialogLpStakeCta(false),
    [setIsOpenedDialogLpStakeCta]
  );
  const handleDialogPoolDepositCtaClose = useCallback(
    () => setDialogPoolDepositCta(undefined),
    [setDialogPoolDepositCta]
  );
  const handleDialogLpDepositClose = useCallback(
    () => setDialogLpDeposit(undefined),
    [setDialogLpDeposit]
  );
  const handleDialogLpStakeClaimClose = useCallback(
    () => setDialogLpStakeClaim(undefined),
    [setDialogLpStakeClaim]
  );
  const handleDialogLpStakeWithdrawClose = useCallback(
    () => setDialogLpStakeWithdraw(undefined),
    [setDialogLpStakeWithdraw]
  );
  const handleDialogEventNoticeLpRewardClose = useCallback(
    (e?: {}, reason?: string) => {
      setIsOpenDialogEventNoticeLpReward(false);

      if (reason === 'flowFinish') {
        closeEventNotice('lpReward');
      }
    },
    [closeEventNotice, setIsOpenDialogEventNoticeLpReward]
  );

  useEffect(() => {
    lastLogin();
  }, [lastLogin]);
  useEffect(() => {
    eventNotices.map((eventNotice) => {
      switch (eventNotice.id) {
        case 'lpReward':
          setIsOpenDialogEventNoticeLpReward(true);
          break;
      }
    });
  }, [eventNotices, setIsOpenDialogEventNoticeLpReward]);
  useEffect(() => {
    syncPools();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    syncPoolPositions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return (
    <ThemeProvider theme={theme}>
      <Body>
        <Header
          connectionErrorContent={connectionErrorContent}
          notice={notice}
          closeNotice={closeNotice}
        />
        <Content
          id="scroller"
          isError={!!connectionErrorContent}
          isNotice={!!notice}>
          {children}
        </Content>
      </Body>

      {/* dialog */}
      <DialogSwapSetting
        open={isOpenDialogSwapSetting}
        slippageAtom={slippageAtom}
        deadlineAtom={deadlineAtom}
        onClose={handleDialogSwapSettingClose}
      />
      <DialogLpStakeCta
        open={isOpenDialogLpStakeCta}
        onClose={handleDialogLpStakeCtaClose}
      />
      <DialogPoolDepositCta
        open={!!dialogPoolDepositCta}
        {...dialogPoolDepositCta}
        onClose={handleDialogPoolDepositCtaClose}
      />
      <DialogLpDeposit
        open={!!dialogLpDeposit}
        lpRewardPoolData={dialogLpDeposit}
        onClose={handleDialogLpDepositClose}
      />
      <DialogLpStakeClaim
        open={!!dialogLpStakeClaim}
        {...dialogLpStakeClaim}
        onClose={handleDialogLpStakeClaimClose}
      />
      <DialogLpStakeWithdraw
        open={!!dialogLpStakeWithdraw}
        {...dialogLpStakeWithdraw}
        onClose={handleDialogLpStakeWithdrawClose}
      />

      {/* event notice */}
      <DialogEventNoticeLpReward
        open={isOpenDialogEventNoticeLpReward}
        onClose={handleDialogEventNoticeLpRewardClose}
      />
    </ThemeProvider>
  );
};

export default Layout;
