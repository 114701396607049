import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

export type Props = {
  style?: React.CSSProperties;
  dotColor?: string;
};

const fx = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 16px 0;
`;

const Dot = styled.span<{ dotColor?: string }>`
  width: 8px;
  height: 8px;
  background-color: ${({ dotColor }) => dotColor ?? '#fff'};
  border-radius: 8px;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${fx};
  animation-timing-function: ${({ theme }) => theme.transition.timingFunction};
  box-shadow: 0px 2px 2px 0px #0000001a;
  transition: ${({ theme }) => theme.transition.all};

  &:nth-of-type(2) {
    animation-delay: 0.3s;
  }
  &:nth-of-type(3) {
    animation-delay: 0.6s;
  }
`;

const Loading: React.FC<Props> = ({ style, dotColor }) => (
  <Container style={style}>
    <Dot dotColor={dotColor} />
    <Dot dotColor={dotColor} />
    <Dot dotColor={dotColor} />
  </Container>
);

export default Loading;
