import { useEffect, useMemo } from 'react';
import useSWR from 'swr';
import { CE_ASSETS } from 'configs/chainrunner/chainrunnerEndpoint';
import CHAINRUNNER_PAYLOAD from 'configs/chainrunner/chainrunnerPayload';
import CHAINS from 'configs/chains';
import useChainrunnerNetworks from 'hooks/api/chainrunner/useChainrunnerNetworks';
import useIp from 'hooks/useIp';
import useWatchingAddressMap from 'hooks/useWatchingAddressMap';
import { fetcherChainrunnerArray } from 'lib/fetcher';
import SwrResult from 'types/SwrResult';
import TokensByChain from 'types/TokensByChain';
import Asset from 'types/api/chainrunner/Asset';
import ChainrunnerResponse from 'types/api/chainrunner/ChainrunnerResponse';
import {
  getChainrunnerParameters,
  parseAssetToToken,
  parseChainrunnerChainId,
} from 'utils/chainrunner';
import { compareSortingTokenFunction } from 'utils/orderable';

const useTokensByChains = (): SwrResult<TokensByChain[]> => {
  const ip = useIp();

  const { addWatchingAddress } = useWatchingAddressMap();

  const { data: chainrunnerNetworks } = useChainrunnerNetworks();

  const { data: rawData, error: rawError } = useSWR<
    ChainrunnerResponse<Asset[]>[]
  >(
    chainrunnerNetworks?.map((network) => ({
      ...CHAINRUNNER_PAYLOAD,
      endpoint: CE_ASSETS,
      userIP: ip,
      parameters: getChainrunnerParameters([
        parseChainrunnerChainId(network.chainId),
      ]),
    })),
    fetcherChainrunnerArray
  );

  const oksByChains = useMemo(
    () =>
      rawData ? rawData.map((assetsData) => !!assetsData?.result?.ok) : [false],
    [rawData]
  );
  const errorsByChains = useMemo(
    () =>
      rawData
        ? rawData.map((assetsData) => !!assetsData?.result?.error)
        : [true],
    [rawData]
  );
  const assetsByChains = useMemo(
    () =>
      rawData
        ? (rawData
            .map((assetsData) =>
              assetsData?.result?.result?.filter((result) => !!result)
            )
            .filter((assets) => !!assets) as Asset[][])
        : [],
    [rawData]
  );
  const hasError = useMemo(
    () =>
      !(
        oksByChains.find((ok) => !ok) || errorsByChains.find((error) => !!error)
      ),
    [errorsByChains, oksByChains]
  );

  const data = useMemo(
    () =>
      assetsByChains
        ? (assetsByChains
            .map((assetsByChain) => ({
              chain: assetsByChain[0].chainId
                ? CHAINS.find(
                    (chain) =>
                      chain.id ===
                      parseChainrunnerChainId(assetsByChain[0].chainId)
                  )
                : undefined,
              tokens: assetsByChain
                .map((asset) => parseAssetToToken(asset))
                .sort(compareSortingTokenFunction),
            }))
            .filter(
              (tokensByChain) => !!tokensByChain.chain
            ) as TokensByChain[])
        : [],
    [assetsByChains]
  );
  const isLoading = useMemo(() => !(rawError || rawData), [rawData, rawError]);
  const error = useMemo(
    () => (hasError ? errorsByChains : undefined),
    [errorsByChains, hasError]
  );

  useEffect(() => {
    if (data) {
      for (const tokensByChain of data) {
        for (const { address, chainId } of tokensByChain.tokens) {
          addWatchingAddress(address, chainId);
        }
      }
    }
  }, [addWatchingAddress, data]);

  return {
    data,
    isLoading,
    error,
  };
};

export default useTokensByChains;
