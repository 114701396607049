import ChainrunnerPayload from 'types/api/chainrunner/ChainrunnerPayload';
import { CE_NETWORKS } from './chainrunnerEndpoint';

export const CHAINRUNNER_PAYLOAD_TIMEBUDGET = 300;
export const CHAINRUNNER_PAYLOAD_USER_AGENT = 'EverDex';

const CHAINRUNNER_PAYLOAD: ChainrunnerPayload = {
  endpoint: CE_NETWORKS, // DEFAULT // require replace
  timeBudget: CHAINRUNNER_PAYLOAD_TIMEBUDGET,
  userAgent: CHAINRUNNER_PAYLOAD_USER_AGENT,
  userIP: '', // require replace
  parameters: [], // require replace
};

export default CHAINRUNNER_PAYLOAD;
