import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import { IconInfo } from 'components/icons';
import Tooltip from 'components/mui/Tooltip';

export type Props = PropsWithChildren & { size?: number };

const Root = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: help;
`;

const HelpBadge: React.FC<Props> = ({ size = 14, children }) => (
  <Tooltip header="Tips" content={children}>
    <Root>
      <IconInfo size={size} />
    </Root>
  </Tooltip>
);

export default HelpBadge;
