import {
  ICON_DEFAULT_COLOR,
  ICON_DEFAULT_SIZE,
  ICON_PROPERTY_TRANSFORM_REVERSE,
  ICON_STYLE_TRANSFORM_REVERSE,
} from 'configs/icons';
import { IconReversableProps } from 'types/IconProps';

export const IconArrow: React.FC<IconReversableProps> = ({
  size = ICON_DEFAULT_SIZE,
  color = ICON_DEFAULT_COLOR,
  isReverse,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    transform={isReverse ? ICON_PROPERTY_TRANSFORM_REVERSE : ''}
    style={{ transform: isReverse ? ICON_STYLE_TRANSFORM_REVERSE : '' }}
    xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M15.1258 21.1L6.70078 12.7C6.60078 12.6 6.52995 12.4917 6.48828 12.375C6.44661 12.2584 6.42578 12.1334 6.42578 12C6.42578 11.8667 6.44661 11.7417 6.48828 11.625C6.52995 11.5084 6.60078 11.4 6.70078 11.3L15.1258 2.87502C15.3591 2.64169 15.6508 2.52502 16.0008 2.52502C16.3508 2.52502 16.6508 2.65002 16.9008 2.90002C17.1508 3.15002 17.2758 3.44169 17.2758 3.77502C17.2758 4.10836 17.1508 4.40002 16.9008 4.65002L9.55078 12L16.9008 19.35C17.1341 19.5834 17.2508 19.8709 17.2508 20.2125C17.2508 20.5542 17.1258 20.85 16.8758 21.1C16.6258 21.35 16.3341 21.475 16.0008 21.475C15.6674 21.475 15.3758 21.35 15.1258 21.1Z"
        fill={color}
      />
    </g>
  </svg>
);

export const IconExpand: React.FC<IconReversableProps> = ({
  size = ICON_DEFAULT_SIZE,
  color = ICON_DEFAULT_COLOR,
  isReverse,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    transform={isReverse ? ICON_PROPERTY_TRANSFORM_REVERSE : ''}
    style={{ transform: isReverse ? ICON_STYLE_TRANSFORM_REVERSE : '' }}
    xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M6.70078 14.6749C6.51745 14.4916 6.42578 14.2582 6.42578 13.9749C6.42578 13.6916 6.51745 13.4582 6.70078 13.2749L11.3008 8.6749C11.4008 8.5749 11.5091 8.50407 11.6258 8.4624C11.7424 8.42074 11.8674 8.3999 12.0008 8.3999C12.1341 8.3999 12.2591 8.42074 12.3758 8.4624C12.4924 8.50407 12.6008 8.5749 12.7008 8.6749L17.3258 13.2999C17.5091 13.4832 17.6008 13.7082 17.6008 13.9749C17.6008 14.2416 17.5008 14.4749 17.3008 14.6749C17.1174 14.8582 16.8841 14.9499 16.6008 14.9499C16.3174 14.9499 16.0841 14.8582 15.9008 14.6749L12.0008 10.7749L8.07578 14.6999C7.89245 14.8832 7.66745 14.9749 7.40078 14.9749C7.13411 14.9749 6.90078 14.8749 6.70078 14.6749Z"
        fill={color}
      />
    </g>
  </svg>
);

export const IconArrowAlt: React.FC<IconReversableProps> = ({
  size = ICON_DEFAULT_SIZE,
  color = ICON_DEFAULT_COLOR,
  isReverse,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    transform={isReverse ? '' : ICON_PROPERTY_TRANSFORM_REVERSE}
    style={{ transform: isReverse ? '' : ICON_STYLE_TRANSFORM_REVERSE }}
    xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M18.3122 13.0786H3.07672C2.77165 13.0786 2.51593 12.9752 2.30956 12.7685C2.10319 12.5617 2 12.3056 2 12C2 11.6944 2.10319 11.4383 2.30956 11.2315C2.51593 11.0248 2.77165 10.9214 3.07672 10.9214H18.3122L15.2436 7.84756C15.0283 7.63185 14.9251 7.38019 14.9341 7.09258C14.943 6.80496 15.0462 6.5533 15.2436 6.33759C15.4589 6.12188 15.7147 6.00953 16.0108 6.00054C16.3069 5.99155 16.5626 6.09491 16.7779 6.31062L21.7039 11.245C21.8116 11.3529 21.8878 11.4697 21.9327 11.5955C21.9776 11.7214 22 11.8562 22 12C22 12.1438 21.9776 12.2786 21.9327 12.4045C21.8878 12.5303 21.8116 12.6471 21.7039 12.755L16.7779 17.6894C16.5626 17.9051 16.3069 18.0084 16.0108 17.9995C15.7147 17.9905 15.4589 17.8781 15.2436 17.6624C15.0462 17.4467 14.943 17.195 14.9341 16.9074C14.9251 16.6198 15.0283 16.3681 15.2436 16.1524L18.3122 13.0786Z"
        fill={color}
      />
    </g>
  </svg>
);

export const IconFirstPage: React.FC<IconReversableProps> = ({
  size = ICON_DEFAULT_SIZE,
  color = ICON_DEFAULT_COLOR,
  isReverse,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    transform={isReverse ? ICON_PROPERTY_TRANSFORM_REVERSE : ''}
    style={{ transform: isReverse ? ICON_STYLE_TRANSFORM_REVERSE : '' }}
    xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M13.825 12L17.725 8.1C17.9083 7.91667 18 7.68333 18 7.4C18 7.11667 17.9083 6.88333 17.725 6.7C17.5417 6.51667 17.3083 6.425 17.025 6.425C16.7417 6.425 16.5083 6.51667 16.325 6.7L11.725 11.3C11.625 11.4 11.5542 11.5083 11.5125 11.625C11.4708 11.7417 11.45 11.8667 11.45 12C11.45 12.1333 11.4708 12.2583 11.5125 12.375C11.5542 12.4917 11.625 12.6 11.725 12.7L16.325 17.3C16.5083 17.4833 16.7417 17.575 17.025 17.575C17.3083 17.575 17.5417 17.4833 17.725 17.3C17.9083 17.1167 18 16.8833 18 16.6C18 16.3167 17.9083 16.0833 17.725 15.9L13.825 12ZM7.025 6C6.74167 6 6.50417 6.09583 6.3125 6.2875C6.12083 6.47917 6.025 6.71667 6.025 7V17C6.025 17.2833 6.12083 17.5208 6.3125 17.7125C6.50417 17.9042 6.74167 18 7.025 18C7.30833 18 7.54583 17.9042 7.7375 17.7125C7.92917 17.5208 8.025 17.2833 8.025 17V7C8.025 6.71667 7.92917 6.47917 7.7375 6.2875C7.54583 6.09583 7.30833 6 7.025 6Z"
        fill={color}
      />
    </g>
  </svg>
);

export const IconChevron: React.FC<IconReversableProps> = ({
  size = ICON_DEFAULT_SIZE,
  color = ICON_DEFAULT_COLOR,
  isReverse,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    transform={isReverse ? ICON_PROPERTY_TRANSFORM_REVERSE : ''}
    style={{ transform: isReverse ? ICON_STYLE_TRANSFORM_REVERSE : '' }}
    xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M14.7006 6.70001C14.8839 6.88335 14.9756 7.11668 14.9756 7.40001C14.9756 7.68335 14.8839 7.91668 14.7006 8.10001L10.8006 12L14.7006 15.9C14.8839 16.0833 14.9756 16.3167 14.9756 16.6C14.9756 16.8833 14.8839 17.1167 14.7006 17.3C14.5173 17.4833 14.2839 17.575 14.0006 17.575C13.7173 17.575 13.4839 17.4833 13.3006 17.3L8.70059 12.7C8.60059 12.6 8.52975 12.4917 8.48809 12.375C8.44642 12.2583 8.42559 12.1333 8.42559 12C8.42559 11.8667 8.44642 11.7417 8.48809 11.625C8.52975 11.5083 8.60059 11.4 8.70059 11.3L13.3006 6.70001C13.4839 6.51668 13.7173 6.42501 14.0006 6.42501C14.2839 6.42501 14.5173 6.51668 14.7006 6.70001Z"
        fill={color}
      />
    </g>
  </svg>
);

export const IconCreatePoolStepDivider: React.FC<IconReversableProps> = ({
  size = 96,
  color = ICON_DEFAULT_COLOR,
  isReverse,
}) => (
  <svg
    width={size}
    height={size / 12}
    viewBox="0 0 96 8"
    fill="none"
    transform={isReverse ? ICON_PROPERTY_TRANSFORM_REVERSE : ''}
    style={{ transform: isReverse ? ICON_STYLE_TRANSFORM_REVERSE : '' }}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5V3.5ZM95.3536 4.35355C95.5488 4.15829 95.5488 3.84171 95.3536 3.64645L92.1716 0.464466C91.9763 0.269204 91.6597 0.269204 91.4645 0.464466C91.2692 0.659728 91.2692 0.976311 91.4645 1.17157L94.2929 4L91.4645 6.82843C91.2692 7.02369 91.2692 7.34027 91.4645 7.53553C91.6597 7.7308 91.9763 7.7308 92.1716 7.53553L95.3536 4.35355ZM1 4.5H95V3.5H1V4.5Z"
      fill={color}
    />
  </svg>
);
