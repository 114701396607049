import {
  compareLowerStr,
  ProviderInfo,
  ProviderType,
  useWallet,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import styled from '@emotion/styled';
import { useAtom } from 'jotai';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useCallback, useMemo, useRef } from 'react';
import Inline from 'components/styled/Inline';
import PROVIDERS from 'configs/providers';
import useIsMounted from 'hooks/useIsMounted';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { isShowConnectWalletAtom } from 'store/ui';
import PopupContainer from './PopupContainer';
import PopupList, { PopupListItem } from './PopupList';
import PopupTitle from './PopupTitle';

const Root = styled(PopupContainer)`
  width: 200px;
`;

const PopupConnectWallet: React.FC = () => {
  const { t } = useTranslation('common');

  const ref = useRef(null);
  const [isShowConnectWallet, setShowConnectWallet] = useAtom(
    isShowConnectWalletAtom
  );
  const isMounted = useIsMounted(isShowConnectWallet);
  const { providerInfos, wallet } = useWallet();

  useOnClickOutside(ref, () => setShowConnectWallet(false));

  const providers = useMemo<
    {
      key: string;
      target: any;
      name: any;
      icon?: any;
      rdns?: string;
    }[]
  >(
    () =>
      [
        ...PROVIDERS.map((provider) => ({
          key: provider.type,
          target: provider.type,
          icon: provider.src,
          name: provider.name,
        })),
        ...(providerInfos ?? []).map((providerInfo) => ({
          ...providerInfo.info,
          icon: providerInfo.info.icon?.replace('\n', ''),
          key: providerInfo.uuid,
          target: providerInfo,
        })),
      ].filter(
        (provider, index, array) =>
          array.findIndex((item) =>
            compareLowerStr(item.name, provider.name)
          ) === index
      ),
    [providerInfos]
  );

  const handleClick = useCallback(
    (provider: ProviderType | ProviderInfo) => async () => {
      await wallet.connect(provider);

      setShowConnectWallet(false);
    },
    [setShowConnectWallet, wallet]
  );

  if (!isMounted) {
    return <></>;
  }

  return (
    <Root ref={ref} isShowing={isShowConnectWallet}>
      <PopupTitle>{t('selectWallet')}</PopupTitle>
      <PopupList>
        {providers.map(({ key, target, icon, name }) => (
          <PopupListItem key={key} onClick={handleClick(target)}>
            <Inline gap="sm">
              <Image width={24} height={24} alt="" src={icon} />
              {name}
            </Inline>
          </PopupListItem>
        ))}
      </PopupList>
    </Root>
  );
};

export default PopupConnectWallet;
