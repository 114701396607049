import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import { API_PATH_BIHOLER_CHAIN_IMAGE } from 'configs/apiPath';
import { CHAIN_IDS } from 'configs/chains';
import { CHAIN_ID_BIFROST } from 'configs/chains/chainIds';
import useWatchingAddressMap from 'hooks/useWatchingAddressMap';
import { biholder } from 'lib/axiosInstance';
import { logError } from 'lib/log';
import { ChainId } from 'types/Chain';
import ChainImageMap from 'types/ChainImageMap';
import BiHolderImage from 'types/api/biholer/BiHolderImage';
import { parseChainrunnerChainId } from 'utils/chainrunner';

export type Props = {
  chainImageMap: ChainImageMap;
};

export const INTERVAL_ASSET_INFO = 30000;

export const getChainImage = async (chainId: ChainId = CHAIN_ID_BIFROST) => {
  let result: string | undefined = undefined;

  if (chainId) {
    try {
      const { data: chainImage } = await biholder.get<BiHolderImage>(
        API_PATH_BIHOLER_CHAIN_IMAGE.replace('{networkId}', `${chainId}`),
        {
          params: {
            image_type: 'SVG',
          },
        }
      );

      if (chainImage?.url) {
        result = chainImage.url;
      }
    } catch (error) {}
  }

  return result;
};

export const Context = createContext<Props>({
  chainImageMap: {},
});

export const ChainImageMapProvider = ({ children }: PropsWithChildren) => {
  const { watchingAddressMap } = useWatchingAddressMap();

  const [chainImageMap, setChainImageMap] = useState<ChainImageMap>({});

  const taskId = useRef<NodeJS.Timeout>();

  const watchingChainIds = useMemo(
    () => Object.keys(watchingAddressMap),
    [watchingAddressMap]
  );
  const chainIdsNeedUpdate = useMemo(
    () =>
      [...watchingChainIds, ...CHAIN_IDS.map((chainId) => `${chainId}`)]
        .filter((chainId, index, array) => array.indexOf(chainId) === index)
        .filter((chainId) => !chainImageMap[chainId]),
    [chainImageMap, watchingChainIds]
  );

  const updateChainImageMap = useCallback(async (chainIds: string[]) => {
    try {
      const newChainImageMap = await chainIds.reduce(
        async (prev, chainId) => {
          const clonePrev: ChainImageMap = await prev;
          const chainImage = await getChainImage(
            parseChainrunnerChainId(chainId)
          );

          if (chainImage) {
            clonePrev[chainId] = chainImage;
          }

          return Promise.resolve(clonePrev);
        },
        Promise.resolve({} as ChainImageMap)
      );

      setChainImageMap((value) => ({ ...value, ...newChainImageMap }));
    } catch (error) {
      logError(error);
    }
  }, []);

  useEffect(() => {
    if (chainIdsNeedUpdate.length) {
      updateChainImageMap(chainIdsNeedUpdate);

      if (!taskId.current) {
        taskId.current = setInterval(
          () => updateChainImageMap(chainIdsNeedUpdate),
          INTERVAL_ASSET_INFO
        );
      }
    }

    return () => {
      if (taskId.current) {
        clearInterval(taskId.current);
      }
    };
  }, [updateChainImageMap, chainIdsNeedUpdate]);

  return (
    <Context.Provider
      value={{
        chainImageMap,
      }}>
      {children}
    </Context.Provider>
  );
};

const useChainImageMap = (): Props => useContext(Context);

export default useChainImageMap;
