import styled from '@emotion/styled';
import Collapse from 'components/mui/Collapse';
import Center from 'components/styled/Center';

export type Props = {
  connectionErrorContent?: React.ReactNode;
};

const Root = styled(Center)<{ isShow?: boolean }>`
  width: 100vw;
  height: 56px;
  color: ${({ theme }) => theme.color.text};
  background-color: ${({ theme }) => theme.color.box};
`;

const ConnectWarning: React.FC<Props> = ({ connectionErrorContent }) => (
  <Collapse in={!!connectionErrorContent}>
    <Root gap="md" isShow={!!connectionErrorContent}>
      {connectionErrorContent}
    </Root>
  </Collapse>
);

export default ConnectWarning;
