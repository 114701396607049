import { Grow as MuiGrow } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import DEFAULT_TRANSITION_TIMEOUT from 'configs/defaultValue/defaultTransitionTimout';

export const DEFAULT_GROW_TIMEOUT = DEFAULT_TRANSITION_TIMEOUT;

const Grow = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <MuiGrow ref={ref} {...props} timeout={DEFAULT_GROW_TIMEOUT} />;
});

export default Grow;
