import { Wallet } from '@bifrost-platform/bifront-sdk-react-wallet';
import {
  FRAG_LP_REWARD_REWARD_RATE,
  FRAG_LP_REWARD_TOTAL_SUPPLY,
  FRAG_LP_REWARD_BALANCE_OF,
  FRAG_LP_REWARD_EARNED,
  FRAG_LP_REWARD_STAKE,
  FRAG_LP_REWARD_GET_REWARD,
  FRAG_LP_REWARD_EXIT,
  FRAG_LP_REWARD_PERIOD_FINISH,
} from 'configs/fragment';
import { call, pickNumberResponse, send } from 'lib/contract/dex';
import {
  ContractFunctionCaller,
  ContractFunctionSender,
} from 'types/ContractFunction';

export const rewardRate: ContractFunctionCaller = async (option, args) =>
  call({ ...option, fragment: FRAG_LP_REWARD_REWARD_RATE }, args);
export const totalSupply: ContractFunctionCaller = async (option, args) =>
  call({ ...option, fragment: FRAG_LP_REWARD_TOTAL_SUPPLY }, args);
export const balanceOf: ContractFunctionCaller = async (option, args) =>
  call({ ...option, fragment: FRAG_LP_REWARD_BALANCE_OF }, args);
export const earned: ContractFunctionCaller = async (option, args) =>
  call({ ...option, fragment: FRAG_LP_REWARD_EARNED }, args);
export const periodFinish: ContractFunctionCaller = async (option, args) =>
  call({ ...option, fragment: FRAG_LP_REWARD_PERIOD_FINISH }, args);
export const stake: ContractFunctionSender = async (option, args) =>
  send({ ...option, fragment: FRAG_LP_REWARD_STAKE }, args);
export const getReward: ContractFunctionSender = async (option, args) =>
  send({ ...option, fragment: FRAG_LP_REWARD_GET_REWARD }, args);
export const exit: ContractFunctionSender = async (option, args) =>
  send({ ...option, fragment: FRAG_LP_REWARD_EXIT }, args);

// rewardRate
export const callLpRewardRewardRate = async (
  proxyAddress: string,
  rewardStateAddress: string,
  wallet?: Wallet
) =>
  pickNumberResponse(
    await rewardRate({ wallet, to: proxyAddress, onlyBifrost: true }, [
      rewardStateAddress,
    ])
  );
// totalSupply
export const callLpRewardTotalSupply = async (
  proxyAddress: string,
  wallet?: Wallet
) =>
  pickNumberResponse(
    await totalSupply({ wallet, to: proxyAddress, onlyBifrost: true })
  );
// balanceOf
export const callLpRewardBalanceOf = async (
  proxyAddress: string,
  wallet?: Wallet,
  address?: string
) =>
  pickNumberResponse(
    await balanceOf({ wallet, to: proxyAddress, onlyBifrost: true }, [
      address ?? wallet?.getAddress(),
    ])
  );
// earned
export const callLpRewardEarned = async (
  proxyAddress: string,
  rewardStateAddress: string,
  wallet?: Wallet,
  address?: string
) =>
  pickNumberResponse(
    await earned({ wallet, to: proxyAddress, onlyBifrost: true }, [
      rewardStateAddress,
      address ?? wallet?.getAddress(),
    ])
  );
// periodFinish
export const callLpRewardPeriodFinish = async (
  proxyAddress: string,
  rewardStateAddress: string,
  wallet?: Wallet
) =>
  pickNumberResponse(
    await periodFinish({ wallet, to: proxyAddress, onlyBifrost: true }, [
      rewardStateAddress,
    ])
  );
