import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const appear = keyframes`
  from {
    opacity: 0.4;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;
const disappear = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
`;
const PopupContainer = styled.div<{ isShowing: boolean }>`
  position: absolute;
  top: 76px;
  right: 0;
  display: block;
  gap: 15px;
  padding: ${({ theme }) => theme.gap.lg};
  color: ${({ theme }) => theme.color.text};
  background-color: ${({ theme }) => theme.color.popupBackground};
  border-radius: ${({ theme }) => theme.radius.lg};
  box-shadow: ${({ theme }) => theme.boxShadow.popup};
  animation: ${({ isShowing }) => (isShowing ? appear : disappear)} 0.17s
    ${({ isShowing }) => (isShowing ? 'ease-out' : 'ease-in')};
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  cursor: default;
`;

export default PopupContainer;
