import { CHAIN_ID_BIFROST } from 'configs/chains/chainIds';
import useIsCorrectChain from 'hooks/useIsCorrectChain';

const useIsBifrostChain = () => {
  const isCorrectChain = useIsCorrectChain(CHAIN_ID_BIFROST);

  return isCorrectChain;
};

export default useIsBifrostChain;
