import { useWallet } from '@bifrost-platform/bifront-sdk-react-wallet';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import React, { useCallback, useMemo } from 'react';
import { IconShield, IconTestnetInfo, IconWallet } from 'components/icons';
import { CHAIN_BIFROST } from 'configs/chains';
import { CHAIN_NAME_BIFROST } from 'configs/chains/chainNames';
import { MAINNET_CHAIN_NAME_BIFROST } from 'configs/chains/mainnet/chainName';
import { PATHS_ERROR_VIEWABLE } from 'configs/paths';
import useIsBifrostChain from 'hooks/useIsBifrostChain';
import useIsConnected from 'hooks/useIsConnected';
import useIsPath from 'hooks/useIsPath';
import { changeChain } from 'lib/contract/dex';
import IS_TESTNET from 'lib/devEnv/envIsTestnet';

const Button = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.color.point};
  ${({ theme }) => theme.transitionSet.hoverOpacityButton}
`;

const useConnectionErrorContent = (): React.ReactNode => {
  const { t } = useTranslation('common');

  const isErrorViewablePath = useIsPath(PATHS_ERROR_VIEWABLE);

  const { wallet } = useWallet();
  const isConnected = useIsConnected();
  const isBifrostChain = useIsBifrostChain();

  const handleChangeChainClick: React.MouseEventHandler<HTMLAnchorElement> =
    useCallback(() => {
      changeChain(wallet, CHAIN_BIFROST);
    }, [wallet]);

  const connectionErrorContent = useMemo(() => {
    let content = IS_TESTNET ? (
      <>
        <IconTestnetInfo />
        <div>{t('message.testnetNow')} </div>
      </>
    ) : undefined;

    if (isErrorViewablePath) {
      if (!isBifrostChain) {
        content = (
          <>
            <IconShield />
            <div>
              <Button onClick={handleChangeChainClick}>
                {t('error.wrongChainConnected')}{' '}
                {`${CHAIN_NAME_BIFROST}${CHAIN_NAME_BIFROST === MAINNET_CHAIN_NAME_BIFROST ? ' Network' : ''}`}
              </Button>
            </div>
          </>
        );
      }
      if (!isConnected) {
        content = (
          <>
            <IconWallet />
            <div>{t('error.disconnected')}</div>
          </>
        );
      }
    }

    return content;
  }, [
    handleChangeChainClick,
    isBifrostChain,
    isConnected,
    isErrorViewablePath,
    t,
  ]);

  return connectionErrorContent;
};

export default useConnectionErrorContent;
