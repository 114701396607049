import {
  compareLowerStr,
  isValidAddress,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';
import { CHAIN_ID_BIFROST } from 'configs/chains/chainIds';
import TOKEN_ADDRESSES from 'configs/tokens/tokenAddresses';
import { logError } from 'lib/log';
import { ChainId } from 'types/Chain';
import { TokenAddress } from 'types/Token';
import WatchingAddressMap from 'types/WatchingAddressMap';

export type FunctionAddWatchingAddress = (
  address: TokenAddress,
  chainId?: ChainId
) => boolean;
export type Props = {
  watchingAddressMap: WatchingAddressMap;
  addWatchingAddress: FunctionAddWatchingAddress;
};

export const Context = createContext<Props>({
  watchingAddressMap: {},
  addWatchingAddress: () => false,
});

export const WatchingAddressMapProvider = ({ children }: PropsWithChildren) => {
  const [addressMap, setAddressMap] = useState<WatchingAddressMap>({
    [CHAIN_ID_BIFROST]: TOKEN_ADDRESSES,
  });

  const addWatchingAddress = useCallback(
    (address: TokenAddress, chainId: ChainId = CHAIN_ID_BIFROST) => {
      const isValid = isValidAddress(address) && chainId > 0;
      const isExistAlready = addressMap[chainId]?.find(
        (findAddress) =>
          findAddress && address && compareLowerStr(findAddress, address)
      );

      if (!(!isExistAlready && isValid)) {
        return false;
      }

      let result = false;

      try {
        setAddressMap((value) => {
          const watchingAddressMap = Object.assign({}, value);

          const addresses = watchingAddressMap[chainId] ?? [];

          watchingAddressMap[chainId] = [
            ...addresses,
            address.toLowerCase(),
          ].filter((value, index, array) => array.indexOf(value) === index);

          return watchingAddressMap;
        });
        result = true;
      } catch (error) {
        logError(error);
      }
      return result;
    },
    [addressMap]
  );

  return (
    <Context.Provider
      value={{
        watchingAddressMap: addressMap,
        addWatchingAddress,
      }}>
      {children}
    </Context.Provider>
  );
};

const useWatchingAddressMap = (): Props => useContext(Context);

export default useWatchingAddressMap;
