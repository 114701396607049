import { useEffect, useMemo } from 'react';
import useWatchingAddressMap from 'hooks/useWatchingAddressMap';
import { MinimalToken } from 'types/Token';
import useAssetInfo from './useAssetInfo';

const useTokenImage = (token?: MinimalToken): string | undefined => {
  const { watchingAddressMap, addWatchingAddress } = useWatchingAddressMap();

  const { assetMap } = useAssetInfo();

  const tokenImage = useMemo(
    () =>
      assetMap[token?.chainId ?? '']?.[token?.address?.toLowerCase() ?? '']
        ?.imageUrl || undefined,
    [assetMap, token?.address, token?.chainId]
  );

  useEffect(() => {
    if (
      token &&
      !watchingAddressMap[token.chainId]?.includes(token.address.toLowerCase())
    ) {
      addWatchingAddress(token.address, token.chainId);
    }
  }, [addWatchingAddress, token, watchingAddressMap]);

  return tokenImage;
};

export default useTokenImage;
