import {
  getStorageItemString,
  removeStorageItem,
  setStorageItemString,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import getWindowLocation from 'lib/getWindowLocation';
import DevelopmentEnvironment from 'types/DevelopmentEnvironment';
import checkIsNormalEnvValue from './checkIsNormalEnvValue';
import IS_DEV from './isDev';

export const setEnvEverdexApi = (
  developmentEnvironment?: DevelopmentEnvironment
) => {
  if (!IS_DEV) {
    removeStorageItem('envEverdexApi');
    return;
  }

  setStorageItemString('envEverdexApi', developmentEnvironment ?? 'dev');
  getWindowLocation()?.reload?.();
};

const ENV_EVERDEX_API: DevelopmentEnvironment =
  IS_DEV && checkIsNormalEnvValue(getStorageItemString('envEverdexApi'))
    ? (getStorageItemString('envEverdexApi') as DevelopmentEnvironment)
    : 'production';

export default ENV_EVERDEX_API;
