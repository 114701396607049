import Token from 'types/Token';
import useTokens from './useTokens';

const useToken = (token?: Token | string): Token | undefined => {
  const [result] = useTokens(token ? [token] : undefined);

  return result;
};

export default useToken;
