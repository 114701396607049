import { ICON_DEFAULT_COLOR } from 'configs/icons';
import IconProps from 'types/IconProps';

export const IconLogo: React.FC<IconProps> = ({
  size = 178,
  color = ICON_DEFAULT_COLOR,
}) => (
  <svg
    width={size}
    viewBox="0 0 178 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95426 31.0457 0 20 0C8.95426 0 0 8.95426 0 20C0 31.0457 8.95426 40 20 40ZM21.6764 18.4933L22.7678 20.3226L15.4414 22.2333L14.3419 20.4046L21.6764 18.4933ZM28.5598 19.6186H28.7987L29.8578 21.3936L10.3733 26.6471L8.71582 27.0907L8.58917 26.8827C7.36664 24.8748 6.66667 22.517 6.66667 19.9998C6.66667 12.6475 12.6503 6.66667 20.0055 6.66667C23.7406 6.66667 27.1203 8.20974 29.5407 10.6865L29.9312 11.0862L27.6272 11.6922L27.4969 11.5766C25.4991 9.80275 22.8771 8.71582 20.0055 8.71582C13.7817 8.71582 8.71643 13.7791 8.71643 19.9998C8.71643 21.6713 9.09129 23.2606 9.74955 24.6896L28.5598 19.6186ZM30.2557 15.3095C30.9142 16.7388 31.2892 18.3283 31.2892 20.0002C31.2892 26.2209 26.2239 31.2842 20.0001 31.2842C17.1336 31.2842 14.5228 30.2081 12.5309 28.4401L12.4009 28.3247L10.09 28.9304L10.482 29.3305C12.9025 31.8017 16.2708 33.3333 19.9945 33.3333C27.3496 33.3333 33.3333 27.3525 33.3333 20.0002C33.3333 17.483 32.6334 15.1252 31.4108 13.1173L31.284 12.9089L29.7843 13.3135L14.5171 17.3803L15.6159 19.2081L30.2557 15.3095Z"
      fill={color}
    />
    <path
      d="M48 31.6725V8.0005H64.9039V13.0357H54.2123V17.3189H64.0211V22.3541H54.2123V26.6373H64.8712V31.6725H48Z"
      fill={color}
    />
    <path
      d="M85.9276 13.9185L79.9442 31.6725H72.7837L66.8003 13.9185H73.3068L76.2822 25.7872H76.4457L79.4537 13.9185H85.9276Z"
      fill={color}
    />
    <path
      d="M95.7037 31.9995C90.0473 31.9995 86.5978 28.5827 86.5815 22.8446C86.5978 17.3516 90.0963 13.6896 95.5729 13.6896C100.837 13.6896 104.319 17.0737 104.336 22.7792V24.2505H92.6957V24.4794C92.6957 26.3267 93.8564 27.6182 95.8345 27.6182C97.1914 27.6182 98.254 27.046 98.6464 26.0161H104.303C103.698 29.6781 100.559 31.9995 95.7037 31.9995ZM92.6957 20.7847H98.6137C98.5973 19.1826 97.3549 18.0546 95.7037 18.0709C94.0526 18.0546 92.7447 19.1826 92.6957 20.7847Z"
      fill={color}
    />
    <path
      d="M106.984 31.6725V13.9185H112.967V17.2535H113.163C113.801 14.8013 115.305 13.6733 117.218 13.6896C117.757 13.6733 118.329 13.7714 118.82 13.8858V19.2153C118.215 19.0028 117.169 18.8883 116.498 18.8883C114.569 18.8883 113.147 20.2616 113.163 22.3214V31.6725H106.984Z"
      fill={color}
    />
    <path
      d="M126.83 31.9014C122.989 31.9178 119.801 28.9261 119.801 22.8119C119.801 16.4198 123.218 13.6896 126.765 13.6896C129.463 13.6896 131.097 15.2427 131.768 16.992H131.866V8.0005H138.045V31.6725H131.931V28.7626H131.768C131.048 30.5282 129.364 31.9178 126.83 31.9014ZM126.177 22.7792C126.16 25.493 127.223 27.1441 129.054 27.1605C130.901 27.1441 132.013 25.4603 132.029 22.7792C132.013 20.0817 130.918 18.4469 129.054 18.4633C127.223 18.4469 126.16 20.0981 126.177 22.7792Z"
      fill={color}
    />
    <path
      d="M149.751 31.9995C144.094 31.9995 140.645 28.5827 140.628 22.8446C140.645 17.3516 144.143 13.6896 149.62 13.6896C154.884 13.6896 158.366 17.0737 158.382 22.7792V24.2505H146.742V24.4794C146.742 26.3267 147.903 27.6182 149.881 27.6182C151.238 27.6182 152.301 27.046 152.693 26.0161H158.35C157.745 29.6781 154.606 31.9995 149.751 31.9995ZM146.742 20.7847H152.66C152.644 19.1826 151.402 18.0546 149.751 18.0709C148.099 18.0546 146.792 19.1826 146.742 20.7847Z"
      fill={color}
    />
    <path
      d="M166.131 13.9185L168.812 19.5096L171.624 13.9185H177.771L172.965 22.7792L178 31.6725H171.886L168.812 25.9507L165.837 31.6725H159.625L164.693 22.7792L159.919 13.9185H166.131Z"
      fill={color}
    />
  </svg>
);
