import EventNotice from 'types/EventNotice';

const EVENT_NOTICES: EventNotice[] = [
  {
    id: 'lpReward',
    startAt: undefined,
    finishAt: undefined,
  },
];

export default EVENT_NOTICES;
