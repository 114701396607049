import {
  getStorageItemString,
  removeStorageItem,
  setStorageItemString,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import getWindowLocation from './getWindowLocation';

const isDarkmode = () =>
  typeof window === 'object' && getStorageItemString('isDarkmode') === '1';

const setIsDarkmode = (checked: boolean) => {
  if (!checked) {
    removeStorageItem('isDarkmode');
  } else {
    setStorageItemString('isDarkmode', '1');
  }

  getWindowLocation()?.reload();
};

export default isDarkmode;
export { setIsDarkmode };
