import { Wallet, useWallet } from '@bifrost-platform/bifront-sdk-react-wallet';

export const getIsConnected = (wallet?: Wallet) =>
  !!(
    wallet &&
    wallet.getProvider() &&
    wallet.getAddress() &&
    wallet.getChainId()
  );

const useIsConnected = () => {
  const { wallet } = useWallet();

  return getIsConnected(wallet);
};

export default useIsConnected;
