import styled from '@emotion/styled';
import { useAtom } from 'jotai';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IconArrow, IconChevron } from 'components/icons';
import Inline from 'components/styled/Inline';
import { IconBtn } from 'components/styled/btn';
import CURRENCIES from 'configs/currencies';
import DEVELOPMENT_ENVIRONMENTS from 'configs/devEnv/developmentEnvironments';
import ENV_NETWORK_TITLES from 'configs/devEnv/envNetworkTitles';
import LANGUAGES from 'configs/langauges';
import useCurrency from 'hooks/api/biholder/useCurrency';
import useIsMounted from 'hooks/useIsMounted';
import useOnClickOutside from 'hooks/useOnClickOutside';
import ENV_BIHOLDER_API, { setEnvBiholderApi } from 'lib/devEnv/envBiholderApi';
import ENV_CHAINRUNNER_API, {
  setEnvChainrunnerApi,
} from 'lib/devEnv/envChainrunnerApi';
import ENV_EVERDEX_API, { setEnvEverdexApi } from 'lib/devEnv/envEverdexApi';
import { NETWORK_TITLE, setIsTestnet } from 'lib/devEnv/envIsTestnet';
import IS_DEV from 'lib/devEnv/isDev';
import { isShowSettingAtom } from 'store/ui';
import { CurrencyId } from 'types/Currency';
import DataWithEmpty from 'types/DataWithEmpty';
import DevelopmentEnvironment from 'types/DevelopmentEnvironment';
import EnvNetworkTitle from 'types/EnvNetworkTitle';
import { LanguageId } from 'types/Language';
import PopupContainer from './PopupContainer';
import PopupList, { PopupListItem } from './PopupList';
import PopupTitle from './PopupTitle';

type ListChild<T> = {
  id: T;
  title: string;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
};
type ListItem = {
  title: string;
  subtitle: string;
  children: ListChild<
    CurrencyId | LanguageId | EnvNetworkTitle | DevelopmentEnvironment
  >[];
};

const Root = styled(PopupContainer)`
  width: 280px;
  min-width: 280px;
  max-width: 280px;
`;
const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
`;
const Page = styled.div<{ isHide?: boolean }>`
  min-width: 248px;
  max-width: 248px;
  transition: ${({ theme }) => theme.transition.all};
  ${({ isHide }) => (isHide ? 'margin-left: -248px;' : '')}
`;
const ChildTitle = styled(PopupTitle)`
  position: relative;
  text-align: center;
`;
const ChildCloseContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;
const ChildListItem = styled(PopupListItem)`
  justify-content: center;
  text-align: center;
`;

const PopupSetting: React.FC = () => {
  const { t } = useTranslation('common');

  const ref = useRef(null);
  const [isShowSettingWallet, setShowSettingWallet] =
    useAtom(isShowSettingAtom);
  const isMounted = useIsMounted(isShowSettingWallet);

  useOnClickOutside(ref, () => setShowSettingWallet(false));

  const router = useRouter();
  const { pathname, asPath, query, locale } = router;

  const { currency, changeCurrency } = useCurrency();

  // memo
  const selectedLanguage = useMemo(
    () => LANGUAGES.find((language) => language.id === locale),
    [locale]
  );
  const selectedCurrency = useMemo(
    () => CURRENCIES.find((c) => c.id === currency),
    [currency]
  );
  const selectedLanguageTitle = useMemo(
    () => selectedLanguage?.title ?? '',
    [selectedLanguage]
  );
  const selectedCurrencyTitle = useMemo(
    () => selectedCurrency?.title ?? '',
    [selectedCurrency]
  );

  // state
  const [selectedItem, setSelectedItem] = useState<DataWithEmpty<ListItem>>();
  const [isSelectedItem, setIsSelectedItem] = useState(false);

  // handler
  const handleChildCloseClick = useCallback(() => setIsSelectedItem(false), []);
  const handleItemClick = useCallback(
    (item: ListItem) => () => {
      setSelectedItem(item);
      setIsSelectedItem(true);
    },
    []
  );
  const handleLanguageClick = useCallback(
    (languageId: LanguageId) => async () => {
      await router.push({ pathname, query }, asPath, { locale: languageId });
      setIsSelectedItem(false);
    },
    [asPath, pathname, query, router]
  );
  const handleCurrencyClick = useCallback(
    (currencyId: CurrencyId) => () => {
      changeCurrency(currencyId);
      setIsSelectedItem(false);
    },
    [changeCurrency]
  );
  const handleNetworkClick = useCallback(
    (network: EnvNetworkTitle) => () => {
      setIsTestnet(network === 'Testnet');
    },
    []
  );
  const handleEverdexApiClick = useCallback(
    (env: DevelopmentEnvironment) => () => {
      setEnvEverdexApi(env);
    },
    []
  );
  const handleChainrunnerApiClick = useCallback(
    (env: DevelopmentEnvironment) => () => {
      setEnvChainrunnerApi(env);
    },
    []
  );
  const handleBiholderApiClick = useCallback(
    (env: DevelopmentEnvironment) => () => {
      setEnvBiholderApi(env);
    },
    []
  );

  // memo
  const devListItems: ListItem[] = useMemo(
    () =>
      IS_DEV
        ? [
            {
              title: t('everdexApi'),
              subtitle: ENV_EVERDEX_API,
              children: DEVELOPMENT_ENVIRONMENTS.map((env) => ({
                id: env,
                title: env,
                onClick: handleEverdexApiClick(env),
              })),
            },
            {
              title: t('chainrunnerApi'),
              subtitle: ENV_CHAINRUNNER_API,
              children: DEVELOPMENT_ENVIRONMENTS.map((env) => ({
                id: env,
                title: env,
                onClick: handleChainrunnerApiClick(env),
              })),
            },
            {
              title: t('biholderApi'),
              subtitle: ENV_BIHOLDER_API,
              children: DEVELOPMENT_ENVIRONMENTS.map((env) => ({
                id: env,
                title: env,
                onClick: handleBiholderApiClick(env),
              })),
            },
          ]
        : [],
    [
      handleBiholderApiClick,
      handleChainrunnerApiClick,
      handleEverdexApiClick,
      t,
    ]
  );
  const listItems: ListItem[] = useMemo(
    () => [
      {
        title: t('language'),
        subtitle: selectedLanguageTitle,
        children: LANGUAGES.map((language) => ({
          id: language.id,
          title: language.title,
          onClick: handleLanguageClick(language.id),
        })),
      },
      {
        title: t('currency'),
        subtitle: selectedCurrencyTitle,
        children: CURRENCIES.map((currency) => ({
          id: currency.id,
          title: currency.title,
          onClick: handleCurrencyClick(currency.id),
        })),
      },
      {
        title: t('network'),
        subtitle: NETWORK_TITLE,
        children: ENV_NETWORK_TITLES.map((network) => ({
          id: network,
          title: network,
          onClick: handleNetworkClick(network),
        })),
      },
      ...devListItems,
    ],
    [
      t,
      selectedLanguageTitle,
      selectedCurrencyTitle,
      devListItems,
      handleLanguageClick,
      handleCurrencyClick,
      handleNetworkClick,
    ]
  );

  // effect
  useEffect(() => {
    if (!isShowSettingWallet) {
      setIsSelectedItem(false);
    }
  }, [isShowSettingWallet]);

  if (!isMounted) {
    return <></>;
  }

  return (
    <Root ref={ref} isShowing={isShowSettingWallet}>
      <Container>
        <Page isHide={isSelectedItem}>
          <PopupTitle>{t('setting')}</PopupTitle>
          <PopupList>
            {listItems.map((item, listItemIndex) => (
              <PopupListItem
                key={listItemIndex}
                onClick={handleItemClick(item)}>
                {item.title}
                <Inline gap="sm">
                  {item.subtitle}
                  <IconChevron isReverse />
                </Inline>
              </PopupListItem>
            ))}
          </PopupList>
        </Page>
        <Page>
          <ChildTitle>
            <ChildCloseContainer>
              <IconBtn size={16} onClick={handleChildCloseClick}>
                <IconArrow size={16} />
              </IconBtn>
            </ChildCloseContainer>
            {selectedItem?.title}
          </ChildTitle>
          <PopupList>
            {selectedItem?.children.map(
              ({ title, onClick: handleClick }, listItemIndex) => (
                <ChildListItem key={listItemIndex} onClick={handleClick}>
                  {title}
                </ChildListItem>
              )
            )}
          </PopupList>
        </Page>
      </Container>
    </Root>
  );
};

export default PopupSetting;
