import { PropsWithChildren, useCallback } from 'react';
import Expand from 'components/Expand';
import theme from 'styles/theme';
import { Props as BtnProps } from '..';
import HeaderDropdownBtn, {
  HeaderDropdownContainer as Root,
} from '../HeaderDropdownBtn';

export type Props = PropsWithChildren &
  BtnProps & {
    popup?: React.ReactNode;
    isBackgrounded?: boolean;
    isOpen?: boolean;
    isHideExpand?: boolean;
    setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  };

const HeaderDropdownWithExpand: React.FC<Props> = ({
  children,
  disabled,
  isBackgrounded,
  isOpen,
  isHideExpand,
  popup,
  setIsOpen,
}) => {
  const handleClick: React.MouseEventHandler<HTMLAnchorElement> =
    useCallback(() => {
      if (!isOpen) {
        setIsOpen?.((prev) => !prev);
      }
    }, [setIsOpen, isOpen]);

  return (
    <Root>
      <HeaderDropdownBtn
        disabled={disabled}
        isBackgrounded={isBackgrounded}
        onClick={handleClick}>
        {children}
        {isHideExpand ? (
          ''
        ) : (
          <Expand
            color={isBackgrounded ? theme.color.white : theme.color.point}
            isExpand={isOpen}
          />
        )}
      </HeaderDropdownBtn>
      {popup}
    </Root>
  );
};

export default HeaderDropdownWithExpand;
