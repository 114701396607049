import styled from '@emotion/styled';
import Gap from 'types/Gap';

export type Props = { gap?: Gap };

const Inline = styled.div<Props>`
  display: flex;
  align-items: center;
  gap: ${({ gap, theme }) => gap && theme.gap[gap]};
`;

export default Inline;
