import Notice from 'types/Notice';

const NOTICES: Notice[] = [
  {
    id: 'supportArbitrum',
    startAt: undefined,
    finishAt: 1700108889478,
  },
];

export default NOTICES;
