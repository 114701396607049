import {
  isValidAddress,
  Transaction,
  useWallet,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import { useCallback, useMemo, useState } from 'react';
import Loading from 'components/Loading';
import TokenPair from 'components/TokenPair';
import useFirestore from 'hooks/useFirestore';
import useTransactionHistories from 'hooks/useTransactionHistories';
import { lpRewardProxyContract } from 'lib/contract';
import tokenPairTitle from 'lib/tokenPairTitle';
import uuid from 'lib/uuid';
import LpRewardPoolData from 'types/LpRewardPoolData';
import { getIsUserReject } from 'utils/chainrunner';
import { openErrorToast } from 'utils/toastUtils';
import { Props as DialogProps } from '.';
import DialogConfirm from './DialogConfirm';

export type Props = DialogProps & {
  amount?: string;
  lpRewardPoolData?: LpRewardPoolData;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;
const PoolContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  min-height: 100px;
  padding: 8px 0px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;
const Text = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
`;

const DialogLpStakeWithdraw: React.FC<Props> = ({
  open,
  amount,
  lpRewardPoolData,
  onClose: handleClose,
}) => {
  const { t } = useTranslation('dialog');

  const { addPoolAction } = useFirestore();

  const { addTransaction } = useTransactionHistories();

  const { account, wallet } = useWallet();

  const [isLoading, setIsLoading] = useState(false);

  const proxyAddress = useMemo(
    () => lpRewardPoolData?.rewardContract?.proxyAddress,
    [lpRewardPoolData?.rewardContract?.proxyAddress]
  );
  const tokens = useMemo(
    () => lpRewardPoolData?.tokens,
    [lpRewardPoolData?.tokens]
  );
  const title = useMemo(() => t('lpStakeWithdraw.title'), [t]);
  const content = useMemo(
    () => (
      <Container>
        <PoolContainer>
          {tokens ? (
            <>
              <TokenPair isShowTitle={false} size={48} tokens={tokens} />
              <div>{tokenPairTitle(tokens)}</div>
            </>
          ) : (
            ''
          )}
        </PoolContainer>
        <Text>
          {(t('lpStakeWithdraw.content', [tokenPairTitle(tokens)]) as string)
            .split('\n')
            .map((line, lineIndex) => (
              <div key={lineIndex}>{line}</div>
            ))}
        </Text>
      </Container>
    ),
    [t, tokens]
  );

  const withdraw = useCallback(async () => {
    if (
      !(
        account &&
        isValidAddress(account) &&
        proxyAddress &&
        isValidAddress(proxyAddress)
      ) ||
      isLoading
    ) {
      return;
    }

    setIsLoading(true);

    const storeId = uuid();
    const type = 'lpRewardWithdraw';
    const amounts = [`${amount}`];

    try {
      const transaction: Transaction = await lpRewardProxyContract.exit({
        wallet,
        to: proxyAddress,
      });

      const transactionHash = transaction.hash;
      addPoolAction?.({
        type,
        id: storeId,
        transactionHash,
      });
      await addTransaction?.(
        {
          ...transaction,
          type,
          tokens,
          amounts,
        },
        {
          process: t('common:message.lpReward.withdraw.process'),
          success: t('common:message.lpReward.withdraw.success'),
        }
      );
      addPoolAction?.({
        type,
        id: storeId,
        transactionHash,
        complete: true,
      });

      handleClose?.({}, 'flowFinish');
    } catch (error) {
      if (getIsUserReject(error)) {
        return;
      }

      addPoolAction?.({
        type,
        id: storeId,
        error,
      });
      // handle out of slippage
      openErrorToast(error);
    } finally {
      setIsLoading(false);
    }

    handleClose?.({}, 'closeClick');
  }, [
    account,
    addPoolAction,
    addTransaction,
    amount,
    handleClose,
    isLoading,
    proxyAddress,
    t,
    tokens,
    wallet,
  ]);

  const handleConfirm = useCallback(() => {
    withdraw();
  }, [withdraw]);

  const confirmProps = useMemo(
    () => ({
      disabled: isLoading,
      children: isLoading ? <Loading /> : t('lpStakeWithdraw.action'),
      onClick: handleConfirm,
    }),
    [handleConfirm, isLoading, t]
  );

  return (
    <DialogConfirm
      open={open}
      width={360}
      title={title}
      content={content}
      confirmProps={confirmProps}
      onClose={handleClose}
    />
  );
};

export default DialogLpStakeWithdraw;
