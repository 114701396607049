import {
  EncodedParams,
  SendParams,
  call as rawCall,
  rpcCall as rpcRawCall,
  rpcContractCall as rpcRawContractCall,
  AddChainParameter,
  UnknownNumber,
  formatCommas,
  formatDecimal,
  isNormalPositive,
  minMax,
  Wallet,
  isValidAddress,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import BN from 'bignumber.js';
import { CHAIN_ID_BIFROST } from 'configs/chains/chainIds';
import { CHAIN_RPC_URL_BIFROST } from 'configs/chains/chainRpcUrls';
import { METHOD_BALANCE, METHOD_ESTIMATE } from 'configs/method';
import log from 'lib/log';
import Chain from 'types/Chain';
import {
  ContractFunctionCaller,
  ContractFunctionSender,
} from 'types/ContractFunction';
import { CurrencyId } from 'types/Currency';
import DataWithEmpty from 'types/DataWithEmpty';

export type UnknownStringResponse = DataWithEmpty<string | string[]>;
export type UnknownResponse = DataWithEmpty<UnknownNumber | UnknownNumber[]>;

// required symbols of allowance reset
export const SYMBOLS_ALLOWANCE_RESET_REQUIRE = ['USDT'];

// utils
export const num2Hex = (value: UnknownNumber) =>
  `0x${new BN(value).toString(16).replace('0x', '')}`.split('.')[0];

export const hex2Num = (hex: string) =>
  formatDecimal(`0x${hex.replace('0x', '')}`, 18);

export const makeServiceFeeAmount = (
  value: UnknownNumber,
  rate: UnknownNumber,
  min?: UnknownNumber,
  max?: UnknownNumber
) => minMax(new BN(value).multipliedBy(rate).div('100'), min, max);

export const convertAmount = (
  value: UnknownNumber,
  price: UnknownNumber,
  toPrice: UnknownNumber
) =>
  formatDecimal(
    new BN(value).multipliedBy(price).div(price === toPrice ? 1 : toPrice),
    18
  );
export const amount2Usd = (value: UnknownNumber, price: UnknownNumber) =>
  convertAmount(value, price, price);

export const formatAuto = (
  value: UnknownNumber,
  type:
    | CurrencyId
    | 'token-short'
    | 'token'
    | 'ratio'
    | 'time'
    | 'detail' = 'token'
) => {
  const bnValue = new BN(value);

  let result = '0';

  const decimal =
    type === 'token' || type === 'token-short'
      ? 4
      : type === 'time' || type === 'krw'
        ? 0
        : 2;

  if (isNormalPositive(bnValue, true)) {
    if (bnValue.gte('1')) {
      result = formatCommas(bnValue, decimal);
    } else {
      if (type === 'krw' && bnValue.lt(1)) {
        result = '< 1';
      } else if ((type === 'usd' || type === 'ratio') && bnValue.lt(0.01)) {
        result = '< 0.01';
      } else if (type === 'token-short' && bnValue.lt(0.0001)) {
        result = '< 0.0001';
      } else if (type === 'detail') {
        result = formatCommas(bnValue, decimal);
        const [, decimalPart] = formatCommas(bnValue, 36).split('.');
        result = decimalPart
          ? `0.${decimalPart.replace(/[1-9].*$/, '')}${
              decimalPart.replace(/^0*/, '')[0]
            }`
          : '0';
      } else {
        result = formatCommas(bnValue, decimal);
      }
    }
  }

  return result;
};

export const getSignString = (value: UnknownNumber) => {
  const bnValue = new BN(value);

  let result = '';

  if (isNormalPositive(bnValue.abs(), true)) {
    if (bnValue.gt('0')) {
      result = '+';
    } else {
      result = '-';
    }
  }

  return result;
};

export const parseResponse2Hex = <T>(response?: T) => {
  let result: string = '0x0';

  if (response instanceof BN || typeof response === 'number') {
    result = num2Hex(response);
  } else if (typeof response === 'string') {
    result = response;
  } else if (typeof response === 'object' || typeof response === 'bigint') {
    result = num2Hex(`${response}`);
  }

  return result;
};
export const pickResponse = (
  response: UnknownResponse,
  index: number = 0,
  defaultValue: string = ''
): string => {
  let result = defaultValue;

  if (response) {
    if (
      response instanceof BN ||
      typeof response === 'number' ||
      typeof response === 'bigint' ||
      typeof response === 'string'
    ) {
      result = parseResponse2Hex(response);
    } else if (typeof response === 'object') {
      if (response.length && response.length > index && response[index]) {
        const targetResponse = response[index];

        result = parseResponse2Hex(targetResponse);
      } else {
        result = parseResponse2Hex(response);
      }
    }
  }

  return result;
};
export const pickNumberResponse = (
  response?: UnknownResponse,
  index: number = 0,
  defaultValue: UnknownNumber = 0
): BN =>
  new BN(pickResponse(response, index, num2Hex(defaultValue)) ?? defaultValue);
export const pickStringResponse = (
  response?: UnknownResponse,
  index: number = 0,
  defaultValue: string = ''
): string => pickResponse(response, index, defaultValue);

// original usage
export const call: ContractFunctionCaller = async (option, args) => {
  const values = args ?? [];
  const { wallet, fragment, to, onlyBifrost } = option;
  const from = option.from ?? wallet?.getAddress();

  if (!fragment) {
    log(option, args);
    throw new Error('No target fragment');
  }
  if (!(to && isValidAddress(to))) {
    log(option, args);
    throw new Error('Invalid target address');
  }

  try {
    if (!(wallet && wallet.getProvider())) {
      const message = `Invalid wallet connected`;
      // log(message, option, args);
      throw new Error(message);
    }

    if (onlyBifrost && !new BN(wallet.getChainId() ?? 0).eq(CHAIN_ID_BIFROST)) {
      const message = `Invalid connected chain ${wallet.getChainId()} ${CHAIN_ID_BIFROST}`;
      // log(message, option, args);
      throw new Error(message);
    }

    return await wallet.call(to, fragment, values, from);
  } catch (error) {
    return await rpcRawContractCall(
      {
        from,
        to,
        signature: fragment,
        values,
      },
      CHAIN_RPC_URL_BIFROST,
      'latest'
    );
  }
};
export const send: ContractFunctionSender = async (option, args) => {
  const values = args ?? [];
  const { wallet, fragment, to, onlyBifrost } = option;
  const value = num2Hex(option.value ?? 0);
  const from = wallet.getAddress();

  if (!wallet.getProvider()) {
    log(option, args);
    throw new Error('No wallet provider');
  }
  if (!fragment) {
    log(option, args);
    throw new Error('No target fragment');
  }
  if (!to) {
    log(option, args);
    throw new Error('No target address');
  }
  if (onlyBifrost && !new BN(wallet.getChainId() ?? 0).eq(CHAIN_ID_BIFROST)) {
    log(option, args);
    throw new Error(
      `Invalid connected chain ${wallet.getChainId()} ${CHAIN_ID_BIFROST}`
    );
  }

  return await wallet.send(to, fragment, values, value, from);
};

export const rpcCallBalance = async (address: string) =>
  pickNumberResponse(
    await rpcRawCall<UnknownStringResponse>(
      CHAIN_RPC_URL_BIFROST,
      METHOD_BALANCE,
      [address, 'latest']
    )
  );
export const callBalance = async (wallet: Wallet) => {
  const provider = wallet?.getProvider();
  const address = wallet?.getAddress();

  if (!(provider && address)) {
    return new BN(0);
  }

  return pickNumberResponse(
    await rawCall<UnknownStringResponse>(provider, METHOD_BALANCE, [
      address,
      'latest',
    ])
  );
};
export const rpcCallEstimateGas = async (encodedParams: EncodedParams) =>
  pickNumberResponse(
    await rpcRawCall<string>(CHAIN_RPC_URL_BIFROST, METHOD_ESTIMATE, [
      encodedParams,
      'latest',
    ])
  );
export const callEstimateGas = async (
  wallet: Wallet,
  encodedParams: EncodedParams
) => {
  const provider = wallet?.getProvider();

  if (!provider) {
    return new BN(0);
  }

  return pickNumberResponse(
    await rawCall<string>(provider, METHOD_ESTIMATE, [encodedParams, 'latest'])
  );
};

// sender
export const changeChain = async (wallet: Wallet, chain?: Chain) => {
  if (!chain) {
    return;
  }

  const chainInfo: AddChainParameter = {
    chainId: num2Hex(chain.id),
    chainName: chain.name,
    rpcUrls: chain.rpcUrls,
    nativeCurrency: {
      name: chain?.coin?.symbol ?? '',
      symbol: chain?.coin?.symbol ?? '',
      decimals: chain?.coin?.decimals ?? 18,
    },
    blockExplorerUrls: [chain.explorerUrl],
  };
  return wallet.switchAndAddChain(chainInfo);
};
export const sendWithSendParams = (
  wallet: Wallet,
  sendParams: SendParams,
  gasPrice?: UnknownNumber,
  gas?: UnknownNumber
) =>
  send(
    {
      wallet,
      to: sendParams.to,
      fragment: sendParams.signature,
      value: sendParams.value,
      gasPrice: gasPrice ? num2Hex(gasPrice) : undefined,
      gas: gas ? num2Hex(gas) : undefined,
    },
    sendParams.values
  );
