import {
  useWallet,
  address2shorted,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import styled from '@emotion/styled';
import { useAtom } from 'jotai';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useRef, useState } from 'react';
import WalletTypeIcon from 'components/WalletTypeIcon';
import { IconCopy } from 'components/icons';
import Inline from 'components/styled/Inline';
import useIsMounted from 'hooks/useIsMounted';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { isShowWalletAtom } from 'store/ui';
import PopupContainer from './PopupContainer';
import PopupList, { PopupListItem } from './PopupList';
import PopupTitle from './PopupTitle';

const Root = styled(PopupContainer)`
  width: 200px;
`;
const Disconnect = styled.div`
  color: ${({ theme }) => theme.color.blueLink};
`;
const Copied = styled.div`
  position: absolute;
  top: 24px;
  right: 8px;
  padding: 8px;
  font-size: 12px;
  color: ${({ theme }) => theme.color.tint};
  border-radius: ${({ theme }) => theme.radius.sm};
  background-color: ${({ theme }) => theme.color.popupBackground};
  box-shadow: ${({ theme }) => theme.boxShadow.active};
`;

const PopupWallet: React.FC = () => {
  const { t } = useTranslation('common');

  const ref = useRef(null);

  const { wallet, account: address } = useWallet();

  const [isShowWallet, setShowWallet] = useAtom(isShowWalletAtom);
  const isMounted = useIsMounted(isShowWallet);
  const [showCopied, setShowCopied] = useState(false);

  useOnClickOutside(ref, () => setShowWallet(false));

  const handleDisconnect = useCallback(async () => {
    await wallet.disconnect();
    setShowWallet(false);
  }, [setShowWallet, wallet]);
  const handleCopy = useCallback(() => {
    const tempElem = document.createElement('textarea');
    tempElem.value = address ?? '';
    document.body.appendChild(tempElem);

    tempElem.select();
    document.execCommand('copy');
    document.body.removeChild(tempElem);
    setShowCopied(true);
  }, [address]);

  useEffect(() => {
    if (showCopied) {
      setTimeout(() => {
        setShowCopied(false);
      }, 3000);
    }
  }, [showCopied]);

  if (!isMounted) {
    return <></>;
  }

  return (
    <Root ref={ref} isShowing={isShowWallet}>
      <PopupTitle>{t('connectedWallet')}</PopupTitle>
      <PopupList>
        <PopupListItem onClick={handleCopy}>
          <Inline gap="sm">
            <WalletTypeIcon />
            {address2shorted(address ?? '')}
          </Inline>
          <IconCopy />
        </PopupListItem>
        <PopupListItem onClick={handleDisconnect}>
          <div />
          <Disconnect>{t('disconnect')}</Disconnect>
        </PopupListItem>
      </PopupList>
      {showCopied && <Copied>{t('copied')}</Copied>}
    </Root>
  );
};

export default PopupWallet;
