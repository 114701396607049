import { isValidAddress } from '@bifrost-platform/bifront-sdk-react-wallet';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useCallback, useMemo } from 'react';
import TokenPair from 'components/TokenPair';
import { PATH_POOL } from 'configs/paths';
import tokenPairTitle from 'lib/tokenPairTitle';
import Token from 'types/Token';
import { Props as DialogProps } from '.';
import DialogConfirm from './DialogConfirm';

export type Props = DialogProps & {
  address?: string;
  tokens?: Token[];
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;
const PoolContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  min-height: 100px;
  padding: 8px 0px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;
const Text = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
`;

const DialogPoolDepositCta: React.FC<Props> = ({
  open,
  address,
  tokens,
  onClose: handleClose,
}) => {
  const { t } = useTranslation('dialog');

  const router = useRouter();

  const title = useMemo(() => t('poolDepositCta.title'), [t]);
  const content = useMemo(
    () => (
      <Container>
        <PoolContainer>
          {tokens ? (
            <>
              <TokenPair isShowTitle={false} size={48} tokens={tokens} />
              <div>{tokenPairTitle(tokens)}</div>
            </>
          ) : (
            ''
          )}
        </PoolContainer>
        <Text>
          {(t('poolDepositCta.content', [tokenPairTitle(tokens)]) as string)
            .split('\n')
            .map((line, lineIndex) => (
              <div key={lineIndex}>{line}</div>
            ))}
        </Text>
      </Container>
    ),
    [t, tokens]
  );

  const handleConfirm = useCallback(() => {
    if (isValidAddress(address)) {
      router.push(`${PATH_POOL}/${address}/add-stable-liquidity`);
      handleClose?.({}, 'flowFinish');
    }
  }, [address, handleClose, router]);

  const confirmProps = useMemo(
    () => ({ children: t('poolDepositCta.action'), onClick: handleConfirm }),
    [handleConfirm, t]
  );

  return (
    <DialogConfirm
      open={open}
      width={360}
      title={title}
      content={content}
      confirmProps={confirmProps}
      onClose={handleClose}
    />
  );
};

export default DialogPoolDepositCta;
