import TRANSITION from './transition';

const TRANSITION_SET_HOVER_SCALE = `
  ${TRANSITION}
  will-change: transform;
  &:hover {
    transform: scale(1.1);
  }
`;

export default TRANSITION_SET_HOVER_SCALE;
