import { useWallet } from '@bifrost-platform/bifront-sdk-react-wallet';
import * as Sentry from '@sentry/nextjs';
import { Analytics, logEvent } from 'firebase/analytics';
import { FirebaseApp } from 'firebase/app';
import { setDoc, doc, Firestore } from 'firebase/firestore';
import { useAtomValue } from 'jotai';
import moment from 'moment';
import { useRouter } from 'next/router';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  getInitializedAnalytics,
  getInitializedFirebase,
  getInitializedFirestore,
  parseUserActionError,
} from 'lib/firebase';
import getWindowLocation from 'lib/getWindowLocation';
import log, { logError } from 'lib/log';
import uuid from 'lib/uuid';
import {
  currencyAtom,
  isShowConnectWalletAtom,
  isShowWalletAtom,
  isShowSettingAtom,
  isShowSelectChainAtom,
  isShowSelectTokenAtom,
  isShowSelectCoinAtom,
  isShowSelectPairTokenAtom,
  isOpenDialogSwapSettingAtom,
  isOpenDialogLpStakeCtaAtom,
} from 'store/ui';
import DataWithEmpty from 'types/DataWithEmpty';
import {
  TransactionHistoryCategory,
  TransactionHistoryCommonType,
  TransactionHistoryPoolType,
  TransactionHistorySwapType,
} from 'types/TransactionHistory';
import {
  FirebasePoolAction,
  FirebaseSwapAction,
  UserAction,
} from 'types/firestore/FirebaseUserAction';

export type Props = {
  firebase?: FirebaseApp;
  firestore?: Firestore;
  analytics?: Analytics;
  initializeStore?: () => boolean;
  initializeAnalytics?: () => boolean;
  initialize?: () => boolean;
  addSwapAction?: (
    userAction: UserAction<TransactionHistorySwapType>
  ) => Promise<boolean>;
  addPoolAction?: (
    userAction: UserAction<TransactionHistoryPoolType>,
    poolAddress?: string
  ) => Promise<boolean>;
  addCommonAction?: (
    category: TransactionHistoryCategory,
    userAction: UserAction<TransactionHistoryCommonType>
  ) => Promise<boolean>;
};

export const Context = createContext<Props>({});

export const FirestoreProvider = ({ children }: PropsWithChildren) => {
  const router = useRouter();

  const { account, chainId } = useWallet();

  const currency = useAtomValue(currencyAtom);
  const isShowConnectWallet = useAtomValue(isShowConnectWalletAtom);
  const isShowWallet = useAtomValue(isShowWalletAtom);
  const isShowSetting = useAtomValue(isShowSettingAtom);
  const isShowSelectChain = useAtomValue(isShowSelectChainAtom);
  const isShowSelectToken = useAtomValue(isShowSelectTokenAtom);
  const isShowSelectCoin = useAtomValue(isShowSelectCoinAtom);
  const isShowSelectPairToken = useAtomValue(isShowSelectPairTokenAtom);
  const isOpenDialogSwapSetting = useAtomValue(isOpenDialogSwapSettingAtom);
  const isOpenDialogLpStakeCta = useAtomValue(isOpenDialogLpStakeCtaAtom);

  const [firebase, setFirebase] = useState<DataWithEmpty<FirebaseApp>>();
  const [firestore, setFirestore] = useState<DataWithEmpty<Firestore>>();
  const [analytics, setAnalytics] = useState<DataWithEmpty<Analytics>>();

  const initializeStore = useCallback(() => {
    let result = false;
    try {
      if (firebase && !firestore) {
        const initializedFirestore = getInitializedFirestore(firebase);
        if (initializedFirestore) {
          setFirestore(initializedFirestore);
          result = true;
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    }
    return result;
  }, [firebase, firestore]);
  const initializeAnalytics = useCallback(() => {
    let result = false;
    try {
      if (firebase && !analytics) {
        const initializedAnalytics = getInitializedAnalytics(firebase);
        if (initializedAnalytics) {
          setAnalytics(initializedAnalytics);
          result = true;
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    }
    return result;
  }, [firebase, analytics]);
  const initialize = useCallback(() => {
    let result = false;
    try {
      if (!firebase) {
        const initializedFirebase = getInitializedFirebase();
        if (initializedFirebase) {
          setFirebase(initializedFirebase);

          result = true;
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    }
    return result;
  }, [firebase]);
  const event = useCallback(
    (eventName: string, eventParams?: { [key: string]: any }) => {
      if (analytics) {
        try {
          logEvent(analytics, eventName, eventParams);
        } catch (error) {
          Sentry.captureException(error);
        }
      }
    },
    [analytics]
  );
  const addDoc = useCallback(
    async (path: string, data: any) => {
      let result = false;
      if (!firestore) {
        logError('no firestore');
        initialize();
        return result;
      }

      try {
        log(path, data);
        await setDoc(doc(firestore, path, uuid()), data);
        try {
          event('action', data);
        } catch (error) {}
        result = true;
      } catch (error) {
        logError(error);
      }
      return result;
    },
    [firestore, event, initialize]
  );
  const addSwapAction = useCallback(
    async (userAction: UserAction<TransactionHistorySwapType>) => {
      const address = account ?? '';
      const id = userAction.id ?? uuid();
      const tokens = userAction.tokens ?? [];
      const amounts = userAction.amounts ?? [];
      const timestamp = userAction.timestamp ?? Date.now();
      const timeString = moment(timestamp).format('Y/M/D HH:mm:ss');
      const transactionHash = userAction.transactionHash ?? '';
      const complete = userAction.complete ?? false;
      const error = parseUserActionError(userAction.error);
      const origin = getWindowLocation()?.origin || '';
      const location = `${getWindowLocation() || ''}`;
      const srcChain = tokens[0]?.chainId ?? 0;
      const dstChain =
        tokens.length > 1 ? tokens[tokens.length - 1]?.chainId : 0;
      const srcToken = tokens[0]?.symbol ?? '';
      const dstToken =
        tokens.length > 1 ? tokens[tokens.length - 1]?.symbol : '';
      const data: FirebaseSwapAction = {
        ...userAction,
        address,
        id,
        tokens,
        amounts,
        timestamp,
        timeString,
        transactionHash,
        complete,
        error,
        origin,
        location,
        srcChain,
        dstChain,
        srcToken,
        dstToken,
      };
      return addDoc('swapActions', data);
    },
    [account, addDoc]
  );
  const addPoolAction = useCallback(
    async (
      userAction: UserAction<TransactionHistoryPoolType>,
      poolAddress: string = ''
    ) => {
      const address = account ?? '';
      const id = userAction.id ?? uuid();
      const tokens = userAction.tokens ?? [];
      const amounts = userAction.amounts ?? [];
      const timestamp = userAction.timestamp ?? Date.now();
      const timeString = moment(timestamp).format('Y/M/D HH:mm:ss');
      const transactionHash = userAction.transactionHash ?? '';
      const complete = userAction.complete ?? false;
      const error = parseUserActionError(userAction.error);
      const origin = getWindowLocation()?.origin || '';
      const location = `${getWindowLocation() || ''}`;
      const pairSymbol = tokens.map((token) => token.symbol).join('-');
      const data: FirebasePoolAction = {
        ...userAction,
        address,
        id,
        tokens,
        amounts,
        timestamp,
        timeString,
        transactionHash,
        complete,
        error,
        origin,
        location,
        poolAddress,
        pairSymbol,
      };
      return addDoc('poolActions', data);
    },
    [account, addDoc]
  );
  const addCommonAction = useCallback(
    async (
      category: TransactionHistoryCategory,
      userAction: UserAction<TransactionHistoryCommonType>
    ) => (category === 'swap' ? addSwapAction : addPoolAction)(userAction),
    [addPoolAction, addSwapAction]
  );

  useEffect(() => {
    initialize();
  }, [initialize]);
  useEffect(() => {
    initializeStore();
  }, [initializeStore]);
  useEffect(() => {
    initializeAnalytics();
  }, [initializeAnalytics]);
  useEffect(() => {
    const handleRouteChange = (url: any) => {
      event('page_view', {
        page_path: url,
      });
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('hashChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('hashChangeComplete', handleRouteChange);
    };
  }, [event, router.events]);
  useEffect(() => {
    event('account_change', { account });
  }, [event, account]);
  useEffect(() => {
    event('chain_change', { chainId });
  }, [event, chainId]);
  useEffect(() => {
    event('currency_change', { currency });
  }, [event, currency]);
  useEffect(() => {
    if (isShowConnectWallet) {
      event('screen_view', { firebase_screen: 'isShowConnectWallet' });
    }
  }, [event, isShowConnectWallet]);
  useEffect(() => {
    if (isShowWallet) {
      event('screen_view', { firebase_screen: 'isShowWallet' });
    }
  }, [event, isShowWallet]);
  useEffect(() => {
    if (isShowSetting) {
      event('screen_view', { firebase_screen: 'isShowSetting' });
    }
  }, [event, isShowSetting]);
  useEffect(() => {
    if (isShowSelectChain) {
      event('screen_view', { firebase_screen: 'isShowSelectChain' });
    }
  }, [event, isShowSelectChain]);
  useEffect(() => {
    if (isShowSelectToken) {
      event('screen_view', { firebase_screen: 'isShowSelectToken' });
    }
  }, [event, isShowSelectToken]);
  useEffect(() => {
    if (isShowSelectCoin) {
      event('screen_view', { firebase_screen: 'isShowSelectCoin' });
    }
  }, [event, isShowSelectCoin]);
  useEffect(() => {
    if (isShowSelectPairToken) {
      event('screen_view', { firebase_screen: 'isShowSelectPairToken' });
    }
  }, [event, isShowSelectPairToken]);
  useEffect(() => {
    if (isOpenDialogSwapSetting) {
      event('screen_view', { firebase_screen: 'isOpenDialogSwapSetting' });
    }
  }, [event, isOpenDialogSwapSetting]);
  useEffect(() => {
    if (isOpenDialogLpStakeCta) {
      event('screen_view', { firebase_screen: 'isOpenDialogLpStakeCta' });
    }
  }, [event, isOpenDialogLpStakeCta]);

  return (
    <Context.Provider
      value={{
        firebase,
        firestore,
        analytics,
        initializeStore,
        initializeAnalytics,
        initialize,
        addSwapAction,
        addPoolAction,
        addCommonAction,
      }}>
      {children}
    </Context.Provider>
  );
};

const useFirestore = (): Props => useContext(Context);

export default useFirestore;
