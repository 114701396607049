import { useWallet } from '@bifrost-platform/bifront-sdk-react-wallet';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import PopupSelectChain from 'components/popup/PopupSelectChain';
import HeaderDropdownWithExpand from 'components/styled/btn/dropdown/HeaderDropdownWithExpand';
import { ChainImage } from 'components/styled/tokenImage';
import CHAINS, { CHAIN_BIFROST } from 'configs/chains';
import { CHAIN_NAME_BIFROST } from 'configs/chains/chainNames';
import useChainImage from 'hooks/api/biholder/useChainImage';
import { isShowSelectChainAtom } from 'store/ui';

const DropdownChain: React.FC = () => {
  const { chainId } = useWallet();

  const [isShowSelectChain, setShowSelectChain] = useAtom(
    isShowSelectChainAtom
  );

  const chain = useMemo(
    () => CHAINS.find((chain) => chain.id === chainId) ?? CHAIN_BIFROST,
    [chainId]
  );
  const title = useMemo(() => chain.name ?? CHAIN_NAME_BIFROST, [chain.name]);

  const image = useChainImage(chain.id);

  return (
    <HeaderDropdownWithExpand
      isOpen={isShowSelectChain}
      popup={<PopupSelectChain />}
      setIsOpen={setShowSelectChain}>
      <ChainImage size={24} image={image} />
      {title}
    </HeaderDropdownWithExpand>
  );
};

export default DropdownChain;
