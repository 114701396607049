import IS_TESTNET from 'lib/devEnv/envIsTestnet';
import {
  MAINNET_CHAIN_RPC_URLS_BIFROST,
  MAINNET_CHAIN_RPC_URLS_ETHEREUM,
  MAINNET_CHAIN_RPC_URLS_BSC,
  MAINNET_CHAIN_RPC_URLS_POLYGON,
  MAINNET_CHAIN_RPC_URLS_BASE,
} from './mainnet/chainRpcUrls';
import {
  TESTNET_CHAIN_RPC_URLS_BIFROST,
  TESTNET_CHAIN_RPC_URLS_ETHEREUM,
  TESTNET_CHAIN_RPC_URLS_BSC,
  TESTNET_CHAIN_RPC_URLS_POLYGON,
  TESTNET_CHAIN_RPC_URLS_BASE,
} from './testnet/chainRpcUrls';

export const CHAIN_RPC_URLS_BIFROST = IS_TESTNET
  ? TESTNET_CHAIN_RPC_URLS_BIFROST
  : MAINNET_CHAIN_RPC_URLS_BIFROST;
export const CHAIN_RPC_URLS_ETHEREUM = IS_TESTNET
  ? TESTNET_CHAIN_RPC_URLS_ETHEREUM
  : MAINNET_CHAIN_RPC_URLS_ETHEREUM;
export const CHAIN_RPC_URLS_BSC = IS_TESTNET
  ? TESTNET_CHAIN_RPC_URLS_BSC
  : MAINNET_CHAIN_RPC_URLS_BSC;
export const CHAIN_RPC_URLS_POLYGON = IS_TESTNET
  ? TESTNET_CHAIN_RPC_URLS_POLYGON
  : MAINNET_CHAIN_RPC_URLS_POLYGON;
export const CHAIN_RPC_URLS_BASE = IS_TESTNET
  ? TESTNET_CHAIN_RPC_URLS_BASE
  : MAINNET_CHAIN_RPC_URLS_BASE;

export const CHAIN_RPC_URL_BIFROST = CHAIN_RPC_URLS_BIFROST[0];
export const CHAIN_RPC_URL_ETHEREUM = CHAIN_RPC_URLS_ETHEREUM[0];
export const CHAIN_RPC_URL_BSC = CHAIN_RPC_URLS_BSC[0];
export const CHAIN_RPC_URL_POLYGON = CHAIN_RPC_URLS_POLYGON[0];
export const CHAIN_RPC_URL_BASE = CHAIN_RPC_URLS_BASE[0];

export const CHAIN_SINGLE_RPC_URLS = [
  CHAIN_RPC_URL_BIFROST,
  CHAIN_RPC_URL_ETHEREUM,
  CHAIN_RPC_URL_BSC,
  CHAIN_RPC_URL_POLYGON,
  CHAIN_RPC_URL_BASE,
];

const CHAIN_RPC_URLS = [
  CHAIN_RPC_URLS_BIFROST,
  CHAIN_RPC_URLS_ETHEREUM,
  CHAIN_RPC_URLS_BSC,
  CHAIN_RPC_URLS_POLYGON,
  CHAIN_RPC_URLS_BASE,
];

export default CHAIN_RPC_URLS;
