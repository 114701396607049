import styled from '@emotion/styled';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { IconExpand } from './icons';

export type Props = {
  size?: number;
  color?: string;
  isExpand?: boolean;
  setExpand?: Dispatch<SetStateAction<boolean>>;
};

const Container = styled.div<{ isExpand?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  user-select: none;
  transition: transform ${({ theme }) => theme.transition.single};
  transform: ${({ isExpand }) => (isExpand ? 'rotate(180deg)' : '')};
`;

const Expand: React.FC<Props> = ({ size, color, isExpand, setExpand }) => {
  const handleClick: React.MouseEventHandler<HTMLImageElement> =
    useCallback(() => {
      setExpand?.(!isExpand);
    }, [isExpand, setExpand]);

  return (
    <Container isExpand={isExpand} onClick={handleClick}>
      <IconExpand isReverse color={color} size={size} />
    </Container>
  );
};

export default Expand;
