import { ADD_EMPTY } from 'configs/address';
import { MAINNET_CHAIN_ID_BIFROST } from 'configs/chains/mainnet/chainId';
import theme from 'styles/theme';
import Token from 'types/Token';
import {
  TOKEN_NAME_BFC,
  TOKEN_NAME_WBFC,
  TOKEN_NAME_BIFI,
  TOKEN_NAME_ETH,
  TOKEN_NAME_BNB,
  TOKEN_NAME_POL,
  TOKEN_NAME_DAI,
  TOKEN_NAME_USDC,
  TOKEN_NAME_USDT,
  TOKEN_NAME_BUSD,
  TOKEN_NAME_P2D,
  TOKEN_NAME_WBTC,
  TOKEN_NAME_BTCB,
  TOKEN_NAME_BTCUSD,
} from './tokenNames';
import {
  TOKEN_SYMBOL_BFC,
  TOKEN_SYMBOL_WBFC,
  TOKEN_SYMBOL_BIFI,
  TOKEN_SYMBOL_ETH,
  TOKEN_SYMBOL_BNB,
  TOKEN_SYMBOL_POL,
  TOKEN_SYMBOL_DAI,
  TOKEN_SYMBOL_USDC,
  TOKEN_SYMBOL_USDT,
  TOKEN_SYMBOL_BUSD,
  TOKEN_SYMBOL_P2D,
  TOKEN_SYMBOL_WBTC,
  TOKEN_SYMBOL_BTCB,
  TOKEN_SYMBOL_BTCUSD,
} from './tokenSymbols';

export const MAINNET_TOKEN_ADDRESS_BFC = ADD_EMPTY;
export const MAINNET_TOKEN_ADDRESS_WBFC =
  '0x1c1b06405058AbE02e4748753aeD1458BEFEE3B9';
export const MAINNET_TOKEN_ADDRESS_BIFI =
  '0x047938C3aD13c1eB821C8e310B2B6F889b6d0003';
export const MAINNET_TOKEN_ADDRESS_ETH =
  '0x6c9944674C1D2cF6c4c4999FC7290Ba105dcd70e';
export const MAINNET_TOKEN_ADDRESS_BNB =
  '0xB800EaF843F962DFe5e145A8c9D07A3e70b11d7F';
export const MAINNET_TOKEN_ADDRESS_POL =
  '0x21ad243b81eff53482F6F6E7C76539f2CfC0B734';
export const MAINNET_TOKEN_ADDRESS_USDC =
  '0x640952E7984f2ECedeAd8Fd97aA618Ab1210A21C';
export const MAINNET_TOKEN_ADDRESS_USDT =
  '0x3eA8654d5755e673599473ab37d92788B5bA12aE';
export const MAINNET_TOKEN_ADDRESS_DAI =
  '0xcDB9579Db96EB5C8298dF889D915D0FF668AfF2a';
export const MAINNET_TOKEN_ADDRESS_BUSD = '';
export const MAINNET_TOKEN_ADDRESS_P2D =
  '0xaa2e0911ac56c6f8a9c4f0006a8c907d5d180a6a';
export const MAINNET_TOKEN_ADDRESS_WBTC =
  '0x7b8fac5f29e101baab33c5f9c39d4f85ba2cc7c1';
export const MAINNET_TOKEN_ADDRESS_BTCB =
  '0xd267f821f1b8344b5a63626c8c824697194a173e';
export const MAINNET_TOKEN_ADDRESS_BTCUSD =
  '0x6906Ccda405926FC3f04240187dd4fAd5DF6d555';

export const MAINNET_TOKEN_BFC: Token = {
  chainId: MAINNET_CHAIN_ID_BIFROST,
  address: MAINNET_TOKEN_ADDRESS_BFC,
  name: TOKEN_NAME_BFC,
  symbol: TOKEN_SYMBOL_BFC,
  decimals: 18,
  isCoin: true,
  color: theme.color.bfc,
};
export const MAINNET_TOKEN_WBFC: Token = {
  chainId: MAINNET_CHAIN_ID_BIFROST,
  address: MAINNET_TOKEN_ADDRESS_WBFC,
  name: TOKEN_NAME_WBFC,
  symbol: TOKEN_SYMBOL_WBFC,
  decimals: 18,
  isCoin: false,
  color: theme.color.bfc,
};
export const MAINNET_TOKEN_BIFI: Token = {
  chainId: MAINNET_CHAIN_ID_BIFROST,
  address: MAINNET_TOKEN_ADDRESS_BIFI,
  name: TOKEN_NAME_BIFI,
  symbol: TOKEN_SYMBOL_BIFI,
  decimals: 18,
  color: theme.color.bifi,
};
export const MAINNET_TOKEN_ETH: Token = {
  chainId: MAINNET_CHAIN_ID_BIFROST,
  address: MAINNET_TOKEN_ADDRESS_ETH,
  name: TOKEN_NAME_ETH,
  symbol: TOKEN_SYMBOL_ETH,
  decimals: 18,
  color: theme.color.eth,
};
export const MAINNET_TOKEN_BNB: Token = {
  chainId: MAINNET_CHAIN_ID_BIFROST,
  address: MAINNET_TOKEN_ADDRESS_BNB,
  name: TOKEN_NAME_BNB,
  symbol: TOKEN_SYMBOL_BNB,
  decimals: 18,
  color: theme.color.bnb,
};
export const MAINNET_TOKEN_POL: Token = {
  chainId: MAINNET_CHAIN_ID_BIFROST,
  address: MAINNET_TOKEN_ADDRESS_POL,
  name: TOKEN_NAME_POL,
  symbol: TOKEN_SYMBOL_POL,
  decimals: 18,
  color: theme.color.pol,
};
export const MAINNET_TOKEN_USDC: Token = {
  chainId: MAINNET_CHAIN_ID_BIFROST,
  address: MAINNET_TOKEN_ADDRESS_USDC,
  name: TOKEN_NAME_USDC,
  symbol: TOKEN_SYMBOL_USDC,
  decimals: 6,
  color: theme.color.usdc,
};
export const MAINNET_TOKEN_USDT: Token = {
  chainId: MAINNET_CHAIN_ID_BIFROST,
  address: MAINNET_TOKEN_ADDRESS_USDT,
  name: TOKEN_NAME_USDT,
  symbol: TOKEN_SYMBOL_USDT,
  decimals: 6,
  color: theme.color.usdt,
};
export const MAINNET_TOKEN_DAI: Token = {
  chainId: MAINNET_CHAIN_ID_BIFROST,
  address: MAINNET_TOKEN_ADDRESS_DAI,
  name: TOKEN_NAME_DAI,
  symbol: TOKEN_SYMBOL_DAI,
  decimals: 18,
  color: theme.color.dai,
};
export const MAINNET_TOKEN_BUSD: Token = {
  chainId: MAINNET_CHAIN_ID_BIFROST,
  address: MAINNET_TOKEN_ADDRESS_BUSD,
  name: TOKEN_NAME_BUSD,
  symbol: TOKEN_SYMBOL_BUSD,
  decimals: 18,
  color: theme.color.busd,
};
export const MAINNET_TOKEN_P2D: Token = {
  chainId: MAINNET_CHAIN_ID_BIFROST,
  address: MAINNET_TOKEN_ADDRESS_P2D,
  name: TOKEN_NAME_P2D,
  symbol: TOKEN_SYMBOL_P2D,
  decimals: 18,
  color: theme.color.p2d,
};
export const MAINNET_TOKEN_WBTC: Token = {
  chainId: MAINNET_CHAIN_ID_BIFROST,
  address: MAINNET_TOKEN_ADDRESS_WBTC,
  name: TOKEN_NAME_WBTC,
  symbol: TOKEN_SYMBOL_WBTC,
  decimals: 8,
  color: theme.color.wbtc,
};
export const MAINNET_TOKEN_BTCB: Token = {
  chainId: MAINNET_CHAIN_ID_BIFROST,
  address: MAINNET_TOKEN_ADDRESS_BTCB,
  name: TOKEN_NAME_BTCB,
  symbol: TOKEN_SYMBOL_BTCB,
  decimals: 18,
  color: theme.color.btcb,
};
export const MAINNET_TOKEN_BTCUSD: Token = {
  chainId: MAINNET_CHAIN_ID_BIFROST,
  address: MAINNET_TOKEN_ADDRESS_BTCUSD,
  name: TOKEN_NAME_BTCUSD,
  symbol: TOKEN_SYMBOL_BTCUSD,
  decimals: 18,
  color: theme.color.btcusd,
};

const MAINNET_TOKENS = [
  MAINNET_TOKEN_BFC,
  MAINNET_TOKEN_WBFC,
  MAINNET_TOKEN_BIFI,
  MAINNET_TOKEN_ETH,
  MAINNET_TOKEN_BNB,
  MAINNET_TOKEN_POL,
  MAINNET_TOKEN_DAI,
  MAINNET_TOKEN_USDC,
  MAINNET_TOKEN_USDT,
  MAINNET_TOKEN_BUSD,
  MAINNET_TOKEN_P2D,
  MAINNET_TOKEN_WBTC,
  MAINNET_TOKEN_BTCB,
  MAINNET_TOKEN_BTCUSD,
];

export default MAINNET_TOKENS;
