import { ChangeEvent } from 'react';

export const parseNumberInput = (value?: string) =>
  (value ?? '').replace(/[^.\d]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2');

export const parsePositiveInput = (value?: string) =>
  (value ?? '').replace(/[^\d]/g, '');

export const handleNumberInputChange =
  (onChange?: (e: ChangeEvent<HTMLInputElement>) => void) =>
  (e: ChangeEvent<HTMLInputElement>) =>
    onChange?.({
      ...e,
      target: { ...e.target, value: parseNumberInput(e.target.value) },
    });

export const handlePositiveInputChange =
  (onChange?: (e: ChangeEvent<HTMLInputElement>) => void) =>
  (e: ChangeEvent<HTMLInputElement>) =>
    onChange?.({
      ...e,
      target: { ...e.target, value: parsePositiveInput(e.target.value) },
    });
