import styled from '@emotion/styled';

const PopupList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const PopupListItem = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: ${({ theme }) => theme.gap.sm} 0px;
  color: ${({ theme }) => theme.color.text};
  ${({ theme }) => theme.transitionSet.hoverOpacityButton}
`;

export default PopupList;
