import ENV_BIHOLDER_API from 'lib/devEnv/envBiholderApi';
import ENV_CHAINRUNNER_API from 'lib/devEnv/envChainrunnerApi';
import ENV_EVERDEX_API from 'lib/devEnv/envEverdexApi';

// DEV Everdex URL = api-everdex.thebifrost.dev
// DEV Chainrunner URL = api-chainrunner.thebifrost.dev
// DEV BiHolder URL = biholder-view.thebifrost.dev

const DEV_EVERDEX_URL = 'api-everdex.thebifrost.dev';
const PROD_EVERDEX_URL = 'api.everdex.app';

const DEV_CHAINRUNNER_URL = 'api-chainrunner.thebifrost.dev';
const STAGE_CHAINRUNNER_URL = 'api.stage.chainrunner.io';
const PROD_CHAINRUNNER_URL = 'api.chainrunner.io';

const DEV_BIHOLDER_URL = 'biholder-view.thebifrost.dev';
const PROD_BIHOLDER_URL = 'biholder-view.thebifrost.io';

const HTTP_PREFIX = 'https://';
const WS_PREFIX = 'wss://';

export const URL_ORIGIN_EVERDEX = `${HTTP_PREFIX}${
  ENV_EVERDEX_API === 'production' ? PROD_EVERDEX_URL : DEV_EVERDEX_URL
}`;
export const URL_ORIGIN_CHAINRUNNER = `${HTTP_PREFIX}${
  ENV_CHAINRUNNER_API === 'production'
    ? PROD_CHAINRUNNER_URL
    : ENV_CHAINRUNNER_API === 'stage'
      ? STAGE_CHAINRUNNER_URL
      : DEV_CHAINRUNNER_URL
}/http/v3/call`;
export const URL_ORIGIN_CHAINRUNNER_WSS = `${WS_PREFIX}${
  ENV_CHAINRUNNER_API === 'production'
    ? PROD_CHAINRUNNER_URL
    : ENV_CHAINRUNNER_API === 'stage'
      ? STAGE_CHAINRUNNER_URL
      : DEV_CHAINRUNNER_URL
}`;
export const URL_ORIGIN_BIHOLDER = `${HTTP_PREFIX}${
  ENV_BIHOLDER_API === 'production' ? PROD_BIHOLDER_URL : DEV_BIHOLDER_URL
}`;
