import {
  DISABLED_PATHNAMES_DEV,
  DISABLED_PATHNAMES_MAINNET,
  DISABLED_PATHNAMES_PROD,
  DISABLED_PATHNAMES_TESTNETNET,
} from 'configs/disabledPathnames';
import { PATH_HOME } from 'configs/paths';
import IS_TESTNET from './envIsTestnet';
import IS_DEV from './isDev';

const checkIsAvailablePathname = (pathname?: string) => {
  const currentPathname = `/${(pathname ?? PATH_HOME).replace(/^\//, '')}`;

  let result = true;

  if (IS_DEV) {
    result =
      result &&
      !DISABLED_PATHNAMES_DEV.find(
        (disabledPathname) => currentPathname.indexOf(disabledPathname) === 0
      );
  } else {
    result =
      result &&
      !DISABLED_PATHNAMES_PROD.find(
        (disabledPathname) => currentPathname.indexOf(disabledPathname) === 0
      );
  }

  if (IS_TESTNET) {
    result =
      result &&
      !DISABLED_PATHNAMES_TESTNETNET.find(
        (disabledPathname) => currentPathname.indexOf(disabledPathname) === 0
      );
  } else {
    result =
      result &&
      !DISABLED_PATHNAMES_MAINNET.find(
        (disabledPathname) => currentPathname.indexOf(disabledPathname) === 0
      );
  }

  return result;
};

export default checkIsAvailablePathname;
