import {
  ProviderType,
  useWallet,
  getStorageItem,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import { useCallback } from 'react';
import useIsConnected from 'hooks/useIsConnected';

const useLastLogin = () => {
  const { wallet } = useWallet();
  const isConnected = useIsConnected();

  return useCallback(async () => {
    const address = getStorageItem('lastLogin') as string;

    if (!address || isConnected) return;

    const walletType =
      (getStorageItem('lastLoginProviderType') as ProviderType) ?? 'metamask';
    await wallet.connect(walletType);
  }, [isConnected, wallet]);
};

export default useLastLogin;
