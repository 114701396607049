import {
  isValidAddress,
  useWallet,
  formatUnit,
  isNormalPositive,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import BN from 'bignumber.js';
import { useCallback, useEffect, useState } from 'react';
import DEFAULT_BLOCK_TERM from 'configs/defaultValue/defaultBlock';
import useIsConnected from 'hooks/useIsConnected';
import { callTokenBalance } from 'lib/contract/erc20';
import LpRewardPool from 'types/LpRewardPool';
import { openErrorToast } from 'utils/toastUtils';

const useLpRewardBalanceAmounts = (
  lpRewardPools?: LpRewardPool[],
  flag?: boolean,
  interval: number = DEFAULT_BLOCK_TERM
) => {
  const { wallet } = useWallet();
  const isConnected = useIsConnected();

  const [balanceAmounts, setBalanceAmounts] = useState<BN[]>([]);

  const syncBalanceAmounts = useCallback(async () => {
    if (!lpRewardPools?.length) {
      setBalanceAmounts([]);
      return;
    }

    try {
      const newBalanceAmounts = await Promise.all(
        lpRewardPools.map(async ({ rewardContract: { lpTokenAddress } }) => {
          let result = new BN(0);

          if (lpTokenAddress && isValidAddress(lpTokenAddress)) {
            try {
              const balanceAmount = await callTokenBalance(
                lpTokenAddress,
                wallet,
                wallet.getAddress(),
                true
              );

              result = isNormalPositive(balanceAmount, true)
                ? formatUnit(balanceAmount, 18)
                : new BN(0);
            } catch (error) {}
          }

          return result;
        })
      );

      setBalanceAmounts(newBalanceAmounts);
    } catch (error) {
      setBalanceAmounts([]);
      openErrorToast(error);
    }
  }, [lpRewardPools, wallet]);

  useEffect(() => {
    syncBalanceAmounts();

    const intervalId = setInterval(() => syncBalanceAmounts(), interval);

    return () => clearInterval(intervalId);
  }, [interval, flag, isConnected, syncBalanceAmounts]);

  return balanceAmounts;
};

export default useLpRewardBalanceAmounts;
