import { useAtom } from 'jotai';
import { IconMenu } from 'components/icons';
import PopupSetting from 'components/popup/PopupSetting';
import HeaderDropdown from 'components/styled/btn/dropdown/HeaderDropdown';
import useIsMaintaining from 'hooks/useIsMaintaining';
import { isShowSettingAtom } from 'store/ui';
import theme from 'styles/theme';

const DropdownSetting: React.FC = () => {
  const isMaintaining = useIsMaintaining();

  const [isShowSetting, setShowSetting] = useAtom(isShowSettingAtom);

  return (
    <HeaderDropdown
      isOpen={isShowSetting}
      popup={!isMaintaining && <PopupSetting />}
      setIsOpen={setShowSetting}>
      <IconMenu color={theme.color.point} />
    </HeaderDropdown>
  );
};

export default DropdownSetting;
