import TRANSITION from './transition';

const TRANSITION_SET_HOVER_SCALE_BUTTON = `
  cursor: pointer;
  user-select: none;
  ${TRANSITION}
  will-change: transform;
  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.96);
  }
`;

export default TRANSITION_SET_HOVER_SCALE_BUTTON;
