import {
  compareLowerStr,
  isNormalPositive,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import BN from 'bignumber.js';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { ADD_EMPTY } from 'configs/address';
import { API_PATH_BIHOLER_ASSET } from 'configs/apiPath';
import { CHAIN_ID_BIFROST } from 'configs/chains/chainIds';
import useWatchingAddressMap from 'hooks/useWatchingAddressMap';
import { biholder } from 'lib/axiosInstance';
import { findMainnetTokenByAddress } from 'lib/biholder';
import { logError } from 'lib/log';
import AssetMap, { AssetByAddress, AssetData } from 'types/AssetMap';
import { ChainId } from 'types/Chain';
import DataWithEmpty from 'types/DataWithEmpty';
import { TokenAddress } from 'types/Token';
import BiHolerAsset from 'types/api/biholer/BiHolderAsset';
import { parseChainrunnerChainId } from 'utils/chainrunner';

export type Props = {
  assetMap: AssetMap;
  usdToKrwExchangeRete: string;
};

export const INTERVAL_ASSET_INFO = 30000;

export const getAssetInfo = async (
  address: TokenAddress,
  chainId: ChainId = CHAIN_ID_BIFROST
) => {
  let assetData: DataWithEmpty<AssetData>;

  if (address && chainId) {
    try {
      const { data: asset } = await biholder.get<BiHolerAsset>(
        API_PATH_BIHOLER_ASSET,
        {
          params: {
            address,
            chain_id: chainId,
          },
        }
      );

      assetData = {
        ...asset,
        lastPriceUpdateTimestamp: '',
      };

      if (asset.price) {
        const {
          price: { lastPriceUpdateTimestamp, priceList },
        } = asset;
        const priceInKrw = priceList?.find(
          (price) => price?.currencyId === 'krw'
        )?.price;
        const priceInUsd = priceList?.find(
          (price) => price?.currencyId === 'usd'
        )?.price;

        let usdToKrwExchangeRate: DataWithEmpty<string>;

        if (
          isNormalPositive(priceInKrw, true) &&
          isNormalPositive(priceInUsd, true)
        ) {
          usdToKrwExchangeRate = new BN(priceInKrw ?? 0)
            .div(priceInUsd ?? 0)
            .toString();
        }

        assetData = {
          ...assetData,
          lastPriceUpdateTimestamp,
          priceInKrw,
          priceInUsd,
          usdToKrwExchangeRate,
        };
      }
    } catch (error) {}
  }

  return assetData;
};

export const Context = createContext<Props>({
  assetMap: {},
  usdToKrwExchangeRete: '0',
});

export const AssetInfoProvider = ({ children }: PropsWithChildren) => {
  const { watchingAddressMap } = useWatchingAddressMap();

  const [assetMap, setAssetMap] = useState<AssetMap>({});
  const [usdToKrwExchangeRete, setUsdToKrwExchangeRete] = useState('0');

  const taskId = useRef<NodeJS.Timeout>();

  const updateAssetInfo = useCallback(async () => {
    try {
      const keys = Object.keys(watchingAddressMap);

      const assetInfoMap = await keys.reduce(
        async (acc, chainId) => {
          const addresses = watchingAddressMap[chainId];

          const tokens = await addresses.reduce(
            async (prev, watchingAddress) => {
              const clonePrev = await prev;
              const watchingLowerAddress = watchingAddress.toLowerCase();
              const token = compareLowerStr(watchingLowerAddress, ADD_EMPTY)
                ? {
                    chainId: parseChainrunnerChainId(chainId),
                    address: watchingLowerAddress,
                  }
                : findMainnetTokenByAddress(watchingLowerAddress);
              const targetChainId = token?.chainId ?? parseInt(chainId);
              const targetAddress = token?.address ?? watchingLowerAddress;
              const assetInfo = await getAssetInfo(
                targetAddress,
                targetChainId
              );

              if (assetInfo) {
                clonePrev[watchingLowerAddress] = assetInfo;
              }

              return Promise.resolve(clonePrev);
            },
            Promise.resolve({} as AssetByAddress)
          );

          const cloneAcc: AssetMap = await acc;

          cloneAcc[chainId] = tokens;

          return Promise.resolve(cloneAcc);
        },
        Promise.resolve({} as AssetMap)
      );

      setAssetMap(assetInfoMap);

      try {
        const firstChainTokens = Object.values(assetInfoMap)[0];

        if (firstChainTokens) {
          const { usdToKrwExchangeRate } = Object.values(firstChainTokens)[0];

          if (usdToKrwExchangeRate) {
            setUsdToKrwExchangeRete(usdToKrwExchangeRate);
          }
        }
      } catch (error) {}
    } catch (error) {
      logError(error);
    }
  }, [watchingAddressMap, setAssetMap, setUsdToKrwExchangeRete]);

  useEffect(() => {
    if (Object.keys(watchingAddressMap).length) {
      updateAssetInfo();

      if (!taskId.current) {
        taskId.current = setInterval(updateAssetInfo, INTERVAL_ASSET_INFO);
      }
    }

    return () => {
      if (taskId.current) {
        clearInterval(taskId.current);
      }
    };
  }, [watchingAddressMap, updateAssetInfo]);

  useEffect(() => {
    updateAssetInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Context.Provider
      value={{
        assetMap,
        usdToKrwExchangeRete,
      }}>
      {children}
    </Context.Provider>
  );
};

const useAssetInfo = (): Props => useContext(Context);

export default useAssetInfo;
