import { isNormalPositive } from '@bifrost-platform/bifront-sdk-react-wallet';
import styled from '@emotion/styled';
import BN from 'bignumber.js';
import { PrimitiveAtom, useAtom } from 'jotai';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useState } from 'react';
import OnOffSwitch from 'components/OnOffSwitch';
import { ActionBtn } from 'components/styled/btn';
import { SingleLineInput } from 'components/styled/input';
import HelpBadge from 'components/tooltip/HelpBadge';
import DEFAULT_DEADLINE, {
  MAX_DEADLINE,
  MIN_DEADLINE,
} from 'configs/defaultValue/defaultDeadline';
import DEFAULT_SLIPPAGE, {
  MAX_SLIPPAGE,
  MIN_SLIPPAGE,
} from 'configs/defaultValue/defaultSlippage';
import { handleNumberInputChange } from 'utils/input';
import Root, {
  Props as DialogProps,
  DialogBody,
  DialogCloseBtn,
  DialogContainer,
  DialogHeader,
  DialogTitle,
} from '.';

export type Props = DialogProps & {
  slippageAtom: PrimitiveAtom<string>;
  deadlineAtom: PrimitiveAtom<string>;
};

const Container = styled(DialogContainer)`
  min-width: 400px;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const InputLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  height: 35px;
  font-weight: 500;
`;
const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 60px;
`;

const DialogSwapSetting: React.FC<Props> = ({
  open,
  slippageAtom,
  deadlineAtom,
  onClose: handleClose,
}) => {
  const { t } = useTranslation('dialog');

  const [slippage, setSlippage] = useAtom(slippageAtom);
  const [deadline, setDeadline] = useAtom(deadlineAtom);

  const [isUsingSlippage, setIsUsingSlippage] = useState(false);

  const handleCloseClick = useCallback(
    () => handleClose?.({}, 'closeClick'),
    [handleClose]
  );
  const handleSlippageSwitchClick = useCallback(
    () => setIsUsingSlippage((value) => !value),
    []
  );
  const handleSlippageChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback((event) => setSlippage(event.target.value), [setSlippage]);
  const handleDeadlineChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback((event) => setDeadline(event.target.value), [setDeadline]);
  const handleSlippageBlur: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(() => {
      if (new BN(slippage).lt(MIN_SLIPPAGE)) {
        setSlippage(`${MIN_SLIPPAGE}`);
      }
    }, [setSlippage, slippage]);
  const handleDeadlineBlur: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(() => {
      if (new BN(deadline).lt(MIN_DEADLINE)) {
        setDeadline(`${MIN_DEADLINE}`);
      }
    }, [deadline, setDeadline]);

  useEffect(() => {
    if (
      slippage &&
      isNormalPositive(slippage) &&
      slippage[slippage.length - 1] !== '.'
    ) {
      if (new BN(slippage).gt(MAX_SLIPPAGE)) {
        setSlippage(`${MAX_SLIPPAGE}`);
      }
    }
  }, [setSlippage, slippage]);
  useEffect(() => {
    if (deadline && isNormalPositive(deadline)) {
      if (new BN(deadline).gt(MAX_DEADLINE)) {
        setDeadline(`${MAX_DEADLINE}`);
      }
    }
  }, [deadline, setDeadline]);
  useEffect(() => {
    setSlippage((isUsingSlippage ? slippage : `${DEFAULT_SLIPPAGE}`) ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUsingSlippage]);

  return (
    <Root open={open} onClose={handleClose}>
      <DialogCloseBtn onClick={handleCloseClick} />
      <Container>
        <DialogHeader>
          <DialogTitle>{t('setting.title')}</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <InputContainer>
            <InputLabel>
              {t('setting.slippage')}(%){' '}
              <HelpBadge>{t('tooltip:slippage')}</HelpBadge>
            </InputLabel>
            <InputWrapper>
              <SingleLineInput
                placeholder={`${DEFAULT_SLIPPAGE} (${t('common:fixed')})`}
                readOnly={!isUsingSlippage}
                value={isUsingSlippage ? slippage : ''}
                onChange={handleNumberInputChange(handleSlippageChange)}
                onBlur={handleSlippageBlur}
              />
              <OnOffSwitch
                isOn={isUsingSlippage}
                onClick={handleSlippageSwitchClick}
              />
            </InputWrapper>
          </InputContainer>
          <InputContainer>
            <InputLabel>
              {t('setting.deadline')}({t('common:minute')}){' '}
              <HelpBadge>{t('tooltip:deadline')}</HelpBadge>
            </InputLabel>
            <InputWrapper>
              <SingleLineInput
                placeholder={`${DEFAULT_DEADLINE}`}
                value={deadline}
                onChange={handleNumberInputChange(handleDeadlineChange)}
                onBlur={handleDeadlineBlur}
              />
            </InputWrapper>
          </InputContainer>
          <ActionBtn onClick={handleCloseClick}>
            {t('common:confirm')}
          </ActionBtn>
        </DialogBody>
      </Container>
    </Root>
  );
};

export default DialogSwapSetting;
