import IS_TESTNET from 'lib/devEnv/envIsTestnet';

// default
export const ADD_EMPTY = process.env.NEXT_PUBLIC_ADD_EMPTY ?? '';
export const ADD_DEFAULT = process.env.NEXT_PUBLIC_ADD_DEFAULT ?? '';

// swap
// 0xe9175Ff7f23fB40bfeEC87432eB4a2Ede4A176Ea UNISWAP ROUTER V2
// Smart Router
export const SWAP_MERGER = IS_TESTNET
  ? '0x0790070D296458de036bd25787c8dFfCF7E11B4b'
  : '0x7Ace89E21a5c3300B22DFE168Ae256246EBFA9E2';

// uniswap
export const UNISWAP_V2_FACTORY = IS_TESTNET
  ? '0xE828E190A16C7b2A8b9aCE16D807fDb7141f59CC'
  : '0x19f21b0AB98EC10d734E314356Ad562ae349177d';
