import { useMemo } from 'react';
import useSWR from 'swr';
import { CE_NETWORKS } from 'configs/chainrunner/chainrunnerEndpoint';
import CHAINRUNNER_PAYLOAD from 'configs/chainrunner/chainrunnerPayload';
import CHAINS from 'configs/chains';
import useIp from 'hooks/useIp';
import { fetcherChainrunner } from 'lib/fetcher';
import SwrResult from 'types/SwrResult';
import ChainrunnerResponse from 'types/api/chainrunner/ChainrunnerResponse';
import Network from 'types/api/chainrunner/Network';
import { parseChainrunnerChainId } from 'utils/chainrunner';
import { sortOrderedList } from 'utils/orderable';

const useChainrunnerNetworks = (): SwrResult<Network[]> => {
  const ip = useIp();

  const { data: rawData, error: rawError } = useSWR<
    ChainrunnerResponse<Network[]>
  >(
    {
      ...CHAINRUNNER_PAYLOAD,
      endpoint: CE_NETWORKS,
      userIP: ip,
    },
    fetcherChainrunner
  );

  const data = useMemo(
    () => rawData?.result?.result ?? [],
    [rawData?.result?.result]
  );
  const orderableData = useMemo(
    () =>
      data.map((network) => ({
        ...network,
        order: CHAINS.find(
          (chain) => chain.id === parseChainrunnerChainId(network.chainId)
        )?.order,
      })),
    [data]
  );
  const orderedData = useMemo(
    () => sortOrderedList<Network>(orderableData),
    [orderableData]
  );
  const isLoading = useMemo(() => !(rawError || rawData), [rawData, rawError]);
  const error = useMemo(
    () => rawError || rawData?.result?.error || !rawData?.result?.ok,
    [rawData?.result?.error, rawData?.result?.ok, rawError]
  );

  return {
    data: orderedData,
    isLoading,
    error,
  };
};

export default useChainrunnerNetworks;
