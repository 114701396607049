// erc20
export const FRAG_SYMBOL = 'function symbol() view returns (string)';
export const FRAG_DECIMALS = 'function decimals() view returns (uint8)';
export const FRAG_BALANCE =
  'function balanceOf(address) view returns (uint256)';
export const FRAG_ALLOWANCE =
  'function allowance(address,address) view returns (uint256)';
export const FRAG_TOTAL_SUPPLY =
  'function totalSupply() view returns (uint256)';
export const FRAG_APPROVE = 'function approve(address,uint256)';

// pair
export const FRAG_GET_RESERVES =
  'function getReserves() view returns (uint112,uint112,uint32)';

// factory
export const FRAG_GET_PAIR =
  'function getPair(address,address) view returns (address)';

// swapMerger
export const FRAG_GET_RESERVES_BY_CURVE =
  'function getReservesByCurve(address) view returns (address[],uint256[])';
export const FRAG_GET_LP_TOKEN_BY_CURVE =
  'function getLPTokenByCurve(address) view returns (address)';
export const FRAG_ADD_LIQUIDITY_ETH =
  'function addLiquidityETH(address,uint256,uint256,uint256,address,uint256) view returns (uint256,uint256,uint256)';
export const FRAG_ADD_LIQUIDITY =
  'function addLiquidity(address,address,uint256,uint256,uint256,uint256,address,uint256) view returns (uint256,uint256,uint256)';
export const FRAG_ADD_LIQUIDITY_STABLE_SWAP =
  'function addLiquidityStableSwap(address,address[],uint256[],uint256[],address,uint256) view returns (uint256[],uint256)';
export const FRAG_REMOVE_LIQUIDITY_ETH =
  'function removeLiquidityETH(address,uint256,uint256,uint256,address,uint256) view returns (uint256,uint256)';
export const FRAG_REMOVE_LIQUIDITY =
  'function removeLiquidity(address,address,uint256,uint256,uint256,address,uint256) view returns (uint256,uint256)';
export const FRAG_REMOVE_LIQUIDITY_STABLE_SWAP =
  'function removeLiquidityStableSwap(address,uint,uint[],address,uint) view returns (uint256[])';
export const FRAG_CALC_WITHDRAW_ONE_COIN =
  'function calc_withdraw_one_coin(address,uint256,address) view returns (uint256,int128)';
export const FRAG_CALC_TOKEN_AMOUNT =
  'function calc_token_amount(uint256[4],bool) view returns (uint256)';
export const FRAG_SWAP_EXACT_ETH_FOR_TOKENS =
  'function swapExactETHForTokens(uint256,address[],address,uint256) view returns (uint256[])';
export const FRAG_SWAP_ETH_FOR_EXACT_TOKENS =
  'function swapETHForExactTokens(uint256,address[],address,uint256) view returns (uint256[])';
export const FRAG_SWAP_EXACT_TOKENS_FOR_ETH =
  'function swapExactTokensForETH(uint256,uint256,address[],address,uint256) view returns (uint256[])';
export const FRAG_SWAP_TOKENS_FOR_EXACT_ETH =
  'function swapTokensForExactETH(uint256,uint256,address[],address,uint256) view returns (uint256[])';
export const FRAG_SWAP_EXACT_TOKENS_FOR_TOKENS =
  'function swapExactTokensForTokens(uint256,uint256,address[],address,uint256) view returns (uint256[])';
export const FRAG_SWAP_TOKENS_FOR_EXACT_TOKENS =
  'function swapTokensForExactTokens(uint256,uint256,address[],address,uint256) view returns (uint256[])';

// // stakingRewards
// export const FRAG_STAKING_TOKEN =
//   'function stakingToken() view returns (address)';
// export const FRAG_STAKING_STATE =
//   'function stakingState() view returns (address)';
// export const FRAG_GET_REWARDS_STATES =
//   'function getRewardsStates() view returns (address[])';
//
// export const FRAG_PERIOD_START =
//   'function periodStart(address) view returns (uint)';
// export const FRAG_PERIOD_FINISH =
//   'function periodFinish(address) view returns (uint)';
// export const FRAG_EARNED = 'function earned(address) view returns (uint[])';
//
// export const FRAG_STAKE = 'function stake(uint256)';
// export const FRAG_WITHDRAW = 'function withdraw(uint256)'; // unusing
// export const FRAG_GET_REWARD = 'function getReward()';
// export const FRAG_EXIT = 'function exit()';

// lpReward
export const FRAG_LP_REWARD_REWARD_RATE =
  'function rewardRate(address) view returns (uint)';
export const FRAG_LP_REWARD_TOTAL_SUPPLY =
  'function totalSupply() view returns (uint)';
export const FRAG_LP_REWARD_BALANCE_OF =
  'function balanceOf(address) view returns (uint)';
export const FRAG_LP_REWARD_EARNED =
  'function earned(address,address) view returns (uint)';
export const FRAG_LP_REWARD_PERIOD_FINISH =
  'function periodFinish(address) view returns (uint)';

export const FRAG_LP_REWARD_STAKE = 'function stake(uint)';
export const FRAG_LP_REWARD_GET_REWARD = 'function getReward()';
export const FRAG_LP_REWARD_EXIT = 'function exit()';

// stable pool lp token (curve)
export const FRAG_STABLE_LP_GET_VIRTUAL_PRICE =
  'function get_virtual_price() view returns (uint256)';
