import { PATH_HOME } from 'configs/paths';
import useIsPath from 'hooks/useIsPath';

const useIsRootPath = (): boolean => {
  const isPath = useIsPath([PATH_HOME], true);

  return isPath;
};

export default useIsRootPath;
