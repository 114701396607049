import {
  TOKEN_SYMBOL_BTCUSD,
  TOKEN_SYMBOL_DAI,
  TOKEN_SYMBOL_USDC,
  TOKEN_SYMBOL_USDT,
} from 'configs/tokens/tokenSymbols';
import Orderable from 'types/Orderable';
import Token from 'types/Token';

export const DEFAULT_ORDER = 9999999;

export const SYMBOLS_ORDER = [
  TOKEN_SYMBOL_DAI,
  TOKEN_SYMBOL_USDT,
  TOKEN_SYMBOL_USDC,
  TOKEN_SYMBOL_BTCUSD,
];

export const getTokenOrder = (token: Token): number =>
  token.isCoin
    ? SYMBOLS_ORDER.length + 1
    : SYMBOLS_ORDER.includes(token.symbol)
      ? SYMBOLS_ORDER.indexOf(token.symbol) + 1
      : 0;

export const compareSortingTokenFunction = (a: Token, b: Token): number =>
  getTokenOrder(b) - getTokenOrder(a);

export const sortOrderedList = <T extends Orderable>(list: T[]): T[] =>
  list.sort((a, b) => (a.order ?? DEFAULT_ORDER) - (b.order ?? DEFAULT_ORDER));
