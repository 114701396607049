import styled from '@emotion/styled';
import { useMemo } from 'react';
import useTokenImage from 'hooks/api/biholder/useTokenImage';
import useToken from 'hooks/useToken';
import Inline from './styled/Inline';
import TokenImage from './styled/tokenImage';

export type Props = {
  isShowTitle?: boolean;
  transform?: string;
  symbol?: string;
  size?: number;
  titleStyle?: React.CSSProperties;
};

const ImageContainer = styled.div<{ size: number }>`
  min-width: ${({ size }) => `${size}px`};
  max-width: ${({ size }) => `${size}px`};
  min-height: ${({ size }) => `${size}px`};
  max-height: ${({ size }) => `${size}px`};
  border-radius: 100px;
  display: flex;
`;
const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

const Token: React.FC<Props> = ({
  isShowTitle,
  symbol = '',
  transform,
  size = 32,
  titleStyle,
}) => {
  const token = useToken(symbol);

  const tokenImage = useTokenImage(token);

  const tokenSymbol = useMemo(() => token?.symbol, [token?.symbol]);

  return (
    <Inline gap="sm" style={{ transform }}>
      <ImageContainer size={size}>
        <TokenImage size={size} image={tokenImage} symbol={tokenSymbol} />
      </ImageContainer>
      {isShowTitle && symbol ? <Title style={titleStyle}>{symbol}</Title> : ''}
    </Inline>
  );
};

export default Token;
