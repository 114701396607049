import { ChainByAPI } from 'types/Chain';
import DataWithEmpty from 'types/DataWithEmpty';

// api id
export const MAINNET_CHAIN_API_ID_BIFROST: DataWithEmpty<ChainByAPI> = 'bfc';
export const MAINNET_CHAIN_API_ID_ETHEREUM: DataWithEmpty<ChainByAPI> = 'eth';
export const MAINNET_CHAIN_API_ID_BSC: DataWithEmpty<ChainByAPI> = 'bsc';
export const MAINNET_CHAIN_API_ID_POLYGON: DataWithEmpty<ChainByAPI> = 'matic';
export const MAINNET_CHAIN_API_ID_BASE: DataWithEmpty<ChainByAPI> = 'base';
export const MAINNET_CHAIN_API_ID_ARBITRUM: DataWithEmpty<ChainByAPI> = 'arbi';
