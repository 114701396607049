import styled from '@emotion/styled';
import React from 'react';

export type Props = {
  isOn?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

const Root = styled.a<{ isOn?: boolean }>`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 60px;
  padding: 4px;
  border-radius: 24px;
  gap: 4px;
  background-color: ${({ isOn }) => (isOn ? '#FF474C' : '#ccc')};
  ${({ theme }) => theme.transitionSet.hoverOpacityButton}
`;
const Text = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  color: ${({ theme }) => theme.color.beigeLight};
  font-size: 18px;
  font-weight: 700;
  text-align: center;
`;
const Indicator = styled.div<{ isOn?: boolean }>`
  position: absolute;
  width: 52px;
  height: 52px;
  margin: 4px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.color.background};
  box-shadow: ${({ theme }) => theme.boxShadow.popup};
  left: ${({ isOn }) => (isOn ? 56 : 0)}px;
  transition: ${({ theme }) => theme.transition.all};
`;

const OnOffSwitch: React.FC<Props> = ({ isOn, onClick: handleClick }) => (
  <Root isOn={isOn} onClick={handleClick}>
    <Text>On</Text>
    <Text>Off</Text>
    <Indicator isOn={isOn} />
  </Root>
);

export default OnOffSwitch;
