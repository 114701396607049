import { Transaction } from '@bifrost-platform/bifront-sdk-react-wallet';
import * as Sentry from '@sentry/nextjs';
import { TFunction } from 'next-i18next';
import { Id, toast } from 'react-toastify';

export const openTxToast = (
  tx: Transaction,
  fn?: any,
  t?: TFunction,
  message?: { success?: string; process?: string }
) => {
  toast.promise(
    new Promise((resolve, reject) => {
      tx.wait()
        .then(() => {
          fn && fn();
          resolve(true);
        })
        .catch((err: any) => {
          reject(err);
        });
    }),
    {
      pending: {
        render: () =>
          (
            (message?.process ||
              t?.('common:message.process') ||
              'Processing') as string
          )
            .split('\n')
            .map((line, lineIndex) => <div key={lineIndex}>{line}</div>),
      },
      success: {
        render: () =>
          (
            (message?.success ||
              t?.('common:message.success') ||
              'Successful') as string
          )
            .split('\n')
            .map((line, lineIndex) => <div key={lineIndex}>{line}</div>),
      },
      error: {
        render({ data }) {
          // When the promise reject, data will contains the error
          return `${(data as any).reason}`;
        },
      },
    },
    {
      toastId: tx.hash,
    }
  );
};

export const openSuccessToast = ({
  id,
  t,
  message,
}: {
  id: Id;
  t?: TFunction;
  message?: string;
}) => {
  toast.success(
    () =>
      ((message || t?.('common:message.success') || 'Success') as string)
        .split('\n')
        .map((line, lineIndex) => <div key={lineIndex}>{line}</div>),
    {
      toastId: id,
    }
  );
};

export const openErrorToast = (error: any) => {
  Sentry.captureException(error);
  if (error.reason) {
    toast.error(error.reason);
  } else if (error.message) {
    toast.error(error.message);
  }
};
