import { ChainByAPI } from 'types/Chain';
import DataWithEmpty from 'types/DataWithEmpty';

// api id
export const TESTNET_CHAIN_API_ID_BIFROST: DataWithEmpty<ChainByAPI> =
  'bfctestnet';
export const TESTNET_CHAIN_API_ID_ETHEREUM: DataWithEmpty<ChainByAPI> =
  undefined;
export const TESTNET_CHAIN_API_ID_BASE_SEPOLIA: DataWithEmpty<ChainByAPI> =
  undefined;
export const TESTNET_CHAIN_API_ID_ARBITRUM_SEPOLIA: DataWithEmpty<ChainByAPI> =
  undefined;
export const TESTNET_CHAIN_API_ID_BSC: DataWithEmpty<ChainByAPI> = undefined;
export const TESTNET_CHAIN_API_ID_POLYGON: DataWithEmpty<ChainByAPI> =
  undefined;
export const TESTNET_CHAIN_API_ID_BASE: DataWithEmpty<ChainByAPI> = undefined;
export const TESTNET_CHAIN_API_ID_ARBITRUM: DataWithEmpty<ChainByAPI> =
  undefined;
