import ChainrunnerEndpoint from 'types/api/chainrunner/ChainrunnerEndpoint';

export const CE_EXPECT_SELL: ChainrunnerEndpoint =
  'Everdex.V1.expect.swap.sell';
export const CE_EXPECT_BUY: ChainrunnerEndpoint = 'Everdex.V1.expect.swap.buy';

export const CE_EXPECT_BOUND_SELL: ChainrunnerEndpoint =
  'Everdex.V1.expect.bound.sell';
export const CE_EXPECT_BOUND_BUY: ChainrunnerEndpoint =
  'Everdex.V1.expect.bound.buy';

export const CE_NETWORKS: ChainrunnerEndpoint = 'Everdex.V1.get.info.networks';
export const CE_ASSETS: ChainrunnerEndpoint = 'Everdex.V1.get.info.assets';
export const CE_BALANCE: ChainrunnerEndpoint = 'Everdex.V1.get.account.balance';

export const CE_BRIDGE_STATUS: ChainrunnerEndpoint =
  'Everdex.V1.get.swap.bridge.status';

export const CE_RESULT_SELL: ChainrunnerEndpoint =
  'Everdex.V1.get.swap.sell.result';
export const CE_RESULT_BUY: ChainrunnerEndpoint =
  'Everdex.V1.get.swap.buy.result';

// export const CE_LIST: ChainrunnerEndpoint =
//   'Everdex.V1.get.liquidity.plain.list';
// export const CE_POSITION: ChainrunnerEndpoint =
//   'Everdex.V1.get.liquidity.plain.position';
// export const CE_QUOTE: ChainrunnerEndpoint =
//   'Everdex.V1.get.liquidity.plain.quote';

// export const CE_CURVE_LIST: ChainrunnerEndpoint =
//   'Everdex.V1.get.liquidity.curve.list';
// export const CE_CURVE_POSITION: ChainrunnerEndpoint =
//   'Everdex.V1.get.liquidity.curve.position';
// export const CE_CURVE_QUOTE: ChainrunnerEndpoint =
//   'Everdex.V1.get.liquidity.curve.quote';

export const CE_RUN_APPROVE: ChainrunnerEndpoint = 'Everdex.V1.run.approve';
export const CE_RUN_SELL: ChainrunnerEndpoint = 'Everdex.V1.run.swap.sell';
// export const CE_RUN_BUY: ChainrunnerEndpoint = 'Everdex.V1.run.swap.buy';
export const CE_RUN_BRIDGE: ChainrunnerEndpoint = 'Everdex.V1.run.swap.bridge';
// export const CE_RUN_ADD: ChainrunnerEndpoint =
//   'Everdex.V1.run.liquidity.plain.add';
// export const CE_RUN_REMOVE: ChainrunnerEndpoint =
//   'Everdex.V1.run.liquidity.plain.remove';
// export const CE_RUN_CURVE_ADD: ChainrunnerEndpoint =
//   'Everdex.V1.run.liquidity.curve.add';
// export const CE_RUN_CURVE_REMOVE: ChainrunnerEndpoint =
//   'Everdex.V1.run.liquidity.curve.remove';

// export const CE_ERC20_ALLOWANCE: ChainrunnerEndpoint =
//   'Asset.Erc20.get.allowance';
// export const CE_ERC20_BALANCE: ChainrunnerEndpoint = 'Asset.Erc20.get.balance';
export const CE_ERC20_TOTAL_SUPPLY: ChainrunnerEndpoint =
  'Asset.Erc20.Call.totalSupply';
export const CE_ERC20_DECIMALS: ChainrunnerEndpoint =
  'Asset.Erc20.Call.decimals';
// export const CE_ERC20_SYMBOL: ChainrunnerEndpoint = 'Asset.Erc20.get.symbol';
// export const CE_ERC20_NAME: ChainrunnerEndpoint = 'Asset.Erc20.get.name';
