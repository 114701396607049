import {
  TRANSITION_DURATION,
  TRANSITION_DURATION_SHORT,
  TRANSITION_TIMING_FUNCTION,
} from 'utils/style/transition';

const transition = {
  duration: TRANSITION_DURATION,
  timingFunction: TRANSITION_TIMING_FUNCTION,
  shortSingle: `${TRANSITION_DURATION_SHORT} ${TRANSITION_TIMING_FUNCTION}`,
  single: `${TRANSITION_DURATION} ${TRANSITION_TIMING_FUNCTION}`,
  all: `all ${TRANSITION_DURATION} ${TRANSITION_TIMING_FUNCTION}`,
};

export default transition;
