import IS_TESTNET from 'lib/devEnv/envIsTestnet';
import {
  MAINNET_TOKEN_ADDRESS_BFC,
  MAINNET_TOKEN_ADDRESS_WBFC,
  MAINNET_TOKEN_ADDRESS_BIFI,
  MAINNET_TOKEN_ADDRESS_ETH,
  MAINNET_TOKEN_ADDRESS_BNB,
  MAINNET_TOKEN_ADDRESS_POL,
  MAINNET_TOKEN_ADDRESS_DAI,
  MAINNET_TOKEN_ADDRESS_USDC,
  MAINNET_TOKEN_ADDRESS_USDT,
  MAINNET_TOKEN_ADDRESS_BUSD,
  MAINNET_TOKEN_ADDRESS_P2D,
} from './mainnetTokens';
import {
  TESTNET_TOKEN_ADDRESS_BFC,
  TESTNET_TOKEN_ADDRESS_WBFC,
  TESTNET_TOKEN_ADDRESS_BIFI,
  TESTNET_TOKEN_ADDRESS_ETH,
  TESTNET_TOKEN_ADDRESS_BNB,
  TESTNET_TOKEN_ADDRESS_POL,
  TESTNET_TOKEN_ADDRESS_DAI,
  TESTNET_TOKEN_ADDRESS_USDC,
  TESTNET_TOKEN_ADDRESS_USDT,
  TESTNET_TOKEN_ADDRESS_BUSD,
  TESTNET_TOKEN_ADDRESS_P2D,
} from './testnetTokens';

export const TOKEN_ADDRESS_BFC = IS_TESTNET
  ? TESTNET_TOKEN_ADDRESS_BFC
  : MAINNET_TOKEN_ADDRESS_BFC;
export const TOKEN_ADDRESS_WBFC = IS_TESTNET
  ? TESTNET_TOKEN_ADDRESS_WBFC
  : MAINNET_TOKEN_ADDRESS_WBFC;
export const TOKEN_ADDRESS_BIFI = IS_TESTNET
  ? TESTNET_TOKEN_ADDRESS_BIFI
  : MAINNET_TOKEN_ADDRESS_BIFI;
export const TOKEN_ADDRESS_ETH = IS_TESTNET
  ? TESTNET_TOKEN_ADDRESS_ETH
  : MAINNET_TOKEN_ADDRESS_ETH;
export const TOKEN_ADDRESS_BNB = IS_TESTNET
  ? TESTNET_TOKEN_ADDRESS_BNB
  : MAINNET_TOKEN_ADDRESS_BNB;
export const TOKEN_ADDRESS_POL = IS_TESTNET
  ? TESTNET_TOKEN_ADDRESS_POL
  : MAINNET_TOKEN_ADDRESS_POL;
export const TOKEN_ADDRESS_USDC = IS_TESTNET
  ? TESTNET_TOKEN_ADDRESS_USDC
  : MAINNET_TOKEN_ADDRESS_USDC;
export const TOKEN_ADDRESS_USDT = IS_TESTNET
  ? TESTNET_TOKEN_ADDRESS_USDT
  : MAINNET_TOKEN_ADDRESS_USDT;
export const TOKEN_ADDRESS_DAI = IS_TESTNET
  ? TESTNET_TOKEN_ADDRESS_DAI
  : MAINNET_TOKEN_ADDRESS_DAI;
export const TOKEN_ADDRESS_BUSD = IS_TESTNET
  ? TESTNET_TOKEN_ADDRESS_BUSD
  : MAINNET_TOKEN_ADDRESS_BUSD;
export const TOKEN_ADDRESS_P2D = IS_TESTNET
  ? TESTNET_TOKEN_ADDRESS_P2D
  : MAINNET_TOKEN_ADDRESS_P2D;

const TOKEN_ADDRESSES = [
  TOKEN_ADDRESS_BFC,
  TOKEN_ADDRESS_WBFC,
  TOKEN_ADDRESS_BIFI,
  TOKEN_ADDRESS_ETH,
  TOKEN_ADDRESS_BNB,
  TOKEN_ADDRESS_POL,
  TOKEN_ADDRESS_DAI,
  TOKEN_ADDRESS_USDC,
  TOKEN_ADDRESS_USDT,
  TOKEN_ADDRESS_BUSD,
  TOKEN_ADDRESS_P2D,
];

export default TOKEN_ADDRESSES;
