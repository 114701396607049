import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import Image from 'next/image';
import React from 'react';
import ImageLoadingCircle from 'assets/loading-circle.png';
import { ICON_DEFAULT_COLOR, ICON_DEFAULT_SIZE } from 'configs/icons';
import IconProps from 'types/IconProps';

export type Props = IconProps & {};

const fx = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1080deg);
  }
`;

const Root = styled.div<{ size: number }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${fx};
  transition: ${({ theme }) => theme.transition.all};
`;

const LoadingCircle: React.FC<Props> = ({
  size = ICON_DEFAULT_SIZE,
  color = ICON_DEFAULT_COLOR,
}) => (
  <Root size={size}>
    <Image src={ImageLoadingCircle} alt="loading" />
  </Root>
);

export default LoadingCircle;
