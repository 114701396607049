import styled from '@emotion/styled';
import {
  Tooltip as MuiTooltip,
  TooltipProps,
  tooltipClasses,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import theme from 'styles/theme';

export type Props = Omit<TooltipProps, 'title'> & {
  header?: string;
  content?: React.ReactNode;
  isDisabled?: boolean;
  isClickTrigger?: boolean;
};

const Root = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '0px',
    maxWidth: 300,
    color: theme.color.white,
    fontSize: 12,
    backgroundColor: theme.color.black1A,
    borderRadius: 16,
    boxShadow: '0px 0px 16px 0px #00000029',
  },
}));
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
`;
const Header = styled.div`
  color: ${({ theme }) => theme.color.point};
  font-size: 14px;
  font-weight: 700;
`;
const Body = styled.div`
  line-height: 18px;
`;

const Tooltip: React.FC<Props> = (props) => {
  const {
    open,
    isDisabled,
    isClickTrigger,
    header,
    content,
    placement,
    disableFocusListener,
    disableHoverListener,
    disableTouchListener,
    children,
    onClose: handleClose,
  } = props;

  const [clickOpen, setClickOpen] = useState(false);

  const handleExtendedClose = useCallback(
    (event: Event | React.SyntheticEvent<Element, Event>) =>
      isClickTrigger ? setClickOpen(false) : handleClose && handleClose(event),
    [isClickTrigger, handleClose]
  );

  return (
    <Root
      placement={placement ?? 'top'}
      disableFocusListener={
        isDisabled || (isClickTrigger ? true : disableFocusListener)
      }
      disableHoverListener={
        isDisabled || (isClickTrigger ? true : disableHoverListener)
      }
      disableTouchListener={
        isDisabled || (isClickTrigger ? true : disableTouchListener)
      }
      disableInteractive={isDisabled}
      open={isClickTrigger ? clickOpen : open}
      onClose={handleExtendedClose}
      title={
        content ? (
          <Content>
            {header ? <Header>{header}</Header> : ''}
            <Body>{content}</Body>
          </Content>
        ) : undefined
      }>
      {children}
    </Root>
  );
};

export default Tooltip;
