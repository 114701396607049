import styled from '@emotion/styled';

export type Props = { readonly?: boolean; disabled?: boolean };

const Input = styled.input<Props>`
  flex-grow: 1;
  width: 100%;
  height: 28px;
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.black1A};
  border: 0;
  background-color: transparent;
  outline: none;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: none;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
  }
  -moz-appearance: textfield;
  &::placeholder {
    color: ${({ theme }) => theme.color.placeholder};
  }
`;

export const SingleLineInput = styled(Input)`
  height: 60px;
  padding: 0px 16px;
  font-size: 18px;
  border-radius: 24px;
  background-color: #f1efe8;
`;

export default Input;
