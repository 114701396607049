import {
  getStorageItem,
  setStorageItem,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import { useCallback, useEffect, useMemo, useState } from 'react';
import EVENT_NOTICES from 'configs/eventNotices';
import EventNotice from 'types/EventNotice';

const STORAGE_ID_EVENT_NOTICE = 'closedEventNotices';
const CLOSED_EVENT_NOTICES =
  getStorageItem<string[]>(STORAGE_ID_EVENT_NOTICE) ?? [];

const useEventNotice = () => {
  const [closedEventNotices, setClosedEventNotices] =
    useState<string[]>(CLOSED_EVENT_NOTICES);

  const activeEventNotices = useMemo(() => {
    const now = Date.now();

    return EVENT_NOTICES.filter(
      (eventNotice) =>
        (eventNotice.startAt === undefined || eventNotice.startAt <= now) &&
        (eventNotice.finishAt === undefined || eventNotice.finishAt >= now)
    );
  }, []);
  const unclosedEventNotices = useMemo(
    () =>
      activeEventNotices.filter(
        (eventNotice) => !closedEventNotices.includes(eventNotice.id)
      ),
    [activeEventNotices, closedEventNotices]
  );
  const eventNotices: EventNotice[] = useMemo(
    () => unclosedEventNotices,
    [unclosedEventNotices]
  );

  const closeEventNotice = useCallback(
    (id: string) => {
      const newData = [...closedEventNotices, id].filter(
        (data, index, array) => array.indexOf(data) === index
      );

      setStorageItem(STORAGE_ID_EVENT_NOTICE, newData);
      setClosedEventNotices(newData);
    },
    [closedEventNotices]
  );

  useEffect(() => {
    const closedEventNotices = getStorageItem<string[]>(
      STORAGE_ID_EVENT_NOTICE
    );

    setClosedEventNotices(closedEventNotices ?? []);
  }, []);

  return { eventNotices, closeEventNotice };
};

export default useEventNotice;
