import IS_TESTNET from 'lib/devEnv/envIsTestnet';
import Chain from 'types/Chain';
import {
  MAINNET_CHAIN_BIFROST,
  MAINNET_CHAIN_ETHEREUM,
  MAINNET_CHAIN_BSC,
  MAINNET_CHAIN_POLYGON,
  MAINNET_CHAIN_BASE,
  MAINNET_CHAIN_ARBITRUM,
} from './mainnetChains';
import {
  TESTNET_CHAIN_BIFROST,
  TESTNET_CHAIN_ETHEREUM,
  TESTNET_CHAIN_BASE_SEPOLIA,
  TESTNET_CHAIN_ARBITRUM_SEPOLIA,
  TESTNET_CHAIN_BSC,
  TESTNET_CHAIN_BASE,
  TESTNET_CHAIN_ARBITRUM,
} from './testnetChains';

// chain
export const CHAIN_BIFROST: Chain = IS_TESTNET
  ? TESTNET_CHAIN_BIFROST
  : MAINNET_CHAIN_BIFROST;
export const CHAIN_ETHEREUM: Chain = IS_TESTNET
  ? TESTNET_CHAIN_ETHEREUM
  : MAINNET_CHAIN_ETHEREUM;
export const CHAIN_BASE_SEPOLIA: Chain | undefined = IS_TESTNET
  ? TESTNET_CHAIN_BASE_SEPOLIA
  : undefined;
export const CHAIN_ARBITRUM_SEPOLIA: Chain | undefined = IS_TESTNET
  ? TESTNET_CHAIN_ARBITRUM_SEPOLIA
  : undefined;
export const CHAIN_BSC: Chain = IS_TESTNET
  ? TESTNET_CHAIN_BSC
  : MAINNET_CHAIN_BSC;
export const CHAIN_POLYGON: Chain | undefined = IS_TESTNET
  ? undefined
  : MAINNET_CHAIN_POLYGON;
export const CHAIN_BASE: Chain = IS_TESTNET
  ? TESTNET_CHAIN_BASE
  : MAINNET_CHAIN_BASE;
export const CHAIN_ARBITRUM: Chain = IS_TESTNET
  ? TESTNET_CHAIN_ARBITRUM
  : MAINNET_CHAIN_ARBITRUM;

const CHAINS: Chain[] = [
  CHAIN_BIFROST,
  CHAIN_ETHEREUM,
  CHAIN_BASE_SEPOLIA,
  CHAIN_ARBITRUM_SEPOLIA,
  CHAIN_BSC,
  CHAIN_POLYGON,
  CHAIN_BASE,
  CHAIN_ARBITRUM,
].filter((chain) => !!chain) as Chain[];

export const CHAIN_IDS_BY_API = CHAINS.map((chain) => chain.apiId).filter(
  (apiId) => !!apiId
);
export const CHAIN_IDS = CHAINS.map((chain) => chain.id).filter((id) => !!id);
export const RPC_MAP = Object.fromEntries(
  CHAINS.map((chain) => [chain.id, chain.rpcUrls[0]])
);

export default CHAINS;
