import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { setIsTestnet } from 'lib/devEnv/envIsTestnet';
import {
  NetworkQueryValue,
  NETWORK_QUERY_KEY,
  NETWORK_QUERY_VALUES,
  TestnetQueryValue,
  TESTNET_QUERY_KEY,
  TESTNET_QUERY_VALUES,
} from './useSyncQueryProperty';

const useSyncNetworkQueryProperty = () => {
  const router = useRouter();
  const { pathname, query, locale } = router;

  const sync = useCallback(async () => {
    const testnetQuery = query[TESTNET_QUERY_KEY];
    const testnetQueryValue =
      testnetQuery && typeof testnetQuery === 'string'
        ? testnetQuery
        : testnetQuery?.[0];
    const networkQuery = query[NETWORK_QUERY_KEY];
    const networkQueryValue =
      networkQuery && typeof networkQuery === 'string'
        ? networkQuery
        : networkQuery?.[0];

    let newValue: boolean | undefined;
    const newQuery = { ...query };

    if (
      testnetQueryValue &&
      TESTNET_QUERY_VALUES.indexOf(testnetQueryValue) >= 0
    ) {
      const value = testnetQueryValue as TestnetQueryValue;

      delete newQuery[TESTNET_QUERY_KEY];

      newValue = value === TESTNET_QUERY_VALUES[0];
    } else if (
      networkQueryValue &&
      NETWORK_QUERY_VALUES.indexOf(networkQueryValue) >= 0
    ) {
      const value = networkQueryValue as NetworkQueryValue;

      delete newQuery[NETWORK_QUERY_KEY];

      newValue = value === NETWORK_QUERY_VALUES[1];
    }

    if (typeof newValue === 'boolean') {
      await router.replace(
        { pathname, query },
        { pathname, query: newQuery },
        { locale }
      );

      setIsTestnet(newValue);
    }
  }, [locale, pathname, query, router]);

  return sync;
};

export default useSyncNetworkQueryProperty;
