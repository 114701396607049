import { SWAP_MERGER } from 'configs/address';
import {
  FRAG_GET_RESERVES_BY_CURVE,
  FRAG_GET_LP_TOKEN_BY_CURVE,
  FRAG_ADD_LIQUIDITY_ETH,
  FRAG_ADD_LIQUIDITY,
  FRAG_ADD_LIQUIDITY_STABLE_SWAP,
  FRAG_REMOVE_LIQUIDITY_ETH,
  FRAG_REMOVE_LIQUIDITY,
  FRAG_REMOVE_LIQUIDITY_STABLE_SWAP,
  FRAG_CALC_WITHDRAW_ONE_COIN,
  FRAG_CALC_TOKEN_AMOUNT,
  FRAG_SWAP_EXACT_ETH_FOR_TOKENS,
  FRAG_SWAP_ETH_FOR_EXACT_TOKENS,
  FRAG_SWAP_EXACT_TOKENS_FOR_ETH,
  FRAG_SWAP_TOKENS_FOR_EXACT_ETH,
  FRAG_SWAP_EXACT_TOKENS_FOR_TOKENS,
  FRAG_SWAP_TOKENS_FOR_EXACT_TOKENS,
} from 'configs/fragment';
import {
  ContractFunctionCaller,
  ContractFunctionSender,
} from 'types/ContractFunction';
import { call, send } from './dex';

// call
export const calc_withdraw_one_coin: ContractFunctionCaller = async (
  option,
  args
) =>
  call(
    { ...option, fragment: FRAG_CALC_WITHDRAW_ONE_COIN, to: SWAP_MERGER },
    args
  );
export const calc_token_amount: ContractFunctionCaller = async (option, args) =>
  call({ ...option, fragment: FRAG_CALC_TOKEN_AMOUNT }, args);
export const getReservesByCurve: ContractFunctionCaller = async (
  option,
  args
) =>
  call(
    { ...option, fragment: FRAG_GET_RESERVES_BY_CURVE, to: SWAP_MERGER },
    args
  );
export const getLPTokenByCurve: ContractFunctionCaller = async (option, args) =>
  call(
    { ...option, fragment: FRAG_GET_LP_TOKEN_BY_CURVE, to: SWAP_MERGER },
    args
  );

// send
// swap
export const swapExactETHForTokens: ContractFunctionSender = async (
  option,
  args
) =>
  send(
    { ...option, fragment: FRAG_SWAP_EXACT_ETH_FOR_TOKENS, to: SWAP_MERGER },
    args
  );
export const swapETHForExactTokens: ContractFunctionSender = async (
  option,
  args
) =>
  send(
    { ...option, fragment: FRAG_SWAP_ETH_FOR_EXACT_TOKENS, to: SWAP_MERGER },
    args
  );
export const swapExactTokensForETH: ContractFunctionSender = async (
  option,
  args
) =>
  send(
    { ...option, fragment: FRAG_SWAP_EXACT_TOKENS_FOR_ETH, to: SWAP_MERGER },
    args
  );
export const swapTokensForExactETH: ContractFunctionSender = async (
  option,
  args
) =>
  send(
    { ...option, fragment: FRAG_SWAP_TOKENS_FOR_EXACT_ETH, to: SWAP_MERGER },
    args
  );
export const swapExactTokensForTokens: ContractFunctionSender = async (
  option,
  args
) =>
  send(
    { ...option, fragment: FRAG_SWAP_EXACT_TOKENS_FOR_TOKENS, to: SWAP_MERGER },
    args
  );
export const swapTokensForExactTokens: ContractFunctionSender = async (
  option,
  args
) =>
  send(
    { ...option, fragment: FRAG_SWAP_TOKENS_FOR_EXACT_TOKENS, to: SWAP_MERGER },
    args
  );
// add
export const addLiquidityETH: ContractFunctionSender = async (option, args) =>
  send({ ...option, fragment: FRAG_ADD_LIQUIDITY_ETH, to: SWAP_MERGER }, args);
export const addLiquidity: ContractFunctionSender = async (option, args) =>
  send({ ...option, fragment: FRAG_ADD_LIQUIDITY, to: SWAP_MERGER }, args);
export const addLiquidityStableSwap: ContractFunctionSender = async (
  option,
  args
) =>
  send(
    { ...option, fragment: FRAG_ADD_LIQUIDITY_STABLE_SWAP, to: SWAP_MERGER },
    args
  );
// remove
export const removeLiquidityETH: ContractFunctionSender = async (
  option,
  args
) =>
  send(
    { ...option, fragment: FRAG_REMOVE_LIQUIDITY_ETH, to: SWAP_MERGER },
    args
  );
export const removeLiquidity: ContractFunctionSender = async (option, args) =>
  send({ ...option, fragment: FRAG_REMOVE_LIQUIDITY, to: SWAP_MERGER }, args);
export const removeLiquidityStableSwap: ContractFunctionSender = async (
  option,
  args
) =>
  send(
    { ...option, fragment: FRAG_REMOVE_LIQUIDITY_STABLE_SWAP, to: SWAP_MERGER },
    args
  );
