import styled from '@emotion/styled';
import { InnerCard } from 'components/styled/card';
import Btn from '../btn';

const InputCard = styled(InnerCard)`
  display: flex;
  gap: 4px;
  font-size: 14px;
  font-weight: 500;
`;

export const InputCardOutLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  & > span {
    margin-left: 8px;
    color: ${({ theme }) => theme.color.label};
  }
`;
export const InputCardInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;
export const InputCardAmountContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
`;
export const InputCardTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
`;
export const InputCardCurrency = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.label};
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const InputCardRatio = styled(InnerCard)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 8px;
`;
export const InputCardRatioBtn = styled(Btn)`
  height: 44px;
  padding: 0px;
  border-radius: 16px;
  gap: 8px;
  background-color: ${({ theme }) => theme.color.white};
`;
export const InputCardOutKeyValue = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 17px;
  font-size: 14px;
  font-weight: 500;
  & > div {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  & > div:first-of-type() {
  }
  & > div:last-of-type() {
    justify-content: flex-end;
    text-align: right;
  }
`;

export default InputCard;
