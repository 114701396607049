import TRANSITION from './transition';

const TRANSITION_SET_SUBBUTTON_OPACITY = `
  cursor: pointer;
  opacity: 0.6;
  ${TRANSITION}
  will-change: opacity;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.8;
  }
`;

export default TRANSITION_SET_SUBBUTTON_OPACITY;
