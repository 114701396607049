import TRANSITION from './transition';

const TRANSITION_SET_TEXT_FIELD_OPACITY = `
  ${TRANSITION}
  will-change: opacity;
  &:hover,
  &:focus {
    opacity: 0.8;
  }
`;

export default TRANSITION_SET_TEXT_FIELD_OPACITY;
