import DEFAULT_BLOCK_TERM from 'configs/defaultValue/defaultBlock';
import {
  MAINNET_TOKEN_BFC,
  MAINNET_TOKEN_BNB,
  MAINNET_TOKEN_ETH,
  MAINNET_TOKEN_POL,
} from 'configs/tokens/mainnetTokens';
import Chain from 'types/Chain';
import {
  MAINNET_CHAIN_API_ID_BIFROST,
  MAINNET_CHAIN_API_ID_ETHEREUM,
  MAINNET_CHAIN_API_ID_BSC,
  MAINNET_CHAIN_API_ID_POLYGON,
  MAINNET_CHAIN_API_ID_BASE,
  MAINNET_CHAIN_API_ID_ARBITRUM,
} from './mainnet/chainApiId';
import {
  MAINNET_CHAIN_EXPLORER_URL_BIFROST,
  MAINNET_CHAIN_EXPLORER_URL_ETHEREUM,
  MAINNET_CHAIN_EXPLORER_URL_BSC,
  MAINNET_CHAIN_EXPLORER_URL_POLYGON,
  MAINNET_CHAIN_EXPLORER_URL_BASE,
  MAINNET_CHAIN_EXPLORER_URL_ARBITRUM,
} from './mainnet/chainExplorerUrl';
import {
  MAINNET_CHAIN_ID_BIFROST,
  MAINNET_CHAIN_ID_ETHEREUM,
  MAINNET_CHAIN_ID_BSC,
  MAINNET_CHAIN_ID_POLYGON,
  MAINNET_CHAIN_ID_BASE,
  MAINNET_CHAIN_ID_ARBITRUM,
} from './mainnet/chainId';
import {
  MAINNET_CHAIN_NAME_BIFROST,
  MAINNET_CHAIN_NAME_ETHEREUM,
  MAINNET_CHAIN_NAME_BSC,
  MAINNET_CHAIN_NAME_POLYGON,
  MAINNET_CHAIN_NAME_BASE,
  MAINNET_CHAIN_NAME_ARBITRUM,
} from './mainnet/chainName';
import {
  MAINNET_CHAIN_RPC_URLS_BIFROST,
  MAINNET_CHAIN_RPC_URLS_ETHEREUM,
  MAINNET_CHAIN_RPC_URLS_BSC,
  MAINNET_CHAIN_RPC_URLS_POLYGON,
  MAINNET_CHAIN_RPC_URLS_BASE,
  MAINNET_CHAIN_RPC_URLS_ARBITRUM,
} from './mainnet/chainRpcUrls';

// chain
export const MAINNET_CHAIN_BIFROST: Chain = {
  apiId: MAINNET_CHAIN_API_ID_BIFROST,
  id: MAINNET_CHAIN_ID_BIFROST,
  rpcUrls: MAINNET_CHAIN_RPC_URLS_BIFROST,
  explorerUrl: MAINNET_CHAIN_EXPLORER_URL_BIFROST,
  name: MAINNET_CHAIN_NAME_BIFROST,
  blockTerm: DEFAULT_BLOCK_TERM,
  gasFee: 1,
  coin: MAINNET_TOKEN_BFC,
  order: 0,
};
export const MAINNET_CHAIN_ETHEREUM: Chain = {
  apiId: MAINNET_CHAIN_API_ID_ETHEREUM,
  id: MAINNET_CHAIN_ID_ETHEREUM,
  rpcUrls: MAINNET_CHAIN_RPC_URLS_ETHEREUM,
  explorerUrl: MAINNET_CHAIN_EXPLORER_URL_ETHEREUM,
  name: MAINNET_CHAIN_NAME_ETHEREUM,
  blockTerm: DEFAULT_BLOCK_TERM,
  gasFee: 0.001,
  coin: MAINNET_TOKEN_ETH,
  order: 1,
};
export const MAINNET_CHAIN_BSC: Chain = {
  apiId: MAINNET_CHAIN_API_ID_BSC,
  id: MAINNET_CHAIN_ID_BSC,
  rpcUrls: MAINNET_CHAIN_RPC_URLS_BSC,
  explorerUrl: MAINNET_CHAIN_EXPLORER_URL_BSC,
  name: MAINNET_CHAIN_NAME_BSC,
  blockTerm: DEFAULT_BLOCK_TERM,
  gasFee: 0.01,
  coin: MAINNET_TOKEN_BNB,
  order: 4,
};
export const MAINNET_CHAIN_POLYGON: Chain = {
  apiId: MAINNET_CHAIN_API_ID_POLYGON,
  id: MAINNET_CHAIN_ID_POLYGON,
  rpcUrls: MAINNET_CHAIN_RPC_URLS_POLYGON,
  explorerUrl: MAINNET_CHAIN_EXPLORER_URL_POLYGON,
  name: MAINNET_CHAIN_NAME_POLYGON,
  blockTerm: DEFAULT_BLOCK_TERM,
  gasFee: 0.1,
  coin: MAINNET_TOKEN_POL,
  order: 5,
};
export const MAINNET_CHAIN_BASE: Chain = {
  apiId: MAINNET_CHAIN_API_ID_BASE,
  id: MAINNET_CHAIN_ID_BASE,
  rpcUrls: MAINNET_CHAIN_RPC_URLS_BASE,
  explorerUrl: MAINNET_CHAIN_EXPLORER_URL_BASE,
  name: MAINNET_CHAIN_NAME_BASE,
  blockTerm: DEFAULT_BLOCK_TERM,
  gasFee: 0.001,
  coin: MAINNET_TOKEN_ETH,
  order: 3,
};
export const MAINNET_CHAIN_ARBITRUM: Chain = {
  apiId: MAINNET_CHAIN_API_ID_ARBITRUM,
  id: MAINNET_CHAIN_ID_ARBITRUM,
  rpcUrls: MAINNET_CHAIN_RPC_URLS_ARBITRUM,
  explorerUrl: MAINNET_CHAIN_EXPLORER_URL_ARBITRUM,
  name: MAINNET_CHAIN_NAME_ARBITRUM,
  blockTerm: DEFAULT_BLOCK_TERM,
  gasFee: 0.001,
  coin: MAINNET_TOKEN_ETH,
  order: 2,
};

// chains
const MAINNET_CHAINS = [
  MAINNET_CHAIN_BIFROST,
  MAINNET_CHAIN_ETHEREUM,
  MAINNET_CHAIN_BSC,
  MAINNET_CHAIN_POLYGON,
  MAINNET_CHAIN_BASE,
  MAINNET_CHAIN_ARBITRUM,
];

export default MAINNET_CHAINS;
