import { ProviderType } from '@bifrost-platform/bifront-sdk-react-wallet';
import { ImageProps } from 'next/image';
import ImageBiport from 'assets/wallet/biport.svg';
import ImageCoinbase from 'assets/wallet/coinbase.svg';
import ImageMetamask from 'assets/wallet/metamask.svg';
import ImagePockie from 'assets/wallet/pockie.svg';
import ImageWalletconnect from 'assets/wallet/walletconnect.svg';

const PROVIDERS: {
  name: string;
  type: ProviderType;
  src: ImageProps['src'];
}[] = [
  { name: 'Pockie', type: 'pockie', src: ImagePockie },
  { name: 'Biport', type: 'biport', src: ImageBiport },
  { name: 'MetaMask', type: 'metamask', src: ImageMetamask },
  { name: 'Coinbase', type: 'coinbase', src: ImageCoinbase },
  { name: 'WalletConnect', type: 'walletconnect', src: ImageWalletconnect },
];

export default PROVIDERS;
