import {
  ICON_DEFAULT_COLOR,
  ICON_DEFAULT_INNER_COLOR,
  ICON_DEFAULT_SIZE,
} from 'configs/icons';
import IconProps, { IconBackgroundedProps } from 'types/IconProps';

export const IconEqual: React.FC<IconProps> = ({
  size = ICON_DEFAULT_SIZE,
  color = ICON_DEFAULT_COLOR,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M6.1498 15.7C5.91369 15.7 5.713 15.6176 5.54773 15.4529C5.38245 15.2882 5.2998 15.0882 5.2998 14.8529C5.2998 14.6176 5.38245 14.4167 5.54773 14.25C5.713 14.0833 5.91369 14 6.1498 14H17.8498C18.0859 14 18.2866 14.0823 18.4519 14.247C18.6172 14.4118 18.6998 14.6118 18.6998 14.847C18.6998 15.0823 18.6172 15.2833 18.4519 15.45C18.2866 15.6167 18.0859 15.7 17.8498 15.7H6.1498ZM6.1498 9.99999C5.91369 9.99999 5.713 9.91764 5.54773 9.75294C5.38245 9.58822 5.2998 9.38822 5.2998 9.15294C5.2998 8.91764 5.38245 8.71665 5.54773 8.54999C5.713 8.38332 5.91369 8.29999 6.1498 8.29999H17.8498C18.0859 8.29999 18.2866 8.38234 18.4519 8.54704C18.6172 8.71175 18.6998 8.91175 18.6998 9.14704C18.6998 9.38234 18.6172 9.58332 18.4519 9.74999C18.2866 9.91665 18.0859 9.99999 17.8498 9.99999H6.1498Z"
        fill={color}
      />
    </g>
  </svg>
);

export const IconPlusBox: React.FC<IconBackgroundedProps> = ({
  size = ICON_DEFAULT_SIZE,
  color = ICON_DEFAULT_INNER_COLOR,
  backgroundColor = ICON_DEFAULT_COLOR,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.5"
      y="0.5"
      width="47"
      height="47"
      rx="15.5"
      fill={backgroundColor}
      stroke={backgroundColor}
    />
    <g>
      <path
        d="M24 31C23.7167 31 23.4792 30.9042 23.2875 30.7125C23.0958 30.5208 23 30.2833 23 30V25H18C17.7167 25 17.4792 24.9042 17.2875 24.7125C17.0958 24.5208 17 24.2833 17 24C17 23.7167 17.0958 23.4792 17.2875 23.2875C17.4792 23.0958 17.7167 23 18 23H23V18C23 17.7167 23.0958 17.4792 23.2875 17.2875C23.4792 17.0958 23.7167 17 24 17C24.2833 17 24.5208 17.0958 24.7125 17.2875C24.9042 17.4792 25 17.7167 25 18V23H30C30.2833 23 30.5208 23.0958 30.7125 23.2875C30.9042 23.4792 31 23.7167 31 24C31 24.2833 30.9042 24.5208 30.7125 24.7125C30.5208 24.9042 30.2833 25 30 25H25V30C25 30.2833 24.9042 30.5208 24.7125 30.7125C24.5208 30.9042 24.2833 31 24 31Z"
        fill={color}
      />
    </g>
  </svg>
);

export const IconCheck: React.FC<IconProps> = ({
  size = ICON_DEFAULT_SIZE,
  color = ICON_DEFAULT_COLOR,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.36661 7.71758C4.27772 7.71758 4.19438 7.70369 4.11661 7.67591C4.03883 7.64813 3.96661 7.60091 3.89994 7.53425L1.03327 4.66758C0.91105 4.54536 0.852717 4.38702 0.858272 4.19258C0.863828 3.99813 0.927717 3.8398 1.04994 3.71758C1.17216 3.59536 1.32772 3.53425 1.51661 3.53425C1.70549 3.53425 1.86105 3.59536 1.98327 3.71758L4.36661 6.10091L10.0166 0.450911C10.1388 0.328689 10.2972 0.267578 10.4916 0.267578C10.6861 0.267578 10.8444 0.328689 10.9666 0.450911C11.0888 0.573134 11.1499 0.731467 11.1499 0.925911C11.1499 1.12036 11.0888 1.27869 10.9666 1.40091L4.83327 7.53425C4.76661 7.60091 4.69438 7.64813 4.61661 7.67591C4.53883 7.70369 4.4555 7.71758 4.36661 7.71758Z"
      fill={color}
    />
  </svg>
);

export const IconCheckCircle: React.FC<IconProps> = ({
  size = ICON_DEFAULT_SIZE,
  color = ICON_DEFAULT_COLOR,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.1 13.8L8.95 11.65C8.76667 11.4667 8.53333 11.375 8.25 11.375C7.96667 11.375 7.73333 11.4667 7.55 11.65C7.36667 11.8333 7.275 12.0667 7.275 12.35C7.275 12.6333 7.36667 12.8667 7.55 13.05L10.4 15.9C10.6 16.1 10.8333 16.2 11.1 16.2C11.3667 16.2 11.6 16.1 11.8 15.9L17.45 10.25C17.6333 10.0667 17.725 9.83333 17.725 9.55C17.725 9.26667 17.6333 9.03333 17.45 8.85C17.2667 8.66667 17.0333 8.575 16.75 8.575C16.4667 8.575 16.2333 8.66667 16.05 8.85L11.1 13.8ZM12.5 22C11.1167 22 9.81667 21.7375 8.6 21.2125C7.38333 20.6875 6.325 19.975 5.425 19.075C4.525 18.175 3.8125 17.1167 3.2875 15.9C2.7625 14.6833 2.5 13.3833 2.5 12C2.5 10.6167 2.7625 9.31667 3.2875 8.1C3.8125 6.88333 4.525 5.825 5.425 4.925C6.325 4.025 7.38333 3.3125 8.6 2.7875C9.81667 2.2625 11.1167 2 12.5 2C13.8833 2 15.1833 2.2625 16.4 2.7875C17.6167 3.3125 18.675 4.025 19.575 4.925C20.475 5.825 21.1875 6.88333 21.7125 8.1C22.2375 9.31667 22.5 10.6167 22.5 12C22.5 13.3833 22.2375 14.6833 21.7125 15.9C21.1875 17.1167 20.475 18.175 19.575 19.075C18.675 19.975 17.6167 20.6875 16.4 21.2125C15.1833 21.7375 13.8833 22 12.5 22Z"
      fill={color}
    />
  </svg>
);

export const IconWarn: React.FC<IconProps> = ({
  size = ICON_DEFAULT_SIZE,
  color = ICON_DEFAULT_COLOR,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.96309 21C2.78446 21 2.62207 20.9542 2.47592 20.8625C2.32977 20.7708 2.2161 20.65 2.13491 20.5C2.05371 20.35 2.00906 20.1875 2.00094 20.0125C1.99282 19.8375 2.03747 19.6667 2.13491 19.5L11.1475 3.5C11.2449 3.33333 11.3707 3.20833 11.525 3.125C11.6793 3.04167 11.8376 3 12 3C12.1624 3 12.3207 3.04167 12.475 3.125C12.6293 3.20833 12.7551 3.33333 12.8525 3.5L21.8651 19.5C21.9625 19.6667 22.0072 19.8375 21.9991 20.0125C21.9909 20.1875 21.9463 20.35 21.8651 20.5C21.7839 20.65 21.6702 20.7708 21.5241 20.8625C21.3779 20.9542 21.2155 21 21.0369 21H2.96309ZM12 18C12.2761 18 12.5075 17.9042 12.6942 17.7125C12.881 17.5208 12.9743 17.2833 12.9743 17C12.9743 16.7167 12.881 16.4792 12.6942 16.2875C12.5075 16.0958 12.2761 16 12 16C11.7239 16 11.4925 16.0958 11.3058 16.2875C11.119 16.4792 11.0257 16.7167 11.0257 17C11.0257 17.2833 11.119 17.5208 11.3058 17.7125C11.4925 17.9042 11.7239 18 12 18ZM12 15C12.2761 15 12.5075 14.9042 12.6942 14.7125C12.881 14.5208 12.9743 14.2833 12.9743 14V11C12.9743 10.7167 12.881 10.4792 12.6942 10.2875C12.5075 10.0958 12.2761 10 12 10C11.7239 10 11.4925 10.0958 11.3058 10.2875C11.119 10.4792 11.0257 10.7167 11.0257 11V14C11.0257 14.2833 11.119 14.5208 11.3058 14.7125C11.4925 14.9042 11.7239 15 12 15Z"
      fill={color}
    />
  </svg>
);

export const IconTimer: React.FC<IconProps> = ({
  size = ICON_DEFAULT_SIZE,
  color = ICON_DEFAULT_COLOR,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 3C9.71667 3 9.47917 2.90417 9.2875 2.7125C9.09583 2.52083 9 2.28333 9 2C9 1.71667 9.09583 1.47917 9.2875 1.2875C9.47917 1.09583 9.71667 1 10 1H14C14.2833 1 14.5208 1.09583 14.7125 1.2875C14.9042 1.47917 15 1.71667 15 2C15 2.28333 14.9042 2.52083 14.7125 2.7125C14.5208 2.90417 14.2833 3 14 3H10ZM12 14C12.2833 14 12.5208 13.9042 12.7125 13.7125C12.9042 13.5208 13 13.2833 13 13V9C13 8.71667 12.9042 8.47917 12.7125 8.2875C12.5208 8.09583 12.2833 8 12 8C11.7167 8 11.4792 8.09583 11.2875 8.2875C11.0958 8.47917 11 8.71667 11 9V13C11 13.2833 11.0958 13.5208 11.2875 13.7125C11.4792 13.9042 11.7167 14 12 14ZM12 22C10.7667 22 9.60417 21.7625 8.5125 21.2875C7.42083 20.8125 6.46667 20.1667 5.65 19.35C4.83333 18.5333 4.1875 17.5792 3.7125 16.4875C3.2375 15.3958 3 14.2333 3 13C3 11.7667 3.2375 10.6042 3.7125 9.5125C4.1875 8.42083 4.83333 7.46667 5.65 6.65C6.46667 5.83333 7.42083 5.1875 8.5125 4.7125C9.60417 4.2375 10.7667 4 12 4C13.0333 4 14.025 4.16667 14.975 4.5C15.925 4.83333 16.8167 5.31667 17.65 5.95L18.35 5.25C18.5333 5.06667 18.7667 4.975 19.05 4.975C19.3333 4.975 19.5667 5.06667 19.75 5.25C19.9333 5.43333 20.025 5.66667 20.025 5.95C20.025 6.23333 19.9333 6.46667 19.75 6.65L19.05 7.35C19.6833 8.18333 20.1667 9.075 20.5 10.025C20.8333 10.975 21 11.9667 21 13C21 14.2333 20.7625 15.3958 20.2875 16.4875C19.8125 17.5792 19.1667 18.5333 18.35 19.35C17.5333 20.1667 16.5792 20.8125 15.4875 21.2875C14.3958 21.7625 13.2333 22 12 22ZM12 20C13.9333 20 15.5833 19.3167 16.95 17.95C18.3167 16.5833 19 14.9333 19 13C19 11.0667 18.3167 9.41667 16.95 8.05C15.5833 6.68333 13.9333 6 12 6C10.0667 6 8.41667 6.68333 7.05 8.05C5.68333 9.41667 5 11.0667 5 13C5 14.9333 5.68333 16.5833 7.05 17.95C8.41667 19.3167 10.0667 20 12 20Z"
      fill={color}
    />
  </svg>
);
