import {
  useWallet,
  isNormalPositive,
  isValidAddress,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { API_PATH_BIHOLER_BALANCES } from 'configs/apiPath';
import useWatchingAddressMap from 'hooks/useWatchingAddressMap';
import { biholder } from 'lib/axiosInstance';
import { logError } from 'lib/log';
import BalanceMap from 'types/BalanceMap';
import { MinimalToken } from 'types/Token';
import BiHolderBalances from 'types/api/biholer/BiHolderBalances';
import { parseChainrunnerChainId } from 'utils/chainrunner';

export type Props = {
  balanceMap: BalanceMap;
  syncBalance: () => void;
};

export const INTERVAL_BALANCES = 30000;

export const getBalances = async (address: string, tokens: MinimalToken[]) => {
  let result: string[] = [];

  if (isValidAddress(address) && tokens.length) {
    try {
      const { data: balances } = await biholder.post<BiHolderBalances>(
        `${API_PATH_BIHOLER_BALANCES}?account_address=${address}`,
        {
          standard_chain_ids: tokens.map(({ chainId }) => chainId),
          token_addresses: tokens.map(({ address }) => address),
        }
      );

      result = balances;
    } catch (error) {}
  }

  return result;
};

export const Context = createContext<Props>({
  balanceMap: {},
  syncBalance: () => {},
});

export const BalancesProvider = ({ children }: PropsWithChildren) => {
  const { account } = useWallet();

  const { watchingAddressMap } = useWatchingAddressMap();

  const [balanceMap, setBalanceMap] = useState<BalanceMap>({});
  const [syncSwitch, setSyncSwitch] = useState(false);

  const taskId = useRef<NodeJS.Timeout>();

  const updateBalances = useCallback(async () => {
    if (!account) {
      setBalanceMap({});
      return;
    }

    try {
      const keys = Object.keys(watchingAddressMap);
      const tokens = keys
        .reduce((prev, chainId) => {
          const addresses = watchingAddressMap[chainId];
          const tokensByChain = addresses
            ? addresses.map(
                (address) =>
                  ({
                    chainId: parseChainrunnerChainId(chainId),
                    address,
                  }) as MinimalToken
              )
            : [];

          return [...prev, ...tokensByChain];
        }, [] as MinimalToken[])
        .filter(
          ({ chainId, address }) =>
            isNormalPositive(chainId) && isValidAddress(address)
        );
      const balances = await getBalances(account, tokens);

      setBalanceMap((value) => {
        const newBalanceMap = Object.assign({}, value);

        for (const balanceIndex in balances) {
          try {
            const balance = balances[balanceIndex];
            const token = tokens[balanceIndex];
            const { chainId, address } = token;

            if (!newBalanceMap[chainId]) {
              newBalanceMap[chainId] = {};
            }

            newBalanceMap[chainId][address.toLowerCase()] = balance;
          } catch (error) {}
        }
        return newBalanceMap;
      });
    } catch (error) {
      logError(error);
    }
  }, [account, watchingAddressMap]);
  const syncBalance = useCallback(() => setSyncSwitch((value) => !value), []);

  useEffect(() => {
    if (Object.keys(watchingAddressMap).length) {
      updateBalances();

      if (!taskId.current) {
        taskId.current = setInterval(updateBalances, INTERVAL_BALANCES);
      }
    }

    return () => {
      if (taskId.current) {
        clearInterval(taskId.current);
      }
    };
  }, [watchingAddressMap, updateBalances, syncSwitch]);

  return (
    <Context.Provider
      value={{
        balanceMap,
        syncBalance,
      }}>
      {children}
    </Context.Provider>
  );
};

const useBalances = (): Props => useContext(Context);

export default useBalances;
