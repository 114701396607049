import {
  waitForTransaction,
  Transaction,
  getStorageItem,
  setStorageItem,
  Wallet,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import { TFunction } from 'next-i18next';
import IS_TESTNET from 'lib/devEnv/envIsTestnet';
import TransactionHistory, {
  StorageTransactionHistory,
} from 'types/TransactionHistory';

const STORAGE_ID_TRANSACTION_HISTORIES = `transactionHistories${
  IS_TESTNET ? '-testnet' : ''
}`;

export const parseTransactionTitle = (
  { type, tokens }: TransactionHistory,
  t?: TFunction
) => {
  let result = '';

  switch (type) {
    case 'approve':
      result = t?.('common:approve') || 'approve';
      break;
    case 'swap':
      result = t?.('common:swap') || 'swap';
      break;
    case 'bridge':
      result = t?.('common:bridge') || 'bridge';
      break;
  }

  return result;
};

export const makeTransactionWait = (hash: string, wallet: Wallet) => () =>
  waitForTransaction(wallet.getProvider()!, hash);

export const makeTransaction = (hash: string, wallet: Wallet): Transaction => ({
  hash,
  wait: makeTransactionWait(hash, wallet),
});

export const makeTransactionHistory = (
  data: TransactionHistory,
  wallet?: Wallet
): TransactionHistory => {
  const transaction = wallet ? makeTransaction(data.hash, wallet) : undefined;
  const tokens = data.tokens ?? [];
  const timestamp = data.timestamp ?? Date.now();

  return { ...data, ...transaction, tokens, timestamp };
};

export const loadTransactionHistories = (address?: string) =>
  getStorageItem<StorageTransactionHistory[]>(
    STORAGE_ID_TRANSACTION_HISTORIES,
    address
  ) ?? [];

export const saveTransactionHistories = (
  transactionHistories: StorageTransactionHistory[],
  address?: string
) =>
  setStorageItem(
    STORAGE_ID_TRANSACTION_HISTORIES,
    transactionHistories,
    address
  );
