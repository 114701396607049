import { isNormalPositive } from '@bifrost-platform/bifront-sdk-react-wallet';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import LoadingCircle from 'components/LoadingCircle';
import Root, { DialogContainer, DialogBody } from 'components/dialog';

export type Props = {};

const Container = styled(DialogContainer)<{ width?: number }>`
  gap: 24px;
  ${({ width }) => (isNormalPositive(width, true) ? `width: ${width}px;` : '')}
  min-width: 360px;
`;
const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 4px;
  text-align: center;

  & > div:last-of-type {
    margin-top: 12px;
  }
`;

const MaintainingNow: React.FC<Props> = ({}) => {
  const { t } = useTranslation('dialog');

  return (
    <Root open>
      <Container width={400}>
        <DialogBody>
          <ContentContainer>
            {(t('maintain.title') as string)
              .split('\n')
              .map((line, lineIndex) => (
                <div key={lineIndex}>{line}</div>
              ))}
            <LoadingCircle size={36} />
          </ContentContainer>
        </DialogBody>
      </Container>
    </Root>
  );
};

export default MaintainingNow;
