import { PropsWithChildren, useCallback } from 'react';
import { HeaderBtn, HeaderDropdownContainer } from '../HeaderDropdownBtn';

export type Props = PropsWithChildren & {
  popup?: React.ReactNode;
  isOpen?: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

const HeaderDropdown: React.FC<Props> = ({
  children,
  popup,
  isOpen,
  setIsOpen,
}) => {
  const handleClick: React.MouseEventHandler<HTMLAnchorElement> =
    useCallback(() => {
      if (!isOpen) {
        setIsOpen?.((prev) => !prev);
      }
    }, [setIsOpen, isOpen]);

  return (
    <HeaderDropdownContainer onClick={handleClick}>
      <HeaderBtn>{children}</HeaderBtn>
      {popup}
    </HeaderDropdownContainer>
  );
};

export default HeaderDropdown;
