import {
  compareLowerStr,
  isValidAddress,
  ZERO_ADDRESS,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import { useEffect, useMemo } from 'react';
import { CHAIN_ID_BIFROST } from 'configs/chains/chainIds';
import TOKENS from 'configs/tokens';
import Token, { MinimalToken } from 'types/Token';
import useAssetInfo from './api/biholder/useAssetInfo';
import useTokensByChains from './api/chainrunner/useTokensByChains';
import useWatchingAddressMap from './useWatchingAddressMap';

const useTokens = (tokens: (MinimalToken | Token | string)[] = []) => {
  const { watchingAddressMap, addWatchingAddress } = useWatchingAddressMap();

  const { assetMap } = useAssetInfo();

  const { data: tokensByChains } = useTokensByChains();

  const tokensFromChainrunner = useMemo(
    () =>
      tokensByChains?.find(
        (tokensByChain) => tokensByChain.chain.id === CHAIN_ID_BIFROST
      )?.tokens ?? [],
    [tokensByChains]
  );
  const tokensFromBiholder = useMemo(
    () =>
      Object.values(assetMap[CHAIN_ID_BIFROST] ?? {}).map(
        ({ address, name, symbol, decimal: decimals }) => ({
          chainId: CHAIN_ID_BIFROST,
          address,
          name,
          symbol,
          decimals,
          color: '',
          isCoin: compareLowerStr(address, ZERO_ADDRESS),
        })
      ),
    [assetMap]
  );
  const compareTokens = useMemo(
    () => [...TOKENS, ...tokensFromChainrunner, ...tokensFromBiholder],
    [tokensFromChainrunner, tokensFromBiholder]
  );
  const result = useMemo(
    () =>
      tokens
        .map((token) => {
          const symbol =
            typeof token === 'string'
              ? token
              : (token as Token | undefined)?.symbol;
          const address =
            (typeof token === 'object'
              ? (token as Token | undefined)?.address
              : undefined) ?? '';
          const result = token
            ? compareTokens.find(
                (compareToken) =>
                  compareLowerStr(compareToken.symbol, symbol) ||
                  (compareToken.address && address
                    ? compareLowerStr(compareToken.address, address)
                    : false)
              ) ?? token
            : undefined;

          return result;
        })
        .filter((token) => !!token) as Token[],
    [compareTokens, tokens]
  );

  useEffect(() => {
    for (const token of tokens) {
      const address =
        typeof token === 'object'
          ? (token as Token)?.address?.toLowerCase()
          : undefined;
      const chainId =
        typeof token === 'object' ? (token as Token)?.chainId : undefined;

      if (
        address &&
        isValidAddress(address) &&
        !watchingAddressMap[address]?.includes(address)
      ) {
        addWatchingAddress(address, chainId);
      }
    }
  }, [addWatchingAddress, tokens, watchingAddressMap]);

  return result;
};

export default useTokens;
