import {
  formatDecimal,
  isNormalPositive,
  isUserReject,
  UnknownNumber,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import BN from 'bignumber.js';
import { TFunction, Trans } from 'next-i18next';
import React from 'react';
import UnderlineLinkButton from 'components/button/UnderlineLinkButton';
import EXCEPT_ERROR_CODES from 'configs/chainrunner/exceptErrorCodes';
import REQUIRE_PARSE_ERROR_CODES from 'configs/chainrunner/requireParseErrorCodes';
import Token from 'types/Token';
import Asset from 'types/api/chainrunner/Asset';
import ChainIdByString from 'types/api/chainrunner/ChainIdByString';
import ChainrunnerParameter from 'types/api/chainrunner/ChainrunnerParameter';
import ChainrunnerResponse, {
  ChainrunnerErrorResponse,
} from 'types/api/chainrunner/ChainrunnerResponse';
import NumberByString from 'types/api/chainrunner/NumberByString';
import { getIsCoinAddress } from './stringUtils';

export const getChainrunnerParameters = (
  parameters?: (string | number | BN)[]
): ChainrunnerParameter[] =>
  parameters?.map((parameter) =>
    typeof parameter === 'string'
      ? parameter === '()'
        ? parameter
        : `"${parameter}"`
      : typeof parameter === 'object'
        ? `${formatDecimal(parameter, 36)}`
        : `${parameter}`
  ) ?? [];

export const parsePositiveBN = (value?: UnknownNumber) =>
  new BN(formatDecimal((isNormalPositive(value) ? value : 0) ?? 0, 18));

export const parseChainrunnerNumber = (value?: NumberByString) =>
  parseInt(`${value ?? 0}`);

export const parseChainrunnerDecimals = (value?: NumberByString) =>
  parseChainrunnerNumber(value ?? '18');

export const parseChainrunnerChainId = (chainId?: ChainIdByString) =>
  parseChainrunnerNumber(chainId);

export const parseAssetToToken = (asset?: Asset): Token => ({
  chainId: parseChainrunnerChainId(asset?.chainId),
  address: asset?.address ?? '',
  symbol: asset?.symbol ?? '',
  decimals: parseChainrunnerDecimals(asset?.decimals),
  color: '',
  isCoin: getIsCoinAddress(asset?.address),
});

export const parseErrorMessage = (
  error?: any,
  t?: TFunction
): React.ReactNode => {
  let result = '';

  if (error) {
    if (typeof error === 'object') {
      if ((error as Array<any>).length) {
        result = (error as Array<any>).map((e) => `${e}`).join(' ');
      } else {
        if ((error as ChainrunnerErrorResponse)?.code) {
          const { code: bnCode } = error;
          const code = parseInt(`${bnCode}`);

          if (EXCEPT_ERROR_CODES.includes(code)) {
            return '';
          } else if (REQUIRE_PARSE_ERROR_CODES.includes(code)) {
            switch (code) {
              case 1003:
                return (
                  t?.('common:error.boundOver') ||
                  'The size of the order is large compared to the current liquidity.'
                );
              case 1004:
                return (
                  (
                    <Trans
                      i18nKey="common:error.noSwapPath"
                      t={t}
                      components={{
                        faqLink: (
                          <UnderlineLinkButton
                            target="_blank"
                            rel="noopener noreferrer"
                            href={t?.('common:link.faq') || ''}
                          />
                        ),
                        br: <br />,
                      }}
                    />
                  ) ||
                  'This token cannot be traded, please refer to the FAQ for more information.'
                );
              case 1005:
                return (
                  t?.('common:error.liquidityInsufficient') ||
                  'Insufficient liquidity'
                );
              case 2001:
                return (
                  t?.('common:error.disconnected') || 'Please connect a wallet.'
                );
              case 3001:
              case 3004:
                return (
                  t?.('common:error.apiError', [code]) ||
                  `[${code}] Error occurred. Please try again.`
                );
              case 4000:
                return (
                  t?.('common:error.transactionRevert') ||
                  'The transaction failed.\nPlease try again.'
                );
            }
          }

          result = `[${code}] `;
        }

        if ((error as { reason: string })?.reason) {
          result = `${result} ${error.reason}`;
        } else if ((error as { message: string })?.message) {
          result = `${result} ${error.message}`;
        } else if ((error as { error: string })?.error) {
          result = `${result} ${error.error}`;
        }

        if ((error as ChainrunnerErrorResponse)?.log) {
          result = `${result} ${error.log}`;
        }
      }
    } else if (typeof error === 'boolean') {
      result = 'Unknown error';
    } else {
      result = `${error}`;
    }
  }

  return result;
};

export const getIsUserReject = (error?: any) =>
  error && typeof error === 'object' && error.code
    ? isUserReject({ ...error, code: parseInt(`${error.code}`) })
    : (error as ChainrunnerResponse<boolean>)?.result?.error?.code
      ? isUserReject({
          ...error.result.error,
          code: parseInt(`${error.result.error.code}`),
        })
      : false;
