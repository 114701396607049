import isDarkmode from 'lib/isDarkmode';

const darkmode = isDarkmode();

const COLOR_COMMON = {
  tint: '#ff474c',
  white: '#fff',
  beigeDark: '#f1efe8',
  beigeLight: '#fdfdf7',
  greyCC: '#ccc',
  greyAA: '#aaa',
  greyLight: '#e8e6e2',
  black1A: '#1a1a1a',
  black8: '#888',
  blue: '#0085ff',
  skyblue: '#2775c9',
  red: '#ff0007',
  green: '#0ed877',
  green2: '#19c974',
  //
  white2: '#f8f8f8',
  black: '#222',
  tint2: '#fdec94',
  transparent: 'transparent',
};
const COLOR_COMPONENT = {
  point: darkmode ? COLOR_COMMON.tint : COLOR_COMMON.tint,
  outline: darkmode ? COLOR_COMMON.greyLight : COLOR_COMMON.greyLight,
  blueLink: darkmode ? COLOR_COMMON.blue : COLOR_COMMON.blue,
  ec: darkmode ? COLOR_COMMON.red : COLOR_COMMON.red,
  //
  background: darkmode ? COLOR_COMMON.beigeLight : COLOR_COMMON.beigeLight,
  chipBackground: darkmode ? COLOR_COMMON.beigeDark : COLOR_COMMON.beigeDark,
  popupBackground: darkmode ? COLOR_COMMON.beigeLight : COLOR_COMMON.beigeLight,
  text: darkmode ? COLOR_COMMON.black1A : COLOR_COMMON.black1A,
  box: darkmode ? COLOR_COMMON.beigeDark : COLOR_COMMON.beigeDark,
  icon: darkmode ? COLOR_COMMON.black1A : COLOR_COMMON.black1A,
  headerBackground: darkmode
    ? `${COLOR_COMMON.beigeLight}e6`
    : `${COLOR_COMMON.beigeLight}e6`,
  footerBackground: darkmode ? COLOR_COMMON.beigeDark : COLOR_COMMON.beigeDark,
  placeholder: darkmode ? COLOR_COMMON.black8 : COLOR_COMMON.black8,
  gradient: darkmode
    ? `linear-gradient(90deg, ${COLOR_COMMON.tint} 0%, rgba(253, 236, 148, 0.6) 100%)`
    : `linear-gradient(90deg, ${COLOR_COMMON.tint} 0%, rgba(253, 236, 148, 0.6) 100%)`,
  gradient2: darkmode
    ? `linear-gradient(90deg, #888 0%, ${COLOR_COMMON.tint} 100%)`
    : `linear-gradient(90deg, #888 0%, ${COLOR_COMMON.tint} 100%)`,
  label: darkmode ? COLOR_COMMON.greyAA : COLOR_COMMON.greyAA,
  label2: darkmode ? '#666' : '#666',
  label3: darkmode ? COLOR_COMMON.greyCC : COLOR_COMMON.greyCC,
  input: darkmode ? '#ddd' : '#ddd',
  border: darkmode ? '#444' : '#444',
  divider: darkmode ? '#333' : '#333',
  buttonText: darkmode ? COLOR_COMMON.white : COLOR_COMMON.white,
  buttonBackground: darkmode ? COLOR_COMMON.black1A : COLOR_COMMON.black1A,
  buttonBackgroundDisabled: darkmode
    ? COLOR_COMMON.black8
    : COLOR_COMMON.black8,
};
const COLOR_TOKEN = {
  bfc: '#ff474c',
  eth: '#343434',
  bifi: '#87ceeb',
  bnb: '#f0b90b',
  pol: '#8247e5',
  usdc: '#2775c9',
  usdt: '#53ae94',
  dai: '#f5ac37',
  busd: '#f0b90b',
  p2d: '#36ad74',
  wbtc: '',
  btcb: '',
  btcusd: '',
};
const color = {
  ...COLOR_COMMON,
  ...COLOR_COMPONENT,
  ...COLOR_TOKEN,
};

export default color;
