import Language from 'types/Language';

export const LANGUAGE_EN: Language = {
  id: 'en',
  title: 'English',
};
export const LANGUAGE_KO: Language = {
  id: 'ko',
  title: '한국어',
};

const LANGUAGES: Language[] = [LANGUAGE_EN, LANGUAGE_KO];

export default LANGUAGES;
