import {
  getStorageItemString,
  setStorageItemString,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import getWindowLocation from 'lib/getWindowLocation';
import EnvNetworkTitle from 'types/EnvNetworkTitle';

export const setIsTestnet = (isTestnetEnvironment?: boolean) => {
  setStorageItemString('isTestnet', JSON.stringify(!!isTestnetEnvironment));
  getWindowLocation()?.reload?.();
};

const IS_TESTNET = getStorageItemString('isTestnet') === 'true';

export const NETWORK_TITLE: EnvNetworkTitle = IS_TESTNET
  ? 'Testnet'
  : 'Mainnet';

export default IS_TESTNET;
