import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { PATH_HOME } from 'configs/paths';
import checkIsAvailablePathname from 'lib/devEnv/checkIsAvailablePathname';

const useCheckAvailablePathname = () => {
  const router = useRouter();
  const { pathname, query, locale } = router;

  const checkAvailablePathname = useCallback(async () => {
    if (!checkIsAvailablePathname(pathname)) {
      await router.replace({ pathname: PATH_HOME, query }, PATH_HOME, {
        locale,
      });
    }
  }, [locale, pathname, query, router]);

  useEffect(() => {
    checkAvailablePathname();
  }, [checkAvailablePathname]);

  return checkAvailablePathname;
};

export default useCheckAvailablePathname;
