import ChainrunnerPayload from 'types/api/chainrunner/ChainrunnerPayload';
import ChainrunnerResponse from 'types/api/chainrunner/ChainrunnerResponse';
import { everdex, chainrunner } from './axiosInstance';

export const fetcherEverdex = (url: string) =>
  everdex
    .get(url)
    .then((res) => res.data)
    .catch((err) => err);

export const fetcherChainrunner = <T = any>(
  payload: ChainrunnerPayload
): Promise<ChainrunnerResponse<T>> =>
  chainrunner
    .post('', payload)
    .then((res) => res.data)
    .catch((err) => err);

export const fetcherEverdexArray = (...urls: string[]) =>
  Promise.all(urls.map(fetcherEverdex));

export const fetcherChainrunnerArray = (...payloads: ChainrunnerPayload[]) =>
  Promise.all(payloads.map(fetcherChainrunner));
