import { compareLowerStr } from '@bifrost-platform/bifront-sdk-react-wallet';
import MAINNET_TOKENS from 'configs/tokens/mainnetTokens';
import TESTNET_TOKENS from 'configs/tokens/testnetTokens';
import Token from 'types/Token';
import IS_TESTNET from './devEnv/envIsTestnet';

const EXCEPTIONS = ['BUSD', 'P2D'];

export const findMainnetToken = (symbol?: string) =>
  MAINNET_TOKENS.filter((token) => !EXCEPTIONS.includes(token.symbol)).find(
    (mainnetToken) => compareLowerStr(mainnetToken.symbol, symbol ?? '')
  );
export const findMainnetTokenByAddress = (address: string) =>
  findMainnetToken(
    TESTNET_TOKENS.find((token) => compareLowerStr(address, token.address))
      ?.symbol
  );
export const parseMainnetToken = (token?: Token) =>
  (IS_TESTNET ? findMainnetToken(token?.symbol) : token) ?? token;
