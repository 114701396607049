import styled from '@emotion/styled';

const PopupTitle = styled.div`
  padding-bottom: ${({ theme }) => theme.gap.lg};
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.point};
`;

export default PopupTitle;
