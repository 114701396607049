// everdex
export const API_PATH_EVERDEX_POOLS = '/v2/chains/{chainId}/pools';
export const API_PATH_EVERDEX_POOL = '/v2/chains/{chainId}/pools/{address}';
export const API_PATH_EVERDEX_POOL_CHART =
  '/v2/chains/{chainId}/pools/{address}/chart';
export const API_PATH_EVERDEX_LIQUIDITY_POSITIONS =
  '/v2/chains/{chainId}/liquidity-positions/{address}';
export const API_PATH_EVERDEX_MARKET_STATS = '/v2/chains/{chainId}/market';

// biholder
export const API_PATH_BIHOLER_ASSET = '/asset/asset';
export const API_PATH_BIHOLER_CHAIN_IMAGE =
  '/cache/asset_info/networks/{networkId}/image';
export const API_PATH_BIHOLER_BALANCES = '/account/balances';
