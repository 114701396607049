export const PATH_HOME = '/';
export const PATH_SWAP = '/swap';
export const PATH_POOL = '/pool';
export const PATH_LP_REWARD = '/lpReward';
export const PATH_STATS = '/stats';
export const PATH_HISTORY = '/history';

export const PATHS_ERROR_VIEWABLE: string[] = [PATH_POOL, PATH_LP_REWARD];
export const PATHS_WALLET_VIEWABLE: string[] = [
  PATH_SWAP,
  ...PATHS_ERROR_VIEWABLE,
  PATH_HISTORY,
];
