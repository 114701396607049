import styled from '@emotion/styled';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import Zoom from 'components/mui/Zoom';
import useIsMaintaining from 'hooks/useIsMaintaining';

export type Navigation = {
  path: string;
  title: string;
};
export type Props = {
  navigations: Navigation[];
};

const NAVIGATION_WIDTH = 72;
const NAVIGATION_WIDTH_LONG = 98;
const NAVIGATION_HEIGHT = 51;
const NAVIGATION_GAP = 8;
const NAVIGATION_WIDTH_THRESHOLD = 8;

const Root = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: ${NAVIGATION_GAP}px;
  padding: 12px;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.color.beigeDark};
  overflow: hidden;
`;
const NavigationBase = styled.div<{ activated?: boolean; width?: number }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => width ?? NAVIGATION_WIDTH}px;
  height: ${NAVIGATION_HEIGHT}px;
  border-radius: 16px;
  ${({ activated, theme }) =>
    activated ? 'cursor: default;' : theme.transitionSet.hoverOpacityButton}
`;
const ActivatedNavigationContainer = styled(NavigationBase)<{
  offset?: number;
}>`
  position: absolute;
  top: 12px;
  left: 12px;
  transform: translateX(${({ offset }) => offset ?? 0}px);
  transition: transform ${({ theme }) => theme.transition.single};
`;
const ActivatedNavigation = styled(NavigationBase)`
  background-color: ${({ theme }) => theme.color.point};
`;
const Navigation = styled(NavigationBase)`
  color: ${({ activated, theme }) =>
    activated ? theme.color.white : theme.color.black1A};
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: 500;
`;

const Navigator: React.FC<Props> = ({ navigations }) => {
  const { asPath } = useRouter();

  const isMaintaining = useIsMaintaining();

  const [index, setIndex] = useState(0);

  const navigationWidthes = useMemo(
    () =>
      navigations.map((navigation) =>
        navigation.title.length > NAVIGATION_WIDTH_THRESHOLD
          ? NAVIGATION_WIDTH_LONG
          : NAVIGATION_WIDTH
      ),
    [navigations]
  );
  const navigationOffset = useMemo(
    () =>
      navigationWidthes.slice(0, index).reduce((pre, curr) => curr + pre, 0) +
      index * NAVIGATION_GAP,
    [index, navigationWidthes]
  );
  const activatedNavigationIndex = useMemo(
    () =>
      navigations.findIndex((navigation) => asPath.startsWith(navigation.path)),
    [navigations, asPath]
  );
  const isShowActivatedNavigation = useMemo(
    () => activatedNavigationIndex >= 0,
    [activatedNavigationIndex]
  );
  const isDisabled = useMemo(() => isMaintaining, [isMaintaining]);

  useEffect(() => {
    if (activatedNavigationIndex >= 0) {
      setIndex(activatedNavigationIndex);
    }
  }, [activatedNavigationIndex]);

  return (
    <Root>
      <ActivatedNavigationContainer
        width={navigationWidthes[index]}
        offset={navigationOffset}>
        <Zoom in={isShowActivatedNavigation}>
          <ActivatedNavigation width={navigationWidthes[index]} />
        </Zoom>
      </ActivatedNavigationContainer>
      {navigations.map((navigation, navigationIndex) =>
        isDisabled ? (
          <a key={navigationIndex}>
            <Navigation
              activated={navigationIndex === activatedNavigationIndex}
              width={navigationWidthes[navigationIndex]}>
              {navigation.title}
            </Navigation>
          </a>
        ) : (
          <Link href={navigation.path} key={navigationIndex}>
            <Navigation
              activated={navigationIndex === activatedNavigationIndex}
              width={navigationWidthes[navigationIndex]}>
              {navigation.title}
            </Navigation>
          </Link>
        )
      )}
    </Root>
  );
};

export default Navigator;
