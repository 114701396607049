export const TOKEN_SYMBOL_BFC = 'BFC';
export const TOKEN_SYMBOL_WBFC = 'WBFC';
export const TOKEN_SYMBOL_BIFI = 'BiFi';
export const TOKEN_SYMBOL_ETH = 'ETH';
export const TOKEN_SYMBOL_BNB = 'BNB';
export const TOKEN_SYMBOL_POL = 'POL';
export const TOKEN_SYMBOL_DAI = 'DAI';
export const TOKEN_SYMBOL_USDC = 'USDC';
export const TOKEN_SYMBOL_USDT = 'USDT';
export const TOKEN_SYMBOL_BUSD = 'BUSD';
export const TOKEN_SYMBOL_P2D = 'P2D';
export const TOKEN_SYMBOL_WBTC = 'WBTC';
export const TOKEN_SYMBOL_BTCB = 'BTCB';
export const TOKEN_SYMBOL_BTCUSD = 'BtcUSD';

const TOKEN_SYMBOL_TOKENS = [
  TOKEN_SYMBOL_BFC,
  TOKEN_SYMBOL_WBFC,
  TOKEN_SYMBOL_BIFI,
  TOKEN_SYMBOL_ETH,
  TOKEN_SYMBOL_BNB,
  TOKEN_SYMBOL_POL,
  TOKEN_SYMBOL_DAI,
  TOKEN_SYMBOL_USDC,
  TOKEN_SYMBOL_USDT,
  TOKEN_SYMBOL_BUSD,
  TOKEN_SYMBOL_P2D,
  TOKEN_SYMBOL_WBTC,
  TOKEN_SYMBOL_BTCB,
  TOKEN_SYMBOL_BTCUSD,
];

export default TOKEN_SYMBOL_TOKENS;
