import {
  Transaction,
  useWallet,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import { useTranslation } from 'next-i18next';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { DAYS_30_BY_MILLISECONDS } from 'configs/time';
import TransactionHistory, {
  StorageTransactionHistory,
} from 'types/TransactionHistory';
import {
  openErrorToast,
  openSuccessToast,
  openTxToast,
} from 'utils/toastUtils';
import {
  loadTransactionHistories,
  makeTransactionWait,
  saveTransactionHistories,
} from 'utils/transactionHistory';

export type FunctionAddTransaction = (
  transactionHistory: TransactionHistory,
  message?: {
    process?: string;
    success?: string | boolean;
    error?: any;
    ignore?: boolean;
  }
) => Promise<boolean>;
export type Props = {
  transactionHistories: TransactionHistory[];
  transactionHistoriesIn30Days: TransactionHistory[];
  addTransaction?: FunctionAddTransaction;
};

export const Context = createContext<Props>({
  transactionHistories: [],
  transactionHistoriesIn30Days: [],
});

export const TransactionHistoriesProvider = ({
  children,
}: PropsWithChildren) => {
  const { t } = useTranslation('common');

  const { account, wallet } = useWallet();

  const [transactionHistories, setTransactionHistories] = useState<
    TransactionHistory[]
  >([]);

  const transactionHistoriesIn30Days = useMemo(
    () =>
      transactionHistories.filter(
        (transactionHistory) =>
          new Date(transactionHistory.timestamp ?? 0).getTime() >
          Date.now() - DAYS_30_BY_MILLISECONDS
      ),
    [transactionHistories]
  );

  const addTransaction: FunctionAddTransaction = useCallback(
    async (data, message) => {
      let result = false;

      try {
        let newData: TransactionHistory = data;

        if (!newData.timestamp) {
          newData = { ...newData, timestamp: Date.now() };
        }
        if (!newData.wait) {
          newData = {
            ...newData,
            wait: makeTransactionWait(newData.hash, wallet),
          };
        }

        setTransactionHistories((value) => [newData, ...value]);

        if (typeof message?.success === 'boolean' && message.success) {
          openSuccessToast({
            id: newData.hash,
            t,
          });
        } else if (typeof message?.error === 'boolean' && message.error) {
          openErrorToast(message.error);
        } else if (!newData.receipt) {
          if (!message?.ignore) {
            openTxToast(newData as Transaction, undefined, t, {
              success:
                typeof message?.success === 'string'
                  ? message.success
                  : undefined,
              process: message?.process,
            });
          }

          const receipt = await (newData as Transaction).wait();
          newData = { ...newData, receipt };

          setTransactionHistories((value) =>
            value.map((transactionHistory) =>
              transactionHistory.hash === newData.hash
                ? newData
                : transactionHistory
            )
          );
        }

        result = true;
      } catch (error) {
        setTransactionHistories((value) =>
          value.map((transactionHistory) =>
            transactionHistory.hash === data.hash
              ? { ...data, reverted: true }
              : transactionHistory
          )
        );
      }

      return result;
    },
    [t, wallet]
  );

  useEffect(() => {
    let transactionHistories: TransactionHistory[] = [];

    if (account) {
      try {
        transactionHistories = loadTransactionHistories(account);
      } catch (error) {}
    }

    setTransactionHistories(transactionHistories);
  }, [account]);
  useEffect(() => {
    if (account && transactionHistories.length) {
      try {
        saveTransactionHistories(
          transactionHistories
            .map((transactionHistory) => ({
              ...transactionHistory,
              tokens: transactionHistory.tokens ?? [],
              timestamp: transactionHistory.timestamp ?? Date.now(),
            }))
            .filter(
              ({ type, tokens, timestamp }) => !!(type && tokens && timestamp)
            ) as StorageTransactionHistory[],
          account
        );
      } catch (error) {}
    }
  }, [transactionHistories, account]);

  return (
    <Context.Provider
      value={{
        transactionHistories,
        transactionHistoriesIn30Days,
        addTransaction,
      }}>
      {children}
    </Context.Provider>
  );
};

const useTransactionHistories = (): Props => useContext(Context);

export default useTransactionHistories;
