import styled from '@emotion/styled';
import Btn from '.';

export const HeaderBtn = styled(Btn)`
  color: ${({ theme }) => theme.color.point};
`;

const HeaderDropdownBtn = styled(HeaderBtn)<{ isBackgrounded?: boolean }>`
  justify-content: space-between;
  height: 56px;
  padding: 0px ${({ theme }) => theme.gap.lg};
  border: 1px solid ${({ theme }) => theme.color.point};
  border-radius: ${({ theme }) => theme.radius.md};
  ${({ isBackgrounded, theme }) =>
    isBackgrounded
      ? `
        color: ${theme.color.white};
        background-color: ${theme.color.point}
      `
      : ''}
`;

export const HeaderDropdownContainer = styled.span`
  position: relative;
  display: inline-block;
`;

export default HeaderDropdownBtn;
