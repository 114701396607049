import {
  ZERO_VALUE,
  encode,
  Wallet,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import BN from 'bignumber.js';
import {
  FRAG_SYMBOL,
  FRAG_DECIMALS,
  FRAG_BALANCE,
  FRAG_ALLOWANCE,
  FRAG_TOTAL_SUPPLY,
  FRAG_APPROVE,
} from 'configs/fragment';
import {
  call,
  callEstimateGas,
  num2Hex,
  pickNumberResponse,
  pickStringResponse,
  send,
} from 'lib/contract/dex';
import {
  EstimateFunction,
  GetApproveDataFunction,
} from 'types/CommonSendFunction';
import {
  ContractFunctionCaller,
  ContractFunctionSender,
} from 'types/ContractFunction';

export const symbol: ContractFunctionCaller = async (option, args) =>
  call({ ...option, fragment: FRAG_SYMBOL }, args);
export const decimals: ContractFunctionCaller = async (option, args) =>
  call({ ...option, fragment: FRAG_DECIMALS }, args);
export const balanceOf: ContractFunctionCaller = async (option, args) =>
  call({ ...option, fragment: FRAG_BALANCE }, args);
export const allowance: ContractFunctionCaller = async (option, args) =>
  call({ ...option, fragment: FRAG_ALLOWANCE }, args);
export const totalSupply: ContractFunctionCaller = async (option, args) =>
  call({ ...option, fragment: FRAG_TOTAL_SUPPLY }, args);
export const approve: ContractFunctionSender = async (option, args) =>
  send({ ...option, fragment: FRAG_APPROVE }, args);

// token balance
export const callTokenBalance = async (
  tokenAddress: string,
  wallet?: Wallet,
  address?: string,
  onlyBifrost?: boolean
) =>
  pickNumberResponse(
    await balanceOf({ wallet, to: tokenAddress, from: address, onlyBifrost }, [
      address ?? wallet?.getAddress(),
    ])
  );
// allowance
export const callTokenAllowance = async (
  tokenAddress: string,
  contractAddress: string,
  wallet?: Wallet,
  address?: string,
  onlyBifrost?: boolean
) =>
  pickNumberResponse(
    await allowance({ wallet, to: tokenAddress, from: address, onlyBifrost }, [
      address ?? wallet?.getAddress(),
      contractAddress,
    ])
  );
// total supply
export const callTokenTotalSupply = async (
  tokenAddress: string,
  wallet?: Wallet,
  onlyBifrost?: boolean
) =>
  pickNumberResponse(
    await totalSupply({ wallet, to: tokenAddress, onlyBifrost })
  );
// symbol
export const callTokenSymbol = async (
  tokenAddress: string,
  wallet?: Wallet,
  onlyBifrost?: boolean
) =>
  pickStringResponse(await symbol({ wallet, to: tokenAddress, onlyBifrost }));
// decimals
export const callTokenDecimals = async (
  tokenAddress: string,
  wallet?: Wallet,
  onlyBifrost?: boolean
) =>
  pickNumberResponse(await decimals({ wallet, to: tokenAddress, onlyBifrost }));

export const makeApproveSendData = (
  token: string,
  owner: string,
  spender: string,
  amount: string
) => {
  const fragment = FRAG_APPROVE;
  const args = [spender, num2Hex(amount)];
  const data = encode(fragment, args);

  return {
    encodedParams: {
      from: owner,
      to: token,
      value: ZERO_VALUE,
      data,
    },
    sendParams: {
      from: owner,
      to: token,
      signature: fragment,
      values: args,
    },
  };
};

export const getApproveSendData: GetApproveDataFunction = async (
  tokenAddress,
  params
) =>
  makeApproveSendData(
    tokenAddress,
    params.from ?? '',
    params.to ?? '',
    params.amount
  );
export const getApproveEstimatedGas: EstimateFunction = async (
  wallet,
  encodedParams
) => {
  if (!(wallet && encodedParams)) {
    return new BN(0);
  }

  return pickNumberResponse(await callEstimateGas(wallet, encodedParams));
};
