import { useMemo } from 'react';
import { ChainId } from 'types/Chain';
import useChainImageMap from './useChainImageMap';

const useChainImage = (chainId?: ChainId): string | undefined => {
  const { chainImageMap } = useChainImageMap();

  const chainImage = useMemo(
    () => chainImageMap[chainId ?? ''] || undefined,
    [chainId, chainImageMap]
  );

  return chainImage;
};

export default useChainImage;
