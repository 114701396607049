import {
  getStorageItemString,
  removeStorageItem,
  setStorageItemString,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import getWindowLocation from 'lib/getWindowLocation';
import DevelopmentEnvironment from 'types/DevelopmentEnvironment';
import checkIsNormalEnvValue from './checkIsNormalEnvValue';
import IS_DEV from './isDev';

export const setEnvBiholderApi = (
  developmentEnvironment?: DevelopmentEnvironment
) => {
  if (!IS_DEV) {
    removeStorageItem('envBiholderApi');
    return;
  }

  setStorageItemString('envBiholderApi', developmentEnvironment ?? 'dev');
  getWindowLocation()?.reload?.();
};

const ENV_BIHOLDER_API: DevelopmentEnvironment =
  IS_DEV && checkIsNormalEnvValue(getStorageItemString('envBiholderApi'))
    ? (getStorageItemString('envBiholderApi') as DevelopmentEnvironment)
    : 'production';

export default ENV_BIHOLDER_API;
