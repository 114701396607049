import color from 'styles/theme/color';
import TRANSITION from './transition';

const TRANSITION_SET_HOVER_COLOR = `
  cursor: pointer;
  user-select: none;
  ${TRANSITION}
  will-change: color, border-color, opacity;
  &:hover {
    color: ${color.point};
    border-color: ${color.point};
  }
`;

export default TRANSITION_SET_HOVER_COLOR;
