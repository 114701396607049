import { atom } from 'jotai';
import { CurrencyId } from 'types/Currency';
import DataWithEmpty from 'types/DataWithEmpty';
import LpRewardPoolData from 'types/LpRewardPoolData';
import Token from 'types/Token';

export type Open = boolean;

export const currencyAtom = atom<CurrencyId>('usd');

export const isShowConnectWalletAtom = atom<Open>(true);
export const isShowWalletAtom = atom<Open>(false);
export const isShowSettingAtom = atom<Open>(false);
export const isShowSelectChainAtom = atom<Open>(false);
export const isShowSelectTokenAtom = atom<Open>(false);
export const isShowSelectCoinAtom = atom<Open>(false);
export const isShowSelectPairTokenAtom = atom<Open>(false);
export const isOpenDialogSwapSettingAtom = atom<Open>(false);
export const isOpenDialogLpStakeCtaAtom = atom<Open>(false);
export const dialogPoolDepositCtaAtom =
  atom<DataWithEmpty<{ address: string; tokens: Token[] }>>(undefined);
export const dialogLpDepositAtom =
  atom<DataWithEmpty<LpRewardPoolData>>(undefined);
export const dialogLpStakeClaimAtom =
  atom<DataWithEmpty<{ amount?: string; lpRewardPoolData?: LpRewardPoolData }>>(
    undefined
  );
export const dialogLpStakeWithdrawAtom =
  atom<DataWithEmpty<{ amount?: string; lpRewardPoolData?: LpRewardPoolData }>>(
    undefined
  );
export const isOpenDialogEventNoticeLpRewardAtom = atom<Open>(false);
