import styled from '@emotion/styled';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import ArbitrumImage from 'assets/notice/notice-icon-arbitrum.svg';
import CongratulationImage from 'assets/notice/notice-icon-congratulation.svg';
import { IconClose } from 'components/icons';
import Collapse from 'components/mui/Collapse';
import { IconBtn } from 'components/styled/btn';
import theme from 'styles/theme';
import Notice from 'types/Notice';

export type Props = {
  notice?: Notice;
  closeNotice: (id: string) => void;
};

const Root = styled.div<{ isShow?: boolean }>`
  position: relative;
  width: 100vw;
  height: 80px;
  padding: 0px 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: center;
  color: ${({ theme }) => theme.color.white};
  ${({ theme, isShow }) =>
    isShow ? `background-color: ${theme.color.black1A};` : 'opacity: 0;'}
`;
const NoticeRoot = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 40px;
  height: 100%;
  margin: 0px auto;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: ${({ theme }) => theme.color.white};
  text-wrap: nowrap;
`;
const CloseContainer = styled.div`
  display: inline-block;
  width: 24px;
`;
const Highlight = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 16px;
  font-weight: 700;
`;
const ImageCongratulation = styled(() => (
  <Image src={CongratulationImage} alt="" />
))`
  width: 24px;
`;
const ImageArbitrum = styled(() => <Image src={ArbitrumImage} alt="" />)`
  width: 24px;
`;

const NoticeSupportArbitrum: React.FC = () => {
  const { locale } = useRouter();

  return (
    <>
      {locale === 'ko' ? (
        <>
          <Highlight>
            <ImageCongratulation /> <ImageArbitrum /> Arbitrum 네트워크가
            추가되었어요 <ImageCongratulation />
          </Highlight>
          지금 에버덱스와 함께 거래해 보세요!
        </>
      ) : (
        <>
          <Highlight>
            <ImageCongratulation /> <ImageArbitrum /> Arbitrum Network is here{' '}
            <ImageCongratulation />
          </Highlight>
          Trade now!
        </>
      )}
    </>
  );
};

const HeaderNotice: React.FC<Props> = ({ notice, closeNotice }) => {
  const currentNoticeContent = useMemo(() => {
    switch (notice?.id) {
      case 'supportArbitrum':
        return <NoticeSupportArbitrum />;
    }
  }, [notice]);

  const handleCloseClick = useCallback(() => {
    if (!notice?.id) {
      return;
    }

    closeNotice(notice.id);
  }, [closeNotice, notice]);

  return (
    <Collapse in={!!notice}>
      <Root isShow={!!notice}>
        <NoticeRoot>
          {currentNoticeContent}
          <CloseContainer>
            <IconBtn size={24} onClick={handleCloseClick}>
              <IconClose size={24} color={theme.color.white} />
            </IconBtn>
          </CloseContainer>
        </NoticeRoot>
      </Root>
    </Collapse>
  );
};

export default HeaderNotice;
