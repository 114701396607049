import {
  ProviderOptions,
  CoinbaseOption,
  WalletconnectOption,
  WalletConstructorProps,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import CHAINS, { RPC_MAP } from './chains';
import { CHAIN_ID_BIFROST, CHAIN_ID_ETHEREUM } from './chains/chainIds';
import { CHAIN_RPC_URL_ETHEREUM } from './chains/chainRpcUrls';

export const COINBASE_OPTION: CoinbaseOption = {
  appName: 'Everdex',
  defaultChainId: CHAIN_ID_ETHEREUM,
  defaultRpc: CHAIN_RPC_URL_ETHEREUM,
};
export const WALLETCONNECT_OPTION: WalletconnectOption = {
  projectId: process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID ?? '',
  chains: [CHAIN_ID_BIFROST],
  optionalChains: [
    1,
    56,
    137,
    5,
    97,
    80001,
    ...CHAINS.map((chain) => chain.id),
  ],
  methods: [
    'eth_sendTransaction',
    'eth_signTransaction',
    'eth_sign',
    'personal_sign',
    'eth_signTypedData',
  ],
  events: ['chainChanged', 'accountsChanged'],
  rpcMap: RPC_MAP,
  showQrModal: true,
};

export const WALLET_PROVIDER_OPTIONS: ProviderOptions = {
  coinbaseOption: COINBASE_OPTION,
  walletconnectOption: WALLETCONNECT_OPTION,
};

const WALLET_CONSTRUCTOR_PROPS: WalletConstructorProps = {
  options: WALLET_PROVIDER_OPTIONS,
};

export default WALLET_CONSTRUCTOR_PROPS;
