import * as Sentry from '@sentry/nextjs';
import axios from 'axios';
import {
  URL_ORIGIN_EVERDEX,
  URL_ORIGIN_BIHOLDER,
  URL_ORIGIN_CHAINRUNNER,
} from 'configs/urlOrigin';

const AXIOS_INSTANCE_EVERDEX = axios.create({
  baseURL: URL_ORIGIN_EVERDEX,
  withCredentials: false,
  timeout: 10000,
});
const AXIOS_INSTANCE_CHAINRUNNER = axios.create({
  baseURL: URL_ORIGIN_CHAINRUNNER,
  withCredentials: false,
  timeout: 30000,
});
const AXIOS_INSTANCE_BIHOLDER = axios.create({
  baseURL: URL_ORIGIN_BIHOLDER,
  withCredentials: false,
  timeout: 10000,
});

AXIOS_INSTANCE_EVERDEX.interceptors.response.use(
  (response) => response,
  (error) => {
    Sentry.captureException(error);

    return Promise.reject(error);
  }
);
AXIOS_INSTANCE_CHAINRUNNER.interceptors.response.use(
  (response) => response,
  (error) => {
    Sentry.captureException(error);

    return Promise.reject(error);
  }
);
AXIOS_INSTANCE_BIHOLDER.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        Sentry.captureException(
          new Error(
            `API Error: ${error.response.status} ${error.response.statusText}`
          )
        );
      } else if (error.request) {
        Sentry.captureException(
          new Error('Network Error: No response received')
        );
      } else {
        Sentry.captureException(new Error(`Request Error: ${error.message}`));
      }
    } else {
      Sentry.captureException(error);
    }

    return Promise.reject(error);
  }
);

export const everdex = AXIOS_INSTANCE_EVERDEX;
export const chainrunner = AXIOS_INSTANCE_CHAINRUNNER;
export const biholder = AXIOS_INSTANCE_BIHOLDER;
