import { ADD_EMPTY } from 'configs/address';
import { TESTNET_CHAIN_ID_BIFROST } from 'configs/chains/testnet/chainId';
import theme from 'styles/theme';
import Token from 'types/Token';
import {
  TOKEN_NAME_BFC,
  TOKEN_NAME_WBFC,
  TOKEN_NAME_BIFI,
  TOKEN_NAME_ETH,
  TOKEN_NAME_BNB,
  TOKEN_NAME_POL,
  TOKEN_NAME_DAI,
  TOKEN_NAME_USDC,
  TOKEN_NAME_USDT,
  TOKEN_NAME_BUSD,
  TOKEN_NAME_P2D,
  TOKEN_NAME_WBTC,
  TOKEN_NAME_BTCB,
  TOKEN_NAME_BTCUSD,
} from './tokenNames';
import {
  TOKEN_SYMBOL_BFC,
  TOKEN_SYMBOL_WBFC,
  TOKEN_SYMBOL_BIFI,
  TOKEN_SYMBOL_ETH,
  TOKEN_SYMBOL_BNB,
  TOKEN_SYMBOL_POL,
  TOKEN_SYMBOL_DAI,
  TOKEN_SYMBOL_USDC,
  TOKEN_SYMBOL_USDT,
  TOKEN_SYMBOL_BUSD,
  TOKEN_SYMBOL_P2D,
  TOKEN_SYMBOL_WBTC,
  TOKEN_SYMBOL_BTCB,
  TOKEN_SYMBOL_BTCUSD,
} from './tokenSymbols';

export const TESTNET_TOKEN_ADDRESS_BFC = ADD_EMPTY;
export const TESTNET_TOKEN_ADDRESS_WBFC =
  '0x1745F24d85192545E5eD1c9574782d067D3Fda09';
export const TESTNET_TOKEN_ADDRESS_BIFI =
  '0x8010a873d59719e895E20f15f9906B5a1F399C3A';
export const TESTNET_TOKEN_ADDRESS_ETH =
  '0xc83EEd1bf5464eD5383bc3342b918E08f6815950';
export const TESTNET_TOKEN_ADDRESS_BNB =
  '0xCd8bf79fA84D551f2465C0a646cABc295d43Be5C';
export const TESTNET_TOKEN_ADDRESS_POL =
  '0xad115F901a1Af99dc83D055C89641031fd1a50Dc';
export const TESTNET_TOKEN_ADDRESS_USDC =
  '0x28661511CDA7119B2185c647F23106a637CC074f';
export const TESTNET_TOKEN_ADDRESS_USDT =
  '0x815e850CDDb2BB8C8afb61266525daFfB9adD7dc';
export const TESTNET_TOKEN_ADDRESS_DAI =
  '0x2353859d0c5CD0CB4Da701d2aCA9f1222Ad71110';
export const TESTNET_TOKEN_ADDRESS_BUSD =
  '0x875637eb68534B179FA16214300Eb23752C29428';
export const TESTNET_TOKEN_ADDRESS_P2D =
  '0xDa007Bea12013Ee90D5DEC4111DBA5bd98314F93';
export const TESTNET_TOKEN_ADDRESS_WBTC =
  '0xb710c446e2e4e162d734580bd6f13725c345e1f7';
export const TESTNET_TOKEN_ADDRESS_BTCB =
  '0xa4bde980daabdf9861f87ea89d4854a5ff062755';
export const TESTNET_TOKEN_ADDRESS_BTCUSD =
  '0x9039B6f30aa5bD00c303A3644c16B8Cc8031CE53';

export const TESTNET_TOKEN_BFC: Token = {
  chainId: TESTNET_CHAIN_ID_BIFROST,
  address: TESTNET_TOKEN_ADDRESS_BFC,
  name: TOKEN_NAME_BFC,
  symbol: TOKEN_SYMBOL_BFC,
  decimals: 18,
  isCoin: true,
  color: theme.color.bfc,
};
export const TESTNET_TOKEN_WBFC: Token = {
  chainId: TESTNET_CHAIN_ID_BIFROST,
  address: TESTNET_TOKEN_ADDRESS_WBFC,
  name: TOKEN_NAME_WBFC,
  symbol: TOKEN_SYMBOL_WBFC,
  decimals: 18,
  isCoin: false,
  color: theme.color.bfc,
};
export const TESTNET_TOKEN_BIFI: Token = {
  chainId: TESTNET_CHAIN_ID_BIFROST,
  address: TESTNET_TOKEN_ADDRESS_BIFI,
  name: TOKEN_NAME_BIFI,
  symbol: TOKEN_SYMBOL_BIFI,
  decimals: 18,
  color: theme.color.bifi,
};
export const TESTNET_TOKEN_ETH: Token = {
  chainId: TESTNET_CHAIN_ID_BIFROST,
  address: TESTNET_TOKEN_ADDRESS_ETH,
  name: TOKEN_NAME_ETH,
  symbol: TOKEN_SYMBOL_ETH,
  decimals: 18,
  color: theme.color.eth,
};
export const TESTNET_TOKEN_BNB: Token = {
  chainId: TESTNET_CHAIN_ID_BIFROST,
  address: TESTNET_TOKEN_ADDRESS_BNB,
  name: TOKEN_NAME_BNB,
  symbol: TOKEN_SYMBOL_BNB,
  decimals: 18,
  color: theme.color.bnb,
};
export const TESTNET_TOKEN_POL: Token = {
  chainId: TESTNET_CHAIN_ID_BIFROST,
  address: TESTNET_TOKEN_ADDRESS_POL,
  name: TOKEN_NAME_POL,
  symbol: TOKEN_SYMBOL_POL,
  decimals: 18,
  color: theme.color.pol,
};
export const TESTNET_TOKEN_USDC: Token = {
  chainId: TESTNET_CHAIN_ID_BIFROST,
  address: TESTNET_TOKEN_ADDRESS_USDC,
  name: TOKEN_NAME_USDC,
  symbol: TOKEN_SYMBOL_USDC,
  decimals: 6,
  color: theme.color.usdc,
};
export const TESTNET_TOKEN_USDT: Token = {
  chainId: TESTNET_CHAIN_ID_BIFROST,
  address: TESTNET_TOKEN_ADDRESS_USDT,
  name: TOKEN_NAME_USDT,
  symbol: TOKEN_SYMBOL_USDT,
  decimals: 6,
  color: theme.color.usdt,
};
export const TESTNET_TOKEN_DAI: Token = {
  chainId: TESTNET_CHAIN_ID_BIFROST,
  address: TESTNET_TOKEN_ADDRESS_DAI,
  name: TOKEN_NAME_DAI,
  symbol: TOKEN_SYMBOL_DAI,
  decimals: 18,
  color: theme.color.dai,
};
export const TESTNET_TOKEN_BUSD: Token = {
  chainId: TESTNET_CHAIN_ID_BIFROST,
  address: TESTNET_TOKEN_ADDRESS_BUSD,
  name: TOKEN_NAME_BUSD,
  symbol: TOKEN_SYMBOL_BUSD,
  decimals: 18,
  color: theme.color.busd,
};
export const TESTNET_TOKEN_P2D: Token = {
  chainId: TESTNET_CHAIN_ID_BIFROST,
  address: TESTNET_TOKEN_ADDRESS_P2D,
  name: TOKEN_NAME_P2D,
  symbol: TOKEN_SYMBOL_P2D,
  decimals: 18,
  color: theme.color.p2d,
};
export const TESTNET_TOKEN_WBTC: Token = {
  chainId: TESTNET_CHAIN_ID_BIFROST,
  address: TESTNET_TOKEN_ADDRESS_WBTC,
  name: TOKEN_NAME_WBTC,
  symbol: TOKEN_SYMBOL_WBTC,
  decimals: 8,
  color: theme.color.wbtc,
};
export const TESTNET_TOKEN_BTCB: Token = {
  chainId: TESTNET_CHAIN_ID_BIFROST,
  address: TESTNET_TOKEN_ADDRESS_BTCB,
  name: TOKEN_NAME_BTCB,
  symbol: TOKEN_SYMBOL_BTCB,
  decimals: 18,
  color: theme.color.btcb,
};
export const TESTNET_TOKEN_BTCUSD: Token = {
  chainId: TESTNET_CHAIN_ID_BIFROST,
  address: TESTNET_TOKEN_ADDRESS_BTCUSD,
  name: TOKEN_NAME_BTCUSD,
  symbol: TOKEN_SYMBOL_BTCUSD,
  decimals: 18,
  color: theme.color.btcusd,
};

const TESTNET_TOKENS = [
  TESTNET_TOKEN_BFC,
  TESTNET_TOKEN_WBFC,
  TESTNET_TOKEN_BIFI,
  TESTNET_TOKEN_ETH,
  TESTNET_TOKEN_BNB,
  TESTNET_TOKEN_POL,
  TESTNET_TOKEN_DAI,
  TESTNET_TOKEN_USDC,
  TESTNET_TOKEN_USDT,
  TESTNET_TOKEN_BUSD,
  TESTNET_TOKEN_P2D,
  TESTNET_TOKEN_WBTC,
  TESTNET_TOKEN_BTCB,
  TESTNET_TOKEN_BTCUSD,
];

export default TESTNET_TOKENS;
