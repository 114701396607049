const JSON_LD_STRUCTURED_DATA = {
  '@context': 'https://schema.org',
  '@type': 'Product',
  name: 'Everdex',
  url: 'https://www.everdex.app',
  description: 'Start secure and fast crypto trading with Everdex!',
  keywords:
    'Everdex, Dex, Blockchain, Defi, Multichain, Swap, Bridge, Pool, Dapp',
  image: '/og.png',
  logo: '/og.png',
};

export default JSON_LD_STRUCTURED_DATA;
