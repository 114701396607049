import TRANSITION from './transition';

const TRANSITION_SET_HOVER_OPACITY_BUTTON = `
  cursor: pointer;
  user-select: none;
  ${TRANSITION}
  will-change: opacity;
  &:hover {
    opacity: 0.6;
  }
  &:active {
    opacity: 0.4;
  }
`;

export default TRANSITION_SET_HOVER_OPACITY_BUTTON;
