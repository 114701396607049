import { datadogRum } from '@datadog/browser-rum';
import DATADOG_CONFIG from 'configs/datadog';
import IS_DEV from './devEnv/isDev';
import isCorrectOrigin from './isCorrectOrigin';

export const isUsableDatadog = () =>
  !!(
    typeof window === 'object' &&
    window &&
    DATADOG_CONFIG &&
    !IS_DEV &&
    isCorrectOrigin()
  );

export const initializeDatadog = () => {
  if (DATADOG_CONFIG) {
    try {
      datadogRum.init(DATADOG_CONFIG);
      datadogRum.startSessionReplayRecording();
    } catch (error) {}
  }
};

if (isUsableDatadog()) {
  initializeDatadog();
}
