// rpc urls
export const MAINNET_CHAIN_RPC_URLS_BIFROST = [
  'https://public-01.mainnet.thebifrost.io/rpc',
  'https://public-02.mainnet.thebifrost.io/rpc',
];
export const MAINNET_CHAIN_RPC_URLS_ETHEREUM = [
  'https://eth.llamarpc.com',
  'https://eth-rpc.gateway.pokt.network',
  'https://rpc.ankr.com/eth',
  'https://ethereum.publicnode.com',
];
export const MAINNET_CHAIN_RPC_URLS_BSC = [
  'https://bsc-dataseed4.ninicoin.io',
  'https://bsc-dataseed.binance.org',
  'https://bsc-dataseed1.defibit.io',
];
export const MAINNET_CHAIN_RPC_URLS_POLYGON = [
  'https://rpc.ankr.com/polygon',
  'https://rpc-mainnet.matic.quiknode.pro',
];
export const MAINNET_CHAIN_RPC_URLS_BASE = [
  'https://developer-access-mainnet.base.org',
  'https://base.meowrpc.com',
  'https://1rpc.io/base',
];
export const MAINNET_CHAIN_RPC_URLS_ARBITRUM = [
  'https://arbitrum.llamarpc.com',
  'wss://arbitrum-one.publicnode.com',
  'https://endpoints.omniatech.io/v1/arbitrum/one/public',
];
