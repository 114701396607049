import styled from '@emotion/styled';

export type Props = {};

const Root = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  pointer-events: none;
  cursor: default;
`;

const EmojiPartyPopper: React.FC<Props> = () => <Root>🎉</Root>;

export default EmojiPartyPopper;
