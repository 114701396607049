import styled from '@emotion/styled';

export type Props = {
  disabled?: boolean;
  isReadonly?: boolean;
  isGrow?: boolean;
  isSmall?: boolean;
};

const Btn = styled.a<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.base};
  text-align: center;
  cursor: pointer;
  user-select: none;
  ${({ isGrow }) => (isGrow ? 'flex-grow: 1;' : '')}
  ${({ theme, disabled, isReadonly }) =>
    disabled || isReadonly
      ? `
        cursor: default;
        pointer-events: none;
      `
      : theme.transitionSet.hoverOpacityButton}
  ${({ disabled }) => (disabled ? 'opacity: 0.6;' : '')}
`;

export const IconBtn = styled(Btn)<{ size?: number }>`
  width: ${({ size = 32 }) => size}px;
  height: ${({ size = 32 }) => size}px;
`;
export const ActionBtn = styled(Btn)<{
  isPointColor?: boolean;
  isNoBackground?: boolean;
}>`
  height: 64px;
  padding: 0px ${({ theme }) => theme.gap.lg};
  font-weight: 700;
  color: ${({ theme, isNoBackground }) =>
    isNoBackground ? theme.color.black1A : theme.color.white};
  background-color: ${({ theme, disabled, isPointColor, isNoBackground }) =>
    disabled
      ? theme.color.greyCC
      : isPointColor
        ? theme.color.point
        : isNoBackground
          ? 'transparent'
          : theme.color.black1A};
  border-radius: ${({ theme }) => theme.radius.lg};
  ${({ theme, disabled }) =>
    disabled ? 'opacity: 1;' : theme.transitionSet.hoverButton}
  box-shadow: none;
`;

export default Btn;
