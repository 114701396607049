import TRANSITION_SET_HOVER_BUTTON from 'utils/style/transitionSetHoverButton';
import TRANSITION_SET_HOVER_COLOR from 'utils/style/transitionSetHoverColor';
import TRANSITION_SET_HOVER_COLOR_BUTTON from 'utils/style/transitionSetHoverColorButton';
import TRANSITION_SET_HOVER_OPACITY from 'utils/style/transitionSetHoverOpacity';
import TRANSITION_SET_HOVER_OPACITY_BUTTON from 'utils/style/transitionSetHoverOpacityButton';
import TRANSITION_SET_HOVER_OVERLAY from 'utils/style/transitionSetHoverOverlay';
import TRANSITION_SET_HOVER_SCALE from 'utils/style/transitionSetHoverScale';
import TRANSITION_SET_HOVER_SCALE_BUTTON from 'utils/style/transitionSetHoverScaleButton';
import TRANSITION_SET_HOVER_TR_OVERLAY from 'utils/style/transitionSetHoverTrOverlay';
import TRANSITION_SET_SUBBUTTON_OPACITY from 'utils/style/transitionSetSubbuttonOpacity';
import TRANSITION_SET_TEXT_FIELD_OPACITY from 'utils/style/transitionSetTextFieldOpacity';

const transitionSet = {
  textFieldOpacity: TRANSITION_SET_TEXT_FIELD_OPACITY,
  subbuttonOpacity: TRANSITION_SET_SUBBUTTON_OPACITY,
  hoverTrOverlay: TRANSITION_SET_HOVER_TR_OVERLAY,
  hoverButton: TRANSITION_SET_HOVER_BUTTON,
  hoverColor: TRANSITION_SET_HOVER_COLOR,
  hoverColorButton: TRANSITION_SET_HOVER_COLOR_BUTTON,
  hoverScale: TRANSITION_SET_HOVER_SCALE,
  hoverScaleButton: TRANSITION_SET_HOVER_SCALE_BUTTON,
  hoverOpacity: TRANSITION_SET_HOVER_OPACITY,
  hoverOpacityButton: TRANSITION_SET_HOVER_OPACITY_BUTTON,
  hoverOverlay: TRANSITION_SET_HOVER_OVERLAY,
};

export default transitionSet;
