import { compareLowerStr } from '@bifrost-platform/bifront-sdk-react-wallet';
import { AxiosResponse } from 'axios';
import { useAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { API_PATH_EVERDEX_POOLS } from 'configs/apiPath';
import { CHAIN_ID_BIFROST } from 'configs/chains/chainIds';
import EXCEPT_PAIR_ADDRESSES from 'configs/exceptPairs';
import { everdex } from 'lib/axiosInstance';
import { isLoadingPoolsAtom, poolsAtom } from 'store/pool';
import Pool from 'types/api/everdex/Pool';
import ResponseEverdex from 'types/api/everdex/ResponseEverdex';
import ResponseEverdexList from 'types/api/everdex/ResponseEverdexList';

export const getEverdexPools = (
  cursor?: string
): Promise<AxiosResponse<ResponseEverdex<ResponseEverdexList<Pool>>>> =>
  everdex.get<ResponseEverdex<ResponseEverdexList<Pool>>>(
    `${API_PATH_EVERDEX_POOLS.replace('{chainId}', `${CHAIN_ID_BIFROST}`)}?limit=10&${cursor ? `cursor=${cursor}` : ''}`
  );

export const getPools = async (): Promise<Pool[]> => {
  const pools: Pool[] = [];

  let cursor: string | undefined;

  const {
    data: { data },
  } = await getEverdexPools();

  pools.push(...(data?.items ?? []));

  cursor = data?.cursor;

  while (cursor) {
    const {
      data: { data },
    } = await getEverdexPools(cursor);

    pools.push(...(data?.items ?? []));

    cursor = data?.cursor;
  }

  return pools;
};

const usePools = () => {
  const [pools, setPools] = useAtom(poolsAtom);
  const [isLoading, setIsLoading] = useAtom(isLoadingPoolsAtom);

  const filteredPools = useMemo(
    () =>
      pools.filter(
        (pool) =>
          !EXCEPT_PAIR_ADDRESSES.find((address) =>
            compareLowerStr(address, pool.address)
          )
      ),
    [pools]
  );

  const sync = useCallback(async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      const newPools = await getPools();

      if (newPools.length) {
        setPools(newPools);
      }
    } catch (error) {}

    setIsLoading(false);
  }, [isLoading, setIsLoading, setPools]);

  return {
    pools: filteredPools,
    isLoading,
    sync,
  };
};

export default usePools;
