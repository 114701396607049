import {
  isValidAddress,
  Transaction,
  useWallet,
  formatDecimal,
  isNormalPositive,
  parseUnit,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import styled from '@emotion/styled';
import BN from 'bignumber.js';
import { useTranslation } from 'next-i18next';
import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Loading from 'components/Loading';
import TokenPair from 'components/TokenPair';
import { IconArrowAlt } from 'components/icons';
import Collapse from 'components/mui/Collapse';
import { ActionBtn } from 'components/styled/btn';
import Input from 'components/styled/input';
import {
  InputCardRatio as OriginInputCardRatio,
  InputCardRatioBtn as OriginInputCardRatioBtn,
} from 'components/styled/input/InputCard';
import useCurrency from 'hooks/api/biholder/useCurrency';
import useApprove from 'hooks/useApprove';
import useFirestore from 'hooks/useFirestore';
import useTransactionHistories from 'hooks/useTransactionHistories';
import { lpRewardProxyContract } from 'lib/contract';
import { formatAuto, num2Hex } from 'lib/contract/dex';
import log from 'lib/log';
import uuid from 'lib/uuid';
import LpRewardPoolData from 'types/LpRewardPoolData';
import { getIsUserReject } from 'utils/chainrunner';
import { handleNumberInputChange } from 'utils/input';
import { openErrorToast } from 'utils/toastUtils';
import Root, {
  Props as DialogProps,
  DialogCloseBtn,
  DialogContainer,
  DialogHeader,
  DialogTitle,
} from '.';

export type Props = DialogProps & {
  lpRewardPoolData?: LpRewardPoolData;
};

const BALANCE_INPUT_RATIO = [25, 50, 75, 100];

const Container = styled(DialogContainer)`
  gap: 24px;
  width: 360px;
  min-width: 360px;
`;
const Header = styled(DialogHeader)`
  justify-content: center;
  padding: 8px 0px;
`;
const Title = styled(DialogTitle)`
  gap: 4px;
  padding: 4px 0px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
`;
const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
`;
const InputCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 16px;
  border-radius: 24px;
  gap: 16px;
  background-color: ${({ theme }) => theme.color.box};
`;
const InputCardHeader = styled.div`
  min-height: 24px;
  font-size: 16px;
  font-weight: 700;
`;
const InputCardLabel = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  & > span {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    text-align: right;
    text-wrap: nowrap;
    color: ${({ theme }) => theme.color.label};
  }
`;
const InputCardInputContainer = styled.div``;
const InputCardInput = styled(Input)`
  flex-grow: 1;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  background-color: transparent;
  border: none;
  outline: none;
  &::placeholder {
    color: ${(p) => p.theme.color.label3};
  }
`;
const InputCardInputLabel = styled.div`
  height: 12px;
  padding-top: 4px;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.label};
`;
const InputCardRatio = styled(OriginInputCardRatio)`
  padding: 0px;
`;
const InputCardRatioBtn = styled(OriginInputCardRatioBtn)`
  font-size: 14px;
`;
const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ActionWrapper = styled.div`
  flex-grow: 1;
`;
const ApproveContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 182px;
  padding-right: 10px;
  align-items: center;
`;

const DialogLpDeposit: React.FC<Props> = ({
  open,
  lpRewardPoolData,
  onClose: handleClose,
}) => {
  const { t } = useTranslation('dialog');

  const { addPoolAction } = useFirestore();

  const { addTransaction } = useTransactionHistories();

  const { account, wallet } = useWallet();

  const { commarize } = useCurrency();

  const [inputAmount, setInputAmount] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const isInputDisabled = useMemo(
    () => !isNormalPositive(lpRewardPoolData?.balance, true),
    [lpRewardPoolData?.balance]
  );
  const isRatioButtonDisabled = useMemo(
    () => isInputDisabled,
    [isInputDisabled]
  );
  const tokens = useMemo(
    () => lpRewardPoolData?.tokens ?? [],
    [lpRewardPoolData?.tokens]
  );
  const balance = useMemo(
    () =>
      lpRewardPoolData?.balance &&
      isNormalPositive(lpRewardPoolData?.balance, true)
        ? lpRewardPoolData.balance
        : '0',
    [lpRewardPoolData?.balance]
  );
  const lpPrice = useMemo(
    () =>
      lpRewardPoolData?.lpPrice &&
      isNormalPositive(lpRewardPoolData?.lpPrice, true)
        ? lpRewardPoolData.lpPrice
        : '0',
    [lpRewardPoolData?.lpPrice]
  );
  const balanceInCurrency = useMemo(
    () =>
      isNormalPositive(lpRewardPoolData?.balance, true) &&
      isNormalPositive(lpPrice, true)
        ? new BN(lpRewardPoolData?.balance ?? 0).multipliedBy(lpPrice)
        : undefined,
    [lpPrice, lpRewardPoolData?.balance]
  );
  const inputAmountInCurrency = useMemo(
    () =>
      isNormalPositive(inputAmount, true) && isNormalPositive(lpPrice, true)
        ? new BN(inputAmount ?? 0).multipliedBy(lpPrice)
        : undefined,
    [lpPrice, inputAmount]
  );
  const proxyAddress = useMemo(
    () =>
      lpRewardPoolData?.rewardContract?.proxyAddress &&
      isValidAddress(lpRewardPoolData.rewardContract.proxyAddress)
        ? lpRewardPoolData.rewardContract.proxyAddress
        : undefined,
    [lpRewardPoolData?.rewardContract?.proxyAddress]
  );
  const stakingStateAddress = useMemo(
    () =>
      lpRewardPoolData?.rewardContract?.stakingStateAddress &&
      isValidAddress(lpRewardPoolData.rewardContract.stakingStateAddress)
        ? lpRewardPoolData.rewardContract.stakingStateAddress
        : undefined,
    [lpRewardPoolData?.rewardContract?.stakingStateAddress]
  );
  const lpTokenAddress = useMemo(
    () =>
      lpRewardPoolData?.rewardContract?.lpTokenAddress &&
      isValidAddress(lpRewardPoolData.rewardContract.lpTokenAddress)
        ? lpRewardPoolData.rewardContract.lpTokenAddress
        : undefined,
    [lpRewardPoolData?.rewardContract?.lpTokenAddress]
  );

  const { isApproved, approve: runApprove } = useApprove(
    lpTokenAddress,
    stakingStateAddress,
    parseUnit(formatDecimal(inputAmount, 18), 18)
  );

  const initialize = useCallback(() => {
    setInputAmount('');
  }, []);
  const approve = useCallback(async () => {
    if (
      !(
        account &&
        isValidAddress(account) &&
        stakingStateAddress &&
        isValidAddress(stakingStateAddress) &&
        lpTokenAddress &&
        isValidAddress(lpTokenAddress)
      ) ||
      isApproved ||
      isLoading
    ) {
      return;
    }

    setIsLoading(true);

    try {
      await runApprove('pool');
    } catch (error) {
      if (getIsUserReject(error)) {
        return;
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    account,
    stakingStateAddress,
    lpTokenAddress,
    isApproved,
    isLoading,
    runApprove,
  ]);
  const deposit = useCallback(async () => {
    if (
      !(
        account &&
        isValidAddress(account) &&
        proxyAddress &&
        isValidAddress(proxyAddress)
      ) ||
      !(
        balance &&
        isNormalPositive(balance, true) &&
        isNormalPositive(inputAmount, true) &&
        new BN(balance).gte(inputAmount)
      ) ||
      !isApproved ||
      isLoading
    ) {
      return;
    }

    setIsLoading(true);

    const amount = num2Hex(parseUnit(inputAmount, 18));

    const storeId = uuid();
    const type = 'lpRewardDeposit';
    const amounts = [`${inputAmount}`];

    try {
      const args = [amount];
      log(args);
      const transaction: Transaction = await lpRewardProxyContract.stake(
        {
          wallet,
          to: proxyAddress,
        },
        args
      );

      const transactionHash = transaction.hash;
      addPoolAction?.({
        type,
        id: storeId,
        tokens,
        amounts,
        transactionHash,
      });
      await addTransaction?.(
        {
          ...transaction,
          type,
          tokens,
          amounts,
        },
        {
          process: t('common:message.lpReward.deposit.process'),
          success: t('common:message.lpReward.deposit.success'),
        }
      );
      addPoolAction?.({
        type,
        id: storeId,
        tokens,
        amounts,
        transactionHash,
        complete: true,
      });

      handleClose?.({}, 'flowFinish');
    } catch (error) {
      if (getIsUserReject(error)) {
        return;
      }

      addPoolAction?.({
        type,
        id: storeId,
        tokens,
        amounts,
        error,
      });
      // handle out of slippage
      openErrorToast(error);
    } finally {
      setIsLoading(false);
    }

    initialize();
  }, [
    account,
    proxyAddress,
    balance,
    inputAmount,
    isApproved,
    isLoading,
    initialize,
    wallet,
    addPoolAction,
    tokens,
    addTransaction,
    t,
    handleClose,
  ]);

  const handleCloseClick = useCallback(
    () => handleClose?.({}, 'closeClick'),
    [handleClose]
  );
  const handleValueChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => setInputAmount(event.target.value),
    []
  );
  const handleRatioClick = useCallback(
    (ratio: number) => () => {
      setInputAmount(
        formatDecimal(
          lpRewardPoolData?.balance
            ? BN.max(
                new BN(lpRewardPoolData.balance).multipliedBy(
                  new BN(ratio).div('100')
                ),
                0
              )
            : 0,
          18
        )
      );
    },
    [lpRewardPoolData?.balance]
  );
  const handleApproveClick = useCallback(() => {
    approve();
  }, [approve]);
  const handleDepositClick = useCallback(() => {
    deposit();
  }, [deposit]);

  useEffect(() => {
    if (
      isNormalPositive(inputAmount, true) &&
      new BN(inputAmount).gt(balance)
    ) {
      setInputAmount(balance);
    }
  }, [balance, inputAmount]);
  useEffect(() => {
    initialize();
  }, [initialize, open]);

  return (
    <Root open={open} onClose={handleClose}>
      <DialogCloseBtn onClick={handleCloseClick} />
      <Container>
        <Header>
          <Title>{t('lpDeposit.title')}</Title>
        </Header>
        <Description>{t('lpDeposit.description')}</Description>
        <InputCard>
          <InputCardHeader>
            {lpRewardPoolData ? (
              <TokenPair size={24} tokens={lpRewardPoolData.tokens} />
            ) : (
              ''
            )}
          </InputCardHeader>
          <InputCardLabel>
            <div>
              {t('lpDeposit.balanceLabel')}:{' '}
              {formatAuto(lpRewardPoolData?.balance ?? 0, 'token')} LP
            </div>
            <span>
              {balanceInCurrency
                ? `≈ ${commarize(balanceInCurrency, undefined, true)}`
                : ''}
            </span>
          </InputCardLabel>
          <InputCardInputContainer>
            <InputCardInput
              placeholder={t('lpDeposit.placeholder')}
              value={inputAmount}
              disabled={isInputDisabled}
              onChange={handleNumberInputChange(handleValueChange)}
            />
            <Collapse in={!!inputAmountInCurrency}>
              <InputCardInputLabel>
                {inputAmountInCurrency
                  ? `≈ ${commarize(inputAmountInCurrency, undefined, true)}`
                  : ''}
              </InputCardInputLabel>
            </Collapse>
          </InputCardInputContainer>
          <InputCardRatio>
            {BALANCE_INPUT_RATIO.map((ratio) => (
              <InputCardRatioBtn
                disabled={isRatioButtonDisabled || isInputDisabled}
                onClick={handleRatioClick(ratio)}
                key={ratio}>
                {ratio}%
              </InputCardRatioBtn>
            ))}
          </InputCardRatio>
        </InputCard>
        <Footer>
          <Collapse
            orientation="horizontal"
            in={isNormalPositive(inputAmount, true) && !isApproved}>
            <ApproveContainer>
              <ActionWrapper>
                <ActionBtn
                  disabled={!isApproved && isLoading}
                  onClick={handleApproveClick}>
                  {!isApproved && isLoading ? <Loading /> : t('common:approve')}
                </ActionBtn>
              </ActionWrapper>
              <IconArrowAlt isReverse size={16} />
            </ApproveContainer>
          </Collapse>
          <ActionWrapper>
            <ActionBtn
              disabled={!isApproved || (isApproved && isLoading)}
              onClick={handleDepositClick}>
              {isApproved && isLoading ? <Loading /> : t('lpDeposit.action')}
            </ActionBtn>
          </ActionWrapper>
        </Footer>
      </Container>
    </Root>
  );
};

export default DialogLpDeposit;
