import { Wallet, useWallet } from '@bifrost-platform/bifront-sdk-react-wallet';

export const getIsCorrectChain = (wallet?: Wallet, targetChainId?: number) =>
  wallet?.getChainId() === targetChainId;

const useIsCorrectChain = (targetChainId?: number) => {
  const { wallet } = useWallet();

  return getIsCorrectChain(wallet, targetChainId);
};

export default useIsCorrectChain;
