import border from './border';
import boxShadow from './boxShadow';
import color from './color';
import transition from './transition';
import transitionSet from './transitionSet';

const radius = {
  sm: '8px',
  md: '16px',
  lg: '24px',
};

const fontSize = {
  xs: '12px',
  sm: '14px',
  base: '16px',
  md: '20px',
  lg: '24px',
};

const gap = {
  xs: '4px',
  sm: '8px',
  md: '12px',
  lg: '16px',
  xl: '24px',
};

const theme = {
  color,
  radius,
  fontSize,
  border,
  gap,
  boxShadow,
  transition,
  transitionSet,
};

export type Theme = typeof theme;

export default theme;
