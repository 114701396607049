import { isNormalPositive } from '@bifrost-platform/bifront-sdk-react-wallet';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import { ActionBtn } from 'components/styled/btn';
import Root, {
  Props as DialogProps,
  DialogBody,
  DialogCloseBtn,
  DialogContainer,
  DialogHeader,
  DialogTitle,
} from '.';

export type Props = DialogProps & {
  width?: number;
  title?: React.ReactNode;
  content?: React.ReactNode;
  subconfirmContents?: React.ReactNode;
  // eslint-disable-next-line no-unused-vars
  confirmProps?: { [key in string]: any };
};

const Container = styled(DialogContainer)<{ width?: number }>`
  gap: 24px;
  ${({ width }) => (isNormalPositive(width, true) ? `width: ${width}px;` : '')}
  min-width: 360px;
`;
const Header = styled(DialogHeader)`
  justify-content: center;
  padding: 8px 32px;
`;
const Title = styled(DialogTitle)`
  gap: 4px;
  padding: 4px 0px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const ConfirmContainer = styled.div`
  display: flex;
  gap: 16px;
`;
const ConfirmWrapper = styled.div`
  flex-grow: 1;
`;

const DialogConfirm: React.FC<Props> = ({
  open,
  width,
  title,
  content,
  subconfirmContents,
  confirmProps,
  onClose: handleClose,
}) => {
  const { t } = useTranslation('dialog');

  const handleCloseClick = useCallback(
    () => handleClose?.({}, 'closeClick'),
    [handleClose]
  );

  return (
    <Root open={open} onClose={handleClose}>
      <DialogCloseBtn onClick={handleCloseClick} />
      <Container width={width}>
        <Header>
          <Title>{title}</Title>
        </Header>
        <DialogBody>
          <ContentContainer>{content}</ContentContainer>
        </DialogBody>
        <ConfirmContainer>
          {subconfirmContents}
          <ConfirmWrapper>
            <ActionBtn {...confirmProps}>
              {confirmProps?.['children'] ?? t('common:confirm')}
            </ActionBtn>
          </ConfirmWrapper>
        </ConfirmContainer>
      </Container>
    </Root>
  );
};

export default DialogConfirm;
