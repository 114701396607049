import styled from '@emotion/styled';

export type Props = {};

const Card = styled.div<Props>`
  display: flex;
  flex-direction: column;
  width: 414px;
  padding: 16px;
  border-radius: 24px;
  gap: 12px;
  background-color: ${({ theme }) => theme.color.background};
  box-shadow: 0px 0px 16px 0px #00000029;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0px;
  gap: 8px;
`;
export const CardHeaderTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 32px;
`;
export const CardHeaderTitle = styled.div`
  padding: 4px 0px;
  font-size: 20px;
  font-weight: 700;
`;
export const CardHeaderActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ theme }) => theme.color.label};
  font-size: 12px;
  text-align: right;
`;
export const CardHeaderDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  min-height: 24px;
  font-size: 12px;
  & > div {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  & span {
    color: ${({ theme }) => theme.color.point};
  }
`;

export const InnerCard = styled(Card)`
  gap: 16px;
  width: unset;
  background-color: #f1efe8;
  box-shadow: unset;
`;
export const InnerCardTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  font-weight: 700;
  & span {
    font-size: 12px;
  }
`;

export default Card;
