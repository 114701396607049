import {
  formatCommas,
  getStorageItem,
  setStorageItem,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import BN from 'bignumber.js';
import { useAtom } from 'jotai';
import { useEffect, useCallback, useMemo } from 'react';
import CURRENCIES from 'configs/currencies';
import useAssetInfo from 'hooks/api/biholder/useAssetInfo';
import { currencyAtom } from 'store/ui';
import { CurrencyId } from 'types/Currency';

const STORAGE_ID_CURRENCY = 'currency';

const useCurrency = () => {
  const [currency, setCurrency] = useAtom(currencyAtom);

  const { usdToKrwExchangeRete } = useAssetInfo();

  const currencySymbol = useMemo(
    () => (currency === 'krw' ? '₩' : '$'),
    [currency]
  );

  const changeCurrency = useCallback(
    (newCurrency: CurrencyId) => {
      if (!CURRENCIES.find((currency) => currency.id === newCurrency)) {
        return;
      }

      setStorageItem(STORAGE_ID_CURRENCY, newCurrency);
      setCurrency(newCurrency);
    },
    [setCurrency]
  );

  const usdToKrwExchange = useCallback(
    (amountInUsd?: number) => {
      if (!amountInUsd) {
        return 0;
      }

      if (currency === 'usd') {
        return amountInUsd;
      }

      return new BN(amountInUsd).multipliedBy(usdToKrwExchangeRete).toNumber();
    },
    [currency, usdToKrwExchangeRete]
  );

  const commarizeInSwap = useCallback(
    (amount?: number | string | BN, isWithSymbol?: boolean) => {
      if (amount === undefined) {
        return '-';
      }

      const compareAmount = new BN(amount);
      const decimals = currency === 'krw' ? 0 : 2;

      let result = formatCommas(amount, decimals);

      if (currency === 'krw') {
        if (compareAmount.gte(1e12)) {
          result = `${formatCommas(compareAmount.div(1e12), decimals)}조`;
        } else if (compareAmount.gte(1e8)) {
          result = `${formatCommas(compareAmount.div(1e8), decimals)}억`;
        }
      } else if (currency === 'usd') {
        if (compareAmount.gte(1e12)) {
          result = `${formatCommas(compareAmount.div(1e12), decimals)}B`;
        } else if (compareAmount.gte(1e8)) {
          result = `${formatCommas(compareAmount.div(1e8), decimals)}M`;
        }
      }

      if (isWithSymbol) {
        result = `${currencySymbol}${result}`;
      }

      return result;
    },
    [currency, currencySymbol]
  );

  const commarize = useCallback(
    (
      amount?: number | string | BN,
      digits?: number,
      isWithSymbol?: boolean
    ) => {
      if (amount === undefined) {
        return '-';
      }

      digits = digits === undefined ? (currency === 'krw' ? 0 : 2) : digits;

      const compareAmount = new BN(amount).toNumber();

      let result =
        compareAmount?.toLocaleString('en-US', {
          maximumFractionDigits: digits,
        }) ?? '-';

      if (currency === 'krw' && compareAmount >= 1e6) {
        if (compareAmount >= 1e12) {
          result = `${formatCommas(compareAmount / 1e12)}조`;
        } else if (compareAmount >= 1e8) {
          result = `${formatCommas(compareAmount / 1e8)}억`;
        } else if (compareAmount >= 1e7) {
          result = `${formatCommas(compareAmount / 1e7)}천만`;
        } else {
          result = `${formatCommas(compareAmount / 1e6)}백만`;
        }
      } else if (currency === 'usd' && compareAmount >= 1e3) {
        // Alter numbers larger than 1k
        const units = ['K', 'M', 'B'];
        const order = Math.floor(Math.log(compareAmount) / Math.log(1000));
        const unitname = units[order - 1];
        const num = compareAmount / 1000 ** order;

        // output number remainder + unitname
        result = `${formatCommas(num)}${unitname ?? ''}`;
      }

      if (isWithSymbol) {
        result = `${currencySymbol}${result}`;
      }

      // return formatted original number
      return result;
    },
    [currency, currencySymbol]
  );

  useEffect(() => {
    const storageCurrency =
      getStorageItem<CurrencyId>(STORAGE_ID_CURRENCY) ?? 'usd';

    if (CURRENCIES.find((currency) => currency.id === storageCurrency)) {
      setCurrency(storageCurrency);
    }
  }, [setCurrency]);

  return {
    currency,
    currencySymbol,
    usdToKrwExchange,
    changeCurrency,
    commarize,
    commarizeInSwap,
  };
};

export default useCurrency;
