import { useEffect, useState } from 'react';

const useIsMounted = (isShow: boolean, animationDelay: number = 150) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (!isShow) {
      setTimeout(() => {
        setIsMounted(false);
      }, animationDelay);
    } else {
      setIsMounted(true);
    }
  }, [isShow, animationDelay]);

  return isMounted;
};

export default useIsMounted;
