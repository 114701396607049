import { ICON_DEFAULT_COLOR, ICON_DEFAULT_SIZE } from 'configs/icons';
import IconProps from 'types/IconProps';

export const IconRefresh: React.FC<IconProps> = ({
  size = ICON_DEFAULT_SIZE,
  color = ICON_DEFAULT_COLOR,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M12 22C10.75 22 9.57917 21.769 8.4875 21.307C7.39583 20.845 6.44583 20.2209 5.6375 19.4347C4.82917 18.6485 4.1875 17.7245 3.7125 16.6627C3.2375 15.601 3 14.4622 3 13.2464C3 12.9708 3.09583 12.7398 3.2875 12.5534C3.47917 12.367 3.71667 12.2738 4 12.2738C4.28333 12.2738 4.52083 12.367 4.7125 12.5534C4.90417 12.7398 5 12.9708 5 13.2464C5 15.143 5.67917 16.7519 7.0375 18.073C8.39583 19.3942 10.05 20.0548 12 20.0548C13.95 20.0548 15.6042 19.3942 16.9625 18.073C18.3208 16.7519 19 15.143 19 13.2464C19 11.3498 18.3208 9.74093 16.9625 8.41978C15.6042 7.09864 13.95 6.43807 12 6.43807H11.85L12.7 7.2648C12.9 7.45932 12.9958 7.68627 12.9875 7.94563C12.9792 8.205 12.8833 8.43194 12.7 8.62646C12.5 8.82099 12.2625 8.9223 11.9875 8.93041C11.7125 8.93851 11.475 8.8453 11.275 8.65078L8.7 6.14628C8.5 5.95176 8.4 5.72481 8.4 5.46545C8.4 5.20608 8.5 4.97914 8.7 4.78461L11.275 2.28011C11.475 2.08559 11.7125 1.99238 11.9875 2.00049C12.2625 2.00859 12.5 2.10991 12.7 2.30443C12.8833 2.49895 12.9792 2.7259 12.9875 2.98526C12.9958 3.24463 12.9 3.47158 12.7 3.6661L11.85 4.49283H12C13.25 4.49283 14.4208 4.72382 15.5125 5.18582C16.6042 5.64781 17.5542 6.27191 18.3625 7.05811C19.1708 7.84432 19.8125 8.76831 20.2875 9.83008C20.7625 10.8919 21 12.0306 21 13.2464C21 14.4622 20.7625 15.601 20.2875 16.6627C19.8125 17.7245 19.1708 18.6485 18.3625 19.4347C17.5542 20.2209 16.6042 20.845 15.5125 21.307C14.4208 21.769 13.25 22 12 22Z"
        fill={color}
      />
    </g>
  </svg>
);

export const IconClose: React.FC<IconProps> = ({
  size = ICON_DEFAULT_SIZE,
  color = ICON_DEFAULT_COLOR,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M12 13.7034L6.03802 19.6654C5.81496 19.8885 5.53105 20 5.18631 20C4.84157 20 4.55767 19.8885 4.3346 19.6654C4.11153 19.4423 4 19.1584 4 18.8137C4 18.4689 4.11153 18.185 4.3346 17.962L10.2966 12L4.3346 6.03802C4.11153 5.81496 4 5.53105 4 5.18631C4 4.84157 4.11153 4.55767 4.3346 4.3346C4.55767 4.11153 4.84157 4 5.18631 4C5.53105 4 5.81496 4.11153 6.03802 4.3346L12 10.2966L17.962 4.3346C18.185 4.11153 18.4689 4 18.8137 4C19.1584 4 19.4423 4.11153 19.6654 4.3346C19.8885 4.55767 20 4.84157 20 5.18631C20 5.53105 19.8885 5.81496 19.6654 6.03802L13.7034 12L19.6654 17.962C19.8885 18.185 20 18.4689 20 18.8137C20 19.1584 19.8885 19.4423 19.6654 19.6654C19.4423 19.8885 19.1584 20 18.8137 20C18.4689 20 18.185 19.8885 17.962 19.6654L12 13.7034Z"
        fill={color}
      />
    </g>
  </svg>
);

export const IconChange: React.FC<IconProps> = ({
  size = ICON_DEFAULT_SIZE,
  color = ICON_DEFAULT_COLOR,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9779 2.60938C10.5656 2.60764 9.17113 2.92449 7.89823 3.53632C6.62532 4.14816 5.50682 5.03923 4.62598 6.1432C4.28153 6.5749 4.35226 7.2041 4.78397 7.54856C5.21568 7.89301 5.84488 7.82227 6.18933 7.39057C6.88257 6.52172 7.76286 5.82043 8.76466 5.3389C9.76646 4.85738 10.864 4.60801 11.9755 4.60938L11.9767 4.60938C16.0653 4.60938 19.3767 7.91662 19.3767 11.9902V13.387C19.3767 13.9393 19.8244 14.387 20.3767 14.387C20.929 14.387 21.3767 13.9393 21.3767 13.387V11.9902C21.3767 6.80817 17.1663 2.61005 11.9779 2.60938ZM11.9779 2.60938L11.9767 2.60938V3.60938L11.9779 2.60938ZM2.60628 12.3079L2.20711 12.7071C1.81658 13.0976 1.18342 13.0976 0.792893 12.7071C0.402369 12.3166 0.402369 11.6834 0.792893 11.2929L2.85539 9.23039C3.2398 8.84599 3.86087 8.83909 4.25372 9.21486L6.40997 11.2774C6.80908 11.6591 6.82314 12.2921 6.44139 12.6912C6.05964 13.0903 5.42663 13.1044 5.02753 12.7226L4.60812 12.3215C4.77277 16.2543 8.01624 19.3902 12.0008 19.3906C13.1101 19.3889 14.2051 19.1398 15.2059 18.6614C16.207 18.1829 17.0887 17.487 17.7868 16.6246C18.1342 16.1953 18.7639 16.129 19.1932 16.4765C19.6225 16.8239 19.6888 17.4536 19.3413 17.8829C18.4561 18.9766 17.338 19.859 16.0685 20.4658C14.799 21.0727 13.4101 21.3886 12.003 21.3906L12.0016 21.3906C6.91128 21.3906 2.76506 17.356 2.60628 12.3079Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5586 11.3088C17.9404 10.9097 18.5734 10.8956 18.9725 11.2774L20.422 12.6638L21.7929 11.2929C22.1834 10.9024 22.8166 10.9024 23.2071 11.2929C23.5976 11.6834 23.5976 12.3166 23.2071 12.7071L21.1446 14.7696C20.7602 15.154 20.1391 15.1609 19.7463 14.7851L17.59 12.7226C17.1909 12.3409 17.1769 11.7079 17.5586 11.3088Z"
        fill={color}
      />
    </g>
  </svg>
);

export const IconExternalLink: React.FC<IconProps> = ({
  size = ICON_DEFAULT_SIZE,
  color = ICON_DEFAULT_COLOR,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 12.4L9.4 16C9.21667 16.1833 8.98333 16.275 8.7 16.275C8.41667 16.275 8.18333 16.1833 8 16C7.81667 15.8167 7.725 15.5833 7.725 15.3C7.725 15.0167 7.81667 14.7833 8 14.6L11.6 11H9C8.71667 11 8.47917 10.9042 8.2875 10.7125C8.09583 10.5208 8 10.2833 8 10C8 9.71667 8.09583 9.47917 8.2875 9.2875C8.47917 9.09583 8.71667 9 9 9H14C14.2833 9 14.5208 9.09583 14.7125 9.2875C14.9042 9.47917 15 9.71667 15 10V15C15 15.2833 14.9042 15.5208 14.7125 15.7125C14.5208 15.9042 14.2833 16 14 16C13.7167 16 13.4792 15.9042 13.2875 15.7125C13.0958 15.5208 13 15.2833 13 15V12.4ZM13 5C12.7167 5 12.4792 4.90417 12.2875 4.7125C12.0958 4.52083 12 4.28333 12 4C12 3.71667 12.0958 3.47917 12.2875 3.2875C12.4792 3.09583 12.7167 3 13 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V11C21 11.2833 20.9042 11.5208 20.7125 11.7125C20.5208 11.9042 20.2833 12 20 12C19.7167 12 19.4792 11.9042 19.2875 11.7125C19.0958 11.5208 19 11.2833 19 11V5H13ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V4C3 3.71667 3.09583 3.47917 3.2875 3.2875C3.47917 3.09583 3.71667 3 4 3C4.28333 3 4.52083 3.09583 4.7125 3.2875C4.90417 3.47917 5 3.71667 5 4V19H20C20.2833 19 20.5208 19.0958 20.7125 19.2875C20.9042 19.4792 21 19.7167 21 20C21 20.2833 20.9042 20.5208 20.7125 20.7125C20.5208 20.9042 20.2833 21 20 21H5Z"
      fill={color}
    />
  </svg>
);

export const IconSearch: React.FC<IconProps> = ({
  size = ICON_DEFAULT_SIZE,
  color = ICON_DEFAULT_COLOR,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 10.5C15 12.9853 12.9853 15 10.5 15C8.01472 15 6 12.9853 6 10.5C6 8.01472 8.01472 6 10.5 6C12.9853 6 15 8.01472 15 10.5ZM13.6863 16.1668C12.7448 16.6973 11.6577 17 10.5 17C6.91015 17 4 14.0899 4 10.5C4 6.91015 6.91015 4 10.5 4C14.0899 4 17 6.91015 17 10.5C17 12.1021 16.4203 13.5689 15.4593 14.702L18.8044 18.0471C19.2515 18.4942 19.2515 19.219 18.8044 19.666C18.3574 20.1131 17.6326 20.1131 17.1855 19.666L13.6863 16.1668Z"
        fill={color}
      />
    </g>
  </svg>
);

export const IconCopy: React.FC<IconProps> = ({
  size = ICON_DEFAULT_SIZE,
  color = ICON_DEFAULT_COLOR,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.4444 4H11.1111C9.15556 4 7.55556 5.6 7.55556 7.55556C5.6 7.55556 4 9.15556 4 11.1111V16.4444C4 18.4 5.6 20 7.55556 20H12.8889C14.8444 20 16.4444 18.4 16.4444 16.4444C18.4 16.4444 20 14.8444 20 12.8889V7.55556C20 5.6 18.4 4 16.4444 4ZM14.6667 16.4444C14.6667 17.4222 13.8667 18.2222 12.8889 18.2222H7.55556C6.57778 18.2222 5.77778 17.4222 5.77778 16.4444V11.1111C5.77778 10.1333 6.57778 9.33333 7.55556 9.33333H8.44444H12.8889C13.8667 9.33333 14.6667 10.1333 14.6667 11.1111V15.5556V16.4444ZM18.2222 12.8889C18.2222 13.8667 17.4222 14.6667 16.4444 14.6667V11.1111C16.4444 9.15556 14.8444 7.55556 12.8889 7.55556H9.33333C9.33333 6.57778 10.1333 5.77778 11.1111 5.77778H16.4444C17.4222 5.77778 18.2222 6.57778 18.2222 7.55556V12.8889Z"
      fill={color}
    />
  </svg>
);

export const IconDelete: React.FC<IconProps> = ({
  size = ICON_DEFAULT_SIZE,
  color = ICON_DEFAULT_COLOR,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M7 21C6.45 21 5.97917 20.8042 5.5875 20.4125C5.19583 20.0208 5 19.55 5 19V6C4.71667 6 4.47917 5.90417 4.2875 5.7125C4.09583 5.52083 4 5.28333 4 5C4 4.71667 4.09583 4.47917 4.2875 4.2875C4.47917 4.09583 4.71667 4 5 4H9C9 3.71667 9.09583 3.47917 9.2875 3.2875C9.47917 3.09583 9.71667 3 10 3H14C14.2833 3 14.5208 3.09583 14.7125 3.2875C14.9042 3.47917 15 3.71667 15 4H19C19.2833 4 19.5208 4.09583 19.7125 4.2875C19.9042 4.47917 20 4.71667 20 5C20 5.28333 19.9042 5.52083 19.7125 5.7125C19.5208 5.90417 19.2833 6 19 6V19C19 19.55 18.8042 20.0208 18.4125 20.4125C18.0208 20.8042 17.55 21 17 21H7ZM9 16C9 16.2833 9.09583 16.5208 9.2875 16.7125C9.47917 16.9042 9.71667 17 10 17C10.2833 17 10.5208 16.9042 10.7125 16.7125C10.9042 16.5208 11 16.2833 11 16V9C11 8.71667 10.9042 8.47917 10.7125 8.2875C10.5208 8.09583 10.2833 8 10 8C9.71667 8 9.47917 8.09583 9.2875 8.2875C9.09583 8.47917 9 8.71667 9 9V16ZM13 16C13 16.2833 13.0958 16.5208 13.2875 16.7125C13.4792 16.9042 13.7167 17 14 17C14.2833 17 14.5208 16.9042 14.7125 16.7125C14.9042 16.5208 15 16.2833 15 16V9C15 8.71667 14.9042 8.47917 14.7125 8.2875C14.5208 8.09583 14.2833 8 14 8C13.7167 8 13.4792 8.09583 13.2875 8.2875C13.0958 8.47917 13 8.71667 13 9V16Z"
        fill={color}
      />
    </g>
  </svg>
);

export const IconAdd: React.FC<IconProps> = ({
  size = ICON_DEFAULT_SIZE,
  color = ICON_DEFAULT_COLOR,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M11 13V16C11 16.2833 11.0958 16.5208 11.2875 16.7125C11.4792 16.9042 11.7167 17 12 17C12.2833 17 12.5208 16.9042 12.7125 16.7125C12.9042 16.5208 13 16.2833 13 16V13H16C16.2833 13 16.5208 12.9042 16.7125 12.7125C16.9042 12.5208 17 12.2833 17 12C17 11.7167 16.9042 11.4792 16.7125 11.2875C16.5208 11.0958 16.2833 11 16 11H13V8C13 7.71667 12.9042 7.47917 12.7125 7.2875C12.5208 7.09583 12.2833 7 12 7C11.7167 7 11.4792 7.09583 11.2875 7.2875C11.0958 7.47917 11 7.71667 11 8V11H8C7.71667 11 7.47917 11.0958 7.2875 11.2875C7.09583 11.4792 7 11.7167 7 12C7 12.2833 7.09583 12.5208 7.2875 12.7125C7.47917 12.9042 7.71667 13 8 13H11ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22Z"
        fill={color}
      />
    </g>
  </svg>
);
