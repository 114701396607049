import styled from '@emotion/styled';
import Gap from 'types/Gap';

export const Column = styled.div<{ gap?: Gap }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: ${({ gap, theme }) => gap && theme.gap[gap]};
`;

export const ColumnCenter = styled(Column)`
  align-items: center;
`;

export const RootColumn = styled(Column)<{ isLarge?: boolean }>`
  max-width: ${({ isLarge }) => (isLarge ? 1168 : 758)}px;
  width: 100%;
  margin: 120px auto 184px auto;
`;

export default Column;
