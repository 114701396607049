import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import UnknownToken from 'types/UnknownToken';
import { loadUnknownTokens, saveUnknownTokens } from 'utils/unknownToken';

export type FunctionAddUnknownToken = (
  unknownToken: UnknownToken
) => Promise<boolean>;
export type Props = {
  unknownTokens: UnknownToken[];
  addUnknownToken?: FunctionAddUnknownToken;
};

export const Context = createContext<Props>({
  unknownTokens: [],
});

export const UnknownTokensProvider = ({ children }: PropsWithChildren) => {
  const [unknownTokens, setUnknownTokens] = useState<UnknownToken[]>([]);

  const addUnknownToken: FunctionAddUnknownToken = useCallback(async (data) => {
    let result = false;

    try {
      setUnknownTokens((value) => [...value, data]);

      result = true;
    } catch (error) {}

    return result;
  }, []);

  useEffect(() => {
    let unknownTokens: UnknownToken[] = [];

    try {
      unknownTokens = loadUnknownTokens();
    } catch (error) {}

    setUnknownTokens(unknownTokens);
  }, []);
  useEffect(() => {
    if (unknownTokens.length) {
      try {
        saveUnknownTokens(unknownTokens);
      } catch (error) {}
    }
  }, [unknownTokens]);

  return (
    <Context.Provider
      value={{
        unknownTokens,
        addUnknownToken,
      }}>
      {children}
    </Context.Provider>
  );
};

const useUnknownTokens = (): Props => useContext(Context);

export default useUnknownTokens;
