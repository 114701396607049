import { useRouter } from 'next/router';
import { useMemo } from 'react';

const useIsMaintaining = () => {
  const { asPath } = useRouter();

  const isMaintaining = useMemo(() => asPath === '/maintain', [asPath]);

  return isMaintaining;
};

export default useIsMaintaining;
