import { atom } from 'jotai';
import Chainrunner from 'lib/chainrunner';
import Browser from 'types/Browser';
import DataWithEmpty from 'types/DataWithEmpty';
import TransactionHistory from 'types/TransactionHistory';

// device
const browserAtom = atom<Browser>('others');
browserAtom.onMount = (setAtom) => {
  if (!navigator) return;

  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.includes('chrome')) setAtom('chrome');
  else if (userAgent.includes('firefox')) setAtom('firefox');
};

const isAndroidAtom = atom(false);
isAndroidAtom.onMount = (setAtom) => {
  if (!navigator) return;

  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.includes('android')) setAtom(true);
};

const isIosAtom = atom(false);
isIosAtom.onMount = (setAtom) => {
  if (!navigator) return;

  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.includes('iphone')) setAtom(true);
};

const isMobileBrowserAtom = atom((get) => get(isAndroidAtom) || get(isIosAtom));

// transaction
const transactionHistoriesAtom = atom<TransactionHistory[]>([]);

// chainrunner sdk
const chainrunnerAtom = atom<DataWithEmpty<Chainrunner>>(undefined);

// ip
const ipAtom = atom('');

// old balance
const refreshBalanceState = atom(0);

export {
  transactionHistoriesAtom,
  chainrunnerAtom,
  ipAtom,
  refreshBalanceState,
  browserAtom,
  isAndroidAtom,
  isIosAtom,
  isMobileBrowserAtom,
};
