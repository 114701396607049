import styled from '@emotion/styled';
import { Dialog, DialogProps as MuiDialogProps } from '@mui/material';
import { IconArrow, IconClose } from 'components/icons';
import Grow from 'components/mui/Grow';
import { Props as BtnProps, IconBtn } from 'components/styled/btn';

export type Props = MuiDialogProps & {
  onClose?: (
    event?: {},
    reason?: 'backdropClick' | 'escapeKeyDown' | 'closeClick' | 'flowFinish'
  ) => void;
};

const DialogRoot = styled((props: Props) => (
  <Dialog {...props} TransitionComponent={Grow} scroll="body" />
))`
  & .MuiPaper-root {
    max-width: unset;
    background-color: #fdfdf7;
    overflow-x: hidden;
    ${({ fullScreen, theme }) =>
      fullScreen ? '' : `border-radius: ${theme.radius.lg};`}
  }
`;

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  color: ${({ theme }) => theme.color.text};
`;
export const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;
  color: ${({ theme }) => theme.color.text};
`;
export const DialogTitle = styled.div`
  display: flex;
  align-items: center;
  min-height: 24px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: ${({ theme }) => theme.color.text};
`;
export const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: ${({ theme }) => theme.color.text};
`;

const DialogPreviousBtnRoot = styled.div`
  position: absolute;
  top: 24px;
  left: 16px;
`;
const DialogCloseBtnRoot = styled.div`
  position: absolute;
  top: 24px;
  right: 16px;
`;

export const DialogPreviousBtn: React.FC<
  BtnProps & { onClick?: React.MouseEventHandler<HTMLAnchorElement> }
> = ({ disabled, onClick: handleClick }) => (
  <DialogPreviousBtnRoot>
    <IconBtn disabled={disabled} size={32} onClick={handleClick}>
      <IconArrow />
    </IconBtn>
  </DialogPreviousBtnRoot>
);

export const DialogCloseBtn: React.FC<
  BtnProps & { onClick?: React.MouseEventHandler<HTMLAnchorElement> }
> = ({ disabled, onClick: handleClick }) => (
  <DialogCloseBtnRoot>
    <IconBtn disabled={disabled} size={32} onClick={handleClick}>
      <IconClose />
    </IconBtn>
  </DialogCloseBtnRoot>
);

export default DialogRoot;
