import {
  getStorageItem,
  setStorageItem,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import { useCallback, useEffect, useMemo, useState } from 'react';
import NOTICES from 'configs/notices';
import DataWithEmpty from 'types/DataWithEmpty';
import Notice from 'types/Notice';

const STORAGE_ID_NOTICE = 'closedNotices';
const CLOSED_NOTICES = getStorageItem<string[]>(STORAGE_ID_NOTICE) ?? [];

const useNotice = () => {
  const [closedNotices, setClosedNotices] = useState<string[]>(CLOSED_NOTICES);

  const activeNotices = useMemo(() => {
    const now = Date.now();

    return NOTICES.filter(
      (notice) =>
        (notice.startAt === undefined || notice.startAt <= now) &&
        (notice.finishAt === undefined || notice.finishAt >= now)
    );
  }, []);
  const unclosedNotices = useMemo(
    () => activeNotices.filter((notice) => !closedNotices.includes(notice.id)),
    [activeNotices, closedNotices]
  );
  const notice: DataWithEmpty<Notice> = useMemo(
    () => unclosedNotices[0],
    [unclosedNotices]
  );

  const closeNotice = useCallback(
    (id: string) => {
      const newData = [...closedNotices, id].filter(
        (data, index, array) => array.indexOf(data) === index
      );

      setStorageItem(STORAGE_ID_NOTICE, newData);
      setClosedNotices(newData);
    },
    [closedNotices]
  );

  useEffect(() => {
    const closedNotices = getStorageItem<string[]>(STORAGE_ID_NOTICE);

    setClosedNotices(closedNotices ?? []);
  }, []);

  return { notice, closeNotice };
};

export default useNotice;
