import {
  useWallet,
  address2shorted,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import { useAtom } from 'jotai';
import WalletTypeIcon from 'components/WalletTypeIcon';
import PopupWallet from 'components/popup/PopupWallet';
import HeaderDropdownWithExpand from 'components/styled/btn/dropdown/HeaderDropdownWithExpand';
import { isShowWalletAtom } from 'store/ui';

const DropdownWallet: React.FC = () => {
  const { account: address } = useWallet();

  const [isShowWallet, setShowWallet] = useAtom(isShowWalletAtom);

  return (
    <HeaderDropdownWithExpand
      popup={<PopupWallet />}
      isOpen={isShowWallet}
      setIsOpen={setShowWallet}>
      <WalletTypeIcon />
      <div style={{ marginLeft: '6px' }}>{address2shorted(address ?? '')}</div>
    </HeaderDropdownWithExpand>
  );
};

export default DropdownWallet;
