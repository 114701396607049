import {
  useWallet,
  compareLowerStr,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import Image from 'next/image';
import { useMemo } from 'react';
import { ICON_DEFAULT_SIZE } from 'configs/icons';
import PROVIDERS from 'configs/providers';

export type Props = {
  size?: number;
};

const WalletTypeIcon: React.FC<Props> = ({ size = ICON_DEFAULT_SIZE }) => {
  const { providerType, providerRdns, providerInfos } = useWallet();

  const providers = useMemo<
    {
      key: string;
      target: any;
      name: any;
      icon?: any;
      rdns?: string;
    }[]
  >(
    () =>
      [
        ...PROVIDERS.map((provider) => ({
          key: provider.type,
          target: provider.type,
          icon: provider.src,
          name: provider.name,
        })),
        ...(providerInfos ?? []).map((providerInfo) => ({
          ...providerInfo.info,
          icon: providerInfo.info.icon?.replace('\n', ''),
          key: providerInfo.uuid,
          target: providerInfo,
        })),
      ].filter(
        (provider, index, array) =>
          array.findIndex((item) =>
            compareLowerStr(item.name, provider.name)
          ) === index
      ),
    [providerInfos]
  );
  const icon = useMemo(
    () =>
      providers.find(({ name, rdns }) =>
        providerRdns
          ? rdns === providerRdns
          : compareLowerStr(name, providerType)
      )?.icon,
    [providers, providerRdns, providerType]
  );

  return <Image src={icon} alt="" width={size} height={size} />;
};

export default WalletTypeIcon;
