import IS_TESTNET from 'lib/devEnv/envIsTestnet';

export const IMAGE_URL_ORIGIN =
  'https://raw.githubusercontent.com/bifrost-platform/AssetInfo/master';

export const IMAGE_PATH_TOKEN = '/Assets';
export const IMAGE_PATH_CHAIN = '/Networks';

export const MAINNET_PATH_CHAIN_BIFROST = '/bifrost';
export const TESTNET_PATH_CHAIN_BIFROST = '/bifrosttest1';
export const PATH_CHAIN_BIFROST = IS_TESTNET
  ? TESTNET_PATH_CHAIN_BIFROST
  : MAINNET_PATH_CHAIN_BIFROST;

export const MAINNET_IMAGE_PATH_CHAIN_BIFROST = `${MAINNET_PATH_CHAIN_BIFROST}/image.png`;
export const TESTNET_IMAGE_PATH_CHAIN_BIFROST = `${TESTNET_PATH_CHAIN_BIFROST}/image.png`;
export const IMAGE_PATH_CHAIN_BIFROST = IS_TESTNET
  ? TESTNET_IMAGE_PATH_CHAIN_BIFROST
  : MAINNET_IMAGE_PATH_CHAIN_BIFROST;

export const MAINNET_IMAGE_PATH_TOKEN_BFC = `${MAINNET_PATH_CHAIN_BIFROST}/coin/coinImage.png`;
export const TESTNET_IMAGE_PATH_TOKEN_BFC = `${TESTNET_PATH_CHAIN_BIFROST}/coin/coinImage.png`;
export const IMAGE_PATH_TOKEN_BFC = IS_TESTNET
  ? TESTNET_IMAGE_PATH_TOKEN_BFC
  : MAINNET_IMAGE_PATH_TOKEN_BFC;

export const MAINNET_IMAGE_PATH_TOKEN_EMPTY = `${MAINNET_PATH_CHAIN_BIFROST}/tokens/images/empty-token.png`;
export const TESTNET_IMAGE_PATH_TOKEN_EMPTY = `${TESTNET_PATH_CHAIN_BIFROST}/tokens/images/empty-token.png`;
export const IMAGE_PATH_TOKEN_EMPTY = IS_TESTNET
  ? TESTNET_IMAGE_PATH_TOKEN_EMPTY
  : MAINNET_IMAGE_PATH_TOKEN_EMPTY;
