import TRANSITION from './transition';

const TRANSITION_SET_HOVER_OPACITY = `
  ${TRANSITION}
  will-change: opacity;
  &:hover {
    opacity: 0.8;
  }
`;

export default TRANSITION_SET_HOVER_OPACITY;
