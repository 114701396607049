import { useAtom } from 'jotai';
import { useTranslation } from 'next-i18next';
import { IconWallet } from 'components/icons';
import PopupConnectWallet from 'components/popup/PopupConnectWallet';
import HeaderDropdownWithExpand from 'components/styled/btn/dropdown/HeaderDropdownWithExpand';
import useIsMaintaining from 'hooks/useIsMaintaining';
import { isShowConnectWalletAtom } from 'store/ui';
import theme from 'styles/theme';

const DropdownConnectWallet: React.FC = () => {
  const { t } = useTranslation('common');

  const isMaintaining = useIsMaintaining();

  const [isShowConnectWallet, setShowConnectWallet] = useAtom(
    isShowConnectWalletAtom
  );

  return (
    <HeaderDropdownWithExpand
      isHideExpand
      isOpen={isShowConnectWallet}
      popup={!isMaintaining && <PopupConnectWallet />}
      setIsOpen={setShowConnectWallet}>
      {t('connectWallet')}
      <IconWallet color={theme.color.point} />
    </HeaderDropdownWithExpand>
  );
};

export default DropdownConnectWallet;
