import boxShadow from 'styles/theme/boxShadow';
import color from 'styles/theme/color';
import TRANSITION from './transition';

const TRANSITION_SET_HOVER_BUTTON = `
  box-shadow: ${boxShadow.enabled};
  cursor: pointer;
  user-select: none;
  ${TRANSITION}
  will-change: box-shadow, border-color;
  &:hover {
    box-shadow: ${boxShadow.hover};
    border-color: ${color.border};
  }
  &:active {
    box-shadow: ${boxShadow.active};
  }
`;

export default TRANSITION_SET_HOVER_BUTTON;
