import { useRouter } from 'next/router';
import { useMemo } from 'react';

const useIsPath = (paths?: string[], isExactly?: boolean): boolean => {
  const { asPath } = useRouter();

  const isPath = useMemo(
    () =>
      !!paths?.find((path) =>
        isExactly ? path === asPath : asPath.indexOf(path) === 0
      ),
    [paths, isExactly, asPath]
  );

  return isPath;
};

export default useIsPath;
