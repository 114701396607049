import { WalletProvider } from '@bifrost-platform/bifront-sdk-react-wallet';
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'styles/globals.css';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/toastify.css';
import 'styles/switch.css';
import 'lib/datadog';
import Layout from 'components/layout';
import JSON_LD_STRUCTURED_DATA from 'configs/seo/jsonLdStructuredData';
import WALLET_CONSTRUCTOR_PROPS from 'configs/walletConstructorProps';
import { AssetInfoProvider } from 'hooks/api/biholder/useAssetInfo';
import { BalancesProvider } from 'hooks/api/biholder/useBalances';
import { ChainImageMapProvider } from 'hooks/api/biholder/useChainImageMap';
import useSyncQueryProperty from 'hooks/queryProperty/useSyncQueryProperty';
import useCheckAvailablePathname from 'hooks/useCheckAvailablePathname';
import { FirestoreProvider } from 'hooks/useFirestore';
import { TransactionHistoriesProvider } from 'hooks/useTransactionHistories';
import { UnknownTokensProvider } from 'hooks/useUnknownTokens';
import { WatchingAddressMapProvider } from 'hooks/useWatchingAddressMap';
import nextI18nextConfig from '../../next-i18next.config';

// theme
const theme = createTheme({});

const App: NextPage<AppProps> = ({ Component, pageProps }) => {
  useCheckAvailablePathname();

  const { sync: syncQueryProperty } = useSyncQueryProperty();

  const { t } = useTranslation('common');

  useEffect(() => {
    syncQueryProperty();
  }, [syncQueryProperty]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Head>
          <title>
            {t('seo.title') ||
              'Everdex - a multichain native DEX that makes trading simple and efficient'}
          </title>

          <meta
            name="Description"
            content={
              t('seo.description') ||
              'Start secure and fast crypto trading with Everdex!'
            }
          />
          <meta
            name="Keywords"
            content={
              t('seo.keywords') ||
              'Everdex, Dex, Blockchain, Defi, Multichain, Swap, Bridge, Pool, Dapp'
            }
          />
          <meta name="theme-color" content="#ff474c" />
          <meta
            property="og:title"
            content={
              t('seo.og.title') ||
              'Everdex - a multichain native DEX that makes trading simple and efficient'
            }
          />
          <meta
            property="og:description"
            content={
              t('seo.og.description') ||
              'Start secure and fast crypto trading with Everdex!'
            }
          />
          <meta
            property="og:site_name"
            content={t('seo.og.siteName') || 'Everdex'}
          />

          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(JSON_LD_STRUCTURED_DATA),
            }}
          />
        </Head>

        <WalletProvider props={WALLET_CONSTRUCTOR_PROPS}>
          <WatchingAddressMapProvider>
            <AssetInfoProvider>
              <BalancesProvider>
                <TransactionHistoriesProvider>
                  <UnknownTokensProvider>
                    <FirestoreProvider>
                      <ChainImageMapProvider>
                        <Layout>
                          <ToastContainer
                            position="bottom-right"
                            theme="dark"
                          />
                          <Component {...pageProps} />
                        </Layout>
                      </ChainImageMapProvider>
                    </FirestoreProvider>
                  </UnknownTokensProvider>
                </TransactionHistoriesProvider>
              </BalancesProvider>
            </AssetInfoProvider>
          </WatchingAddressMapProvider>
        </WalletProvider>
      </ThemeProvider>
    </>
  );
};

export default appWithTranslation(App, nextI18nextConfig);
