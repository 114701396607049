import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { PATH_SWAP } from 'configs/paths';
import useSyncNetworkQueryProperty from './useSyncNetworkQueryProperty';

export const TESTNET_QUERY_KEY = 'testnet';
export const TESTNET_QUERY_VALUES = ['true'];
export const NETWORK_QUERY_KEY = 'network';
export const NETWORK_QUERY_VALUES = ['mainnet', 'testnet'];
export const FROM_ADDRESS_QUERY_KEY = 'from';
export const FROM_CHAIN_QUERY_KEY = 'fromChain';
export const TO_ADDRESS_QUERY_KEY = 'to';
export const TO_CHAIN_QUERY_KEY = 'toChain';

export type TestnetQueryValue = (typeof TESTNET_QUERY_VALUES)[number];
export type NetworkQueryValue = (typeof NETWORK_QUERY_VALUES)[number];

const useSyncQueryProperty = () => {
  const router = useRouter();
  const { pathname, query, locale } = router;

  const syncNetworkQueryProperty = useSyncNetworkQueryProperty();

  const syncSwapPathProperty = useCallback(async () => {
    const fromQuery = query[FROM_ADDRESS_QUERY_KEY];
    const fromChainQuery = query[FROM_CHAIN_QUERY_KEY];
    const toQuery = query[TO_ADDRESS_QUERY_KEY];
    const toChainQuery = query[TO_CHAIN_QUERY_KEY];

    if (fromQuery || fromChainQuery || toQuery || toChainQuery) {
      await router.replace(
        { pathname, query },
        { pathname: PATH_SWAP, query },
        { locale }
      );
    }
  }, [locale, pathname, query, router]);
  const sync = useCallback(async () => {
    await syncSwapPathProperty();
    await syncNetworkQueryProperty();
  }, [syncNetworkQueryProperty, syncSwapPathProperty]);

  return { sync };
};

export default useSyncQueryProperty;
