import {
  getStorageItem,
  setStorageItem,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import { CHAIN_ID_BIFROST } from 'configs/chains/chainIds';
import IS_TESTNET from 'lib/devEnv/envIsTestnet';
import UnknownToken from 'types/UnknownToken';

const STORAGE_ID_UNKNOWN_TOKENS = `unknownTokens${
  IS_TESTNET ? '-testnet' : ''
}`;

const DEFAULT_UNKNOWN_TOKEN: UnknownToken = {
  chainId: CHAIN_ID_BIFROST,
  address: '', // require replace
  name: undefined,
  symbol: '', // require replace
  decimals: 18, // require replace
  color: '',
  isCoin: false,
  balance: undefined, // require replace
  price: undefined,
};

export const getDefaultToken = (address?: string): UnknownToken => ({
  ...DEFAULT_UNKNOWN_TOKEN,
  address: address ?? '',
});

export const loadUnknownTokens = () =>
  getStorageItem<UnknownToken[]>(STORAGE_ID_UNKNOWN_TOKENS) ?? [];

export const saveUnknownTokens = (unknownTokens: UnknownToken[]) =>
  setStorageItem(STORAGE_ID_UNKNOWN_TOKENS, unknownTokens);
