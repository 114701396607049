import IS_TESTNET from 'lib/devEnv/envIsTestnet';
import {
  MAINNET_CHAIN_ID_BIFROST,
  MAINNET_CHAIN_ID_ETHEREUM,
  MAINNET_CHAIN_ID_BSC,
  MAINNET_CHAIN_ID_POLYGON,
  MAINNET_CHAIN_ID_BASE,
} from './mainnet/chainId';
import {
  TESTNET_CHAIN_ID_BIFROST,
  TESTNET_CHAIN_ID_ETHEREUM,
  TESTNET_CHAIN_ID_BSC,
  TESTNET_CHAIN_ID_POLYGON,
  TESTNET_CHAIN_ID_BASE,
} from './testnet/chainId';

export const CHAIN_ID_BIFROST = IS_TESTNET
  ? TESTNET_CHAIN_ID_BIFROST
  : MAINNET_CHAIN_ID_BIFROST;
export const CHAIN_ID_ETHEREUM = IS_TESTNET
  ? TESTNET_CHAIN_ID_ETHEREUM
  : MAINNET_CHAIN_ID_ETHEREUM;
export const CHAIN_ID_BSC = IS_TESTNET
  ? TESTNET_CHAIN_ID_BSC
  : MAINNET_CHAIN_ID_BSC;
export const CHAIN_ID_POLYGON = IS_TESTNET
  ? TESTNET_CHAIN_ID_POLYGON
  : MAINNET_CHAIN_ID_POLYGON;
export const CHAIN_ID_BASE = IS_TESTNET
  ? TESTNET_CHAIN_ID_BASE
  : MAINNET_CHAIN_ID_BASE;

const CHAIN_IDS = [
  CHAIN_ID_BIFROST,
  CHAIN_ID_ETHEREUM,
  CHAIN_ID_BSC,
  CHAIN_ID_POLYGON,
  CHAIN_ID_BASE,
];

export default CHAIN_IDS;
