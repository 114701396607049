import IS_TESTNET from 'lib/devEnv/envIsTestnet';
import LpRewardPool from 'types/LpRewardPool';
import {
  MAINNET_TOKEN_BTCUSD,
  MAINNET_TOKEN_USDC,
} from './tokens/mainnetTokens';
import {
  TESTNET_TOKEN_BTCUSD,
  TESTNET_TOKEN_USDT,
} from './tokens/testnetTokens';

export const MAINNET_LP_REWARD_POOLS: LpRewardPool[] = [
  {
    address: '0x840Cf4522ED96CBBEB0924672Ea170456eea3a4c',
    tokens: [MAINNET_TOKEN_BTCUSD, MAINNET_TOKEN_USDC],
    rewardContract: {
      lpTokenAddress: '0x4E4156F7Aa6970d96C434607aDC7AF6299751Efc',
      proxyAddress: '0x49Ef8EFCB4C1C90C4c3C089B11398b44DC801456',
      stakingStateAddress: '0x289122630B94Bc8Bc0bE01041af5Abe668585A70',
      rewardStateAddresses: ['0x573c42b20059829612C19bF11324FC1225A9833C'],
    },
  },
];
export const TESTNET_LP_REWARD_POOLS: LpRewardPool[] = [
  {
    address: '0x1A70bB0c4d4D3cE4E58C97e4f4F1Fc64686ee26C',
    tokens: [TESTNET_TOKEN_BTCUSD, TESTNET_TOKEN_USDT],
    rewardContract: {
      lpTokenAddress: '0xBB3f7BA5d8549129A6AF00DBf058895cedE76252',
      proxyAddress: '0x333F96742191C8b0723D8EDd3295a92205F80e54',
      stakingStateAddress: '0xD278231C6ad6FeF6134024A4bb0569d38aE00Ba2',
      rewardStateAddresses: ['0x51138cfFa84b0070DEB16264994948B06c8bF6fB'],
    },
  },
];

const LP_REWARD_POOLS = IS_TESTNET
  ? TESTNET_LP_REWARD_POOLS
  : MAINNET_LP_REWARD_POOLS;

export const LP_REWARD_POOL_ADDRESSES = LP_REWARD_POOLS.map(
  (pool) => pool.address
);

export const LP_REWARD_PAIRS: string[][] = LP_REWARD_POOLS.map((pool) =>
  pool.tokens.map((token) => token.symbol)
);

export default LP_REWARD_POOLS;
