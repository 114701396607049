import { FirebaseOptions } from 'firebase/app';
import DataWithEmpty from 'types/DataWithEmpty';

export const FIREBASE_ERROR_CODE_NUMBER = -1;
export const FIREBASE_ERROR_CODE_STRING = -2;
export const FIREBASE_ERROR_CODE_PARSE_FAIL = -3;
export const FIREBASE_ERROR_CODE_UNKNOWN = -4;

export const FIREBASE_ERROR_MESSAGE_PARSE_FAIL = `Error occurred while parsing the user action error: [{0}] {1}`;
export const FIREBASE_ERROR_MESSAGE_UNKNOWN = 'Unknown error';

export const FIREBASE_API_KEY = process.env.NEXT_PUBLIC_FIREBASE_API_KEY ?? '';
export const FIREBASE_AUTH_DOMAIN =
  process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN ?? '';
export const FIREBASE_PROJECT_ID =
  process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID ?? '';
export const FIREBASE_STORAGE_BUCKET =
  process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET ?? '';
export const FIREBASE_MESSAGING_SENDER_ID =
  process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID ?? '';
export const FIREBASE_APP_ID = process.env.NEXT_PUBLIC_FIREBASE_APP_ID ?? '';
export const FIREBASE_MEASUREMENT_ID =
  process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID ?? '';

const FIREBASE_CONFIG: DataWithEmpty<FirebaseOptions> =
  FIREBASE_API_KEY &&
  FIREBASE_AUTH_DOMAIN &&
  FIREBASE_PROJECT_ID &&
  FIREBASE_STORAGE_BUCKET &&
  FIREBASE_MESSAGING_SENDER_ID &&
  FIREBASE_APP_ID &&
  FIREBASE_MEASUREMENT_ID
    ? {
        apiKey: FIREBASE_API_KEY,
        authDomain: FIREBASE_AUTH_DOMAIN,
        projectId: FIREBASE_PROJECT_ID,
        storageBucket: FIREBASE_STORAGE_BUCKET,
        messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
        appId: FIREBASE_APP_ID,
        measurementId: FIREBASE_MEASUREMENT_ID,
      }
    : undefined;

export default FIREBASE_CONFIG;
