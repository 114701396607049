import styled from '@emotion/styled';
import DEFAULT_TOKEN_ICON from 'configs/defaultValue/defaultTokenIcon';
import ImageSrc from 'types/ImageSrc';

export type ContainerProps = {
  isSmall?: boolean;
  size?: number;
  backgroundColor?: string;
};
export type Props = Pick<ContainerProps, 'isSmall'> &
  Pick<ContainerProps, 'size'> & {
    scale?: number;
    image?: ImageSrc;
    symbol?: string;
  };

export const AssetImageContainer = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  ${({ isSmall, size: propsSize, backgroundColor = '#fff' }) => {
    const size = propsSize ? propsSize : isSmall ? 16 : 32;
    const backgroundStyle = backgroundColor
      ? `background-color: ${backgroundColor};`
      : '';

    return `
      width: ${size}px;
      height: ${size}px;
      border-radius: ${size}px;
      ${backgroundStyle}
    `;
  }}
`;
const TokenImage = styled.div<Props>`
  overflow: hidden;
  ${({ theme, isSmall, size: propsSize, scale, image, symbol }) => {
    const isImageEmpty: boolean = !!(!image || image === DEFAULT_TOKEN_ICON);
    const size = propsSize ? propsSize : isSmall ? 16 : 32;
    const scaleStyle: string = scale ? `transform: scale(${scale});` : '';
    const imageStyle: string = isImageEmpty
      ? `background-color: ${theme.color.box};` // background-image: url(${ImageTokenDefault.src});
      : typeof image === 'string'
        ? `background-image: url(${image});`
        : `background-image: url(${image?.src});`;
    const symbolStyle = isImageEmpty
      ? `
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        text-align: center;
        color: ${theme.color.black8};
        text-wrap: nowrap;
        &:before {
          content: '${(symbol || '').slice(0, 4)}';
          display: block;
        }
      `
      : '';

    return `
      min-width: ${size}px;
      min-height: ${size}px;
      max-width: ${size}px;
      max-height: ${size}px;
      width: ${size}px;
      height: ${size}px;
      border-radius: ${size}px;
      background-size: ${size}px;
      ${imageStyle}
      ${scaleStyle}
      ${symbolStyle}
    `;
  }}
`;

export const MoreChainImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
  width: 24px;
  height: 24px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.greyAA};
  text-wrap: nowrap;
  overflow: hidden;
  border-radius: 24px;
`;
export const ChainImage = (props: Props) => <TokenImage {...props} />;
export const ChainBadgeImage = (props: Props) => (
  <ChainImage {...props} isSmall />
);

export default TokenImage;
