import Navigation from 'types/Navigation';
import { PATH_LP_REWARD, PATH_POOL, PATH_STATS, PATH_SWAP } from './paths';

const NAVIGATIONS: Navigation[] = [
  {
    title: 'header.navigation.swap',
    path: PATH_SWAP,
  },
  {
    title: 'header.navigation.pool',
    path: PATH_POOL,
  },
  {
    title: 'header.navigation.lpReward',
    path: PATH_LP_REWARD,
  },
  {
    title: 'header.navigation.stats',
    path: PATH_STATS,
  },
];

export default NAVIGATIONS;
