export const TRANSITION_DURATION = '0.3s';
export const TRANSITION_DURATION_SHORT = '0.17s';
export const TRANSITION_TIMING_FUNCTION = 'cubic-bezier(0, 0.49, 0.46, 1.01)';

export const makeTransition = (property: string = 'all') => `
  -webkit-transition: ${property} ${TRANSITION_DURATION} ${TRANSITION_TIMING_FUNCTION} !important;
  -moz-transition: ${property} ${TRANSITION_DURATION} ${TRANSITION_TIMING_FUNCTION} !important;
  -ms-transition: ${property} ${TRANSITION_DURATION} ${TRANSITION_TIMING_FUNCTION} !important;
  -o-transition: ${property} ${TRANSITION_DURATION} ${TRANSITION_TIMING_FUNCTION} !important;
  transition: ${property} ${TRANSITION_DURATION} ${TRANSITION_TIMING_FUNCTION} !important;
`;

const TRANSITION = makeTransition();

export default TRANSITION;
