import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { ipAtom } from 'store';
import { getUserIp } from 'utils/ipAddress';

const useIp = (): string => {
  const [ip, setIp] = useAtom(ipAtom);

  useEffect(() => {
    if (window) {
      setIp(getUserIp());
    }
  }, [setIp]);

  return ip;
};

export default useIp;
