import TRANSITION from './transition';

const TRANSITION_SET_HOVER_TR_OVERLAY = `
  & > td {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    & > * {
      position: relative;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #ffffff00;
      ${TRANSITION}
      will-change: opacity;
    }
  }
  &:hover > td:before {
    background-color: #ffffff1a;
  }
  &:active > td:before {
    background-color: #ffffff0d;
  }
`;

export default TRANSITION_SET_HOVER_TR_OVERLAY;
