import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import NAVIGATIONS from 'configs/navigations';
import checkIsAvailablePathname from 'lib/devEnv/checkIsAvailablePathname';
import Navigation from 'types/Navigation';

const useAvailableNavigations = () => {
  const { t } = useTranslation('common');

  const navigations: Navigation[] = useMemo(
    () =>
      NAVIGATIONS.map((navigation) => ({
        ...navigation,
        title: t(navigation.title),
      })),
    [t]
  );
  const availableNavigations: Navigation[] = useMemo(
    () =>
      navigations.filter((navigation) =>
        checkIsAvailablePathname(navigation.path)
      ),
    [navigations]
  );

  return availableNavigations;
};

export default useAvailableNavigations;
