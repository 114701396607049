import styled from '@emotion/styled';
import { AnchorHTMLAttributes } from 'react';

export type Props = AnchorHTMLAttributes<HTMLAnchorElement> & {};

const Root = styled.a`
  color: inherit;
  text-decoration: underline;
  ${({ theme }) => theme.transitionSet.hoverOpacityButton}
`;

const UnderlineLinkButton: React.FC<Props> = (props) => <Root {...props} />;

export default UnderlineLinkButton;
