import { CollapseProps, Collapse as MuiCollapse } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import DEFAULT_TRANSITION_TIMEOUT from 'configs/defaultValue/defaultTransitionTimout';

export const DEFAULT_COLLAPSE_TIMEOUT = DEFAULT_TRANSITION_TIMEOUT;

const Collapse = React.forwardRef(function Transition(
  props: CollapseProps & PropsWithChildren,
  ref: React.Ref<unknown>
) {
  return (
    <MuiCollapse ref={ref} {...props} timeout={DEFAULT_COLLAPSE_TIMEOUT} />
  );
});

export default Collapse;
