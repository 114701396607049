import styled from '@emotion/styled';
import Link from 'next/link';
import { IconLogo } from 'components/icons';
import Tooltip from 'components/mui/Tooltip';
import { PATHS_WALLET_VIEWABLE } from 'configs/paths';
import { Z_HEADER } from 'configs/zIndex';
import useAvailableNavigations from 'hooks/useAvailableNavigations';
import useIsConnected from 'hooks/useIsConnected';
import useIsMaintaining from 'hooks/useIsMaintaining';
import useIsPath from 'hooks/useIsPath';
import useIsRootPath from 'hooks/useIsRootPath';
import ENV_BIHOLDER_API from 'lib/devEnv/envBiholderApi';
import ENV_CHAINRUNNER_API from 'lib/devEnv/envChainrunnerApi';
import ENV_EVERDEX_API from 'lib/devEnv/envEverdexApi';
import { NETWORK_TITLE } from 'lib/devEnv/envIsTestnet';
import IS_DEV from 'lib/devEnv/isDev';
import Notice from 'types/Notice';
import ConnectionWarning from './ConnectWarning';
import HeaderNotice from './HeaderNotice';
import MaintainingNow from './MaintainingNow';
import Navigator from './Navigator';
import DropdownChain from './dropdown/DropdownChain';
import DropdownConnectWallet from './dropdown/DropdownConnectWallet';
import DropdownSetting from './dropdown/DropdownSetting';
import DropdownWallet from './dropdown/DropdownWallet';

export type Props = {
  connectionErrorContent?: React.ReactNode;
  notice?: Notice;
  closeNotice: (id: string) => void;
};

const Root = styled.div<{
  isRoot?: boolean;
}>`
  position: fixed;
  top: 0px;
  background-color: ${({ isRoot, theme }) =>
    isRoot ? 'transparent' : theme.color.headerBackground};
  backdrop-filter: blur(10px);
  z-index: ${Z_HEADER};
  transition: transform ${({ theme }) => theme.transition.single};
`;
const HeaderContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  min-width: 1200px;
  max-width: 1600px;
  height: 120px;
  margin: 0 auto;
  padding: 0 16px;
  gap: 16px;
`;
const ContainerBase = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
const LogoContainer = styled(ContainerBase)`
  justify-content: flex-start;
`;
const NavigationContainer = styled(ContainerBase)`
  justify-content: center;
`;
const ActionContainer = styled(ContainerBase)`
  justify-content: flex-end;
`;
const LogoWrapper = styled.div<{ active?: boolean }>`
  min-width: 123px;
  transition: opacity ${({ theme }) => theme.transition.single};
  ${({ active, theme }) =>
    active ? '' : theme.transitionSet.hoverOpacityButton}
`;

const Header: React.FC<Props> = ({
  connectionErrorContent,
  notice,
  closeNotice,
}) => {
  const isRoot = useIsRootPath();
  const isWalletViewablePath = useIsPath(PATHS_WALLET_VIEWABLE);

  const isMaintaining = useIsMaintaining();

  const isConnected = useIsConnected();

  const availableNavigations = useAvailableNavigations();

  return (
    <Root isRoot={isRoot}>
      <ConnectionWarning connectionErrorContent={connectionErrorContent} />
      <HeaderNotice notice={notice} closeNotice={closeNotice} />
      <HeaderContent>
        <LogoContainer>
          <Link href="/">
            <LogoWrapper active={isRoot}>
              <IconLogo />
            </LogoWrapper>
          </Link>
          {IS_DEV ? (
            <Tooltip
              content={
                IS_DEV ? (
                  <>
                    <div>{NETWORK_TITLE}</div>
                    <div>everdex-api: {ENV_EVERDEX_API}</div>
                    <div>chainrunner-api: {ENV_CHAINRUNNER_API}</div>
                    <div>biholder-api: {ENV_BIHOLDER_API}</div>
                  </>
                ) : undefined
              }>
              <div>dev</div>
            </Tooltip>
          ) : (
            ''
          )}
        </LogoContainer>
        <NavigationContainer>
          <Navigator navigations={availableNavigations} />
        </NavigationContainer>
        <ActionContainer>
          {!isMaintaining && isConnected ? (
            <>
              {isWalletViewablePath ? <DropdownChain /> : ''}
              <DropdownWallet />
            </>
          ) : (
            <DropdownConnectWallet />
          )}
          <DropdownSetting />
        </ActionContainer>
      </HeaderContent>
      {isMaintaining && <MaintainingNow />}
    </Root>
  );
};

export default Header;
