import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import tokenPairTitle from 'lib/tokenPairTitle';
import TypeToken from 'types/Token';
import Token from './Token';
import Inline from './styled/Inline';

export type Props = {
  tokens: (TypeToken | string)[];
  size?: number;
  color?: string;
  isShowTitle?: boolean;
  gap?: number;
};

const Container = styled(Inline)`
  column-gap: ${({ theme }) => theme.gap.md};
  font-size: 16px;
  font-weight: 700;
`;
const SymbolContainer = styled(Inline)<{ length?: number }>`
  flex-direction: row-reverse;
  ${({ length }) =>
    length && length > 1 ? `margin-right: -${4 * (length - 1)}px;` : ''}
`;
const Title = styled.div``;

const SmallContainer = styled(Inline)`
  column-gap: ${({ theme }) => theme.gap.md};
  flex-direction: column;
  row-gap: ${({ theme }) => theme.gap.sm};
  align-items: flex-start;
  font-weight: 700;
`;
const SmallTitle = styled.div<{ color?: string; offset: number }>`
  width: calc(100% ${({ offset }) => offset}px);
  color: ${({ color, theme }) => (color ? color : theme.color.border)};
  font-size: 12px;
`;

const TokenPair: React.FC<Props> = ({
  tokens,
  size = 32,
  color,
  isShowTitle = true,
  gap = -4,
}) => {
  const [symbols, setSymbols] = useState<string[]>([]);

  useEffect(() => {
    if (!tokens?.length) {
      return;
    }

    setSymbols(
      tokens.map((t) => (typeof t === 'string' ? t : t.symbol)).reverse()
    );
  }, [tokens]);

  return (
    <Container>
      <SymbolContainer length={symbols.length}>
        {symbols.map((s, i) => (
          <Token
            symbol={s}
            size={size}
            transform={`translateX(${(symbols.length - i - 1) * gap}px)`}
            key={JSON.stringify(s)}
          />
        ))}
      </SymbolContainer>
      {isShowTitle && <Title style={{ color }}>{tokenPairTitle(tokens)}</Title>}
    </Container>
  );
};

export const SmallTokenPair: React.FC<Props> = ({
  tokens,
  size = 32,
  color,
  isShowTitle = true,
  gap = -4,
}) => {
  const [symbols, setSymbols] = useState<string[]>([]);

  useEffect(() => {
    if (!tokens?.length) {
      return;
    }

    setSymbols(
      tokens.map((t) => (typeof t === 'string' ? t : t.symbol)).reverse()
    );
  }, [tokens]);

  return (
    <SmallContainer>
      <SymbolContainer>
        {symbols.map((s, i) => (
          <Token
            symbol={s}
            size={size}
            transform={`translateX(${(symbols.length - i - 1) * gap}px)`}
            key={JSON.stringify(s)}
          />
        ))}
      </SymbolContainer>
      {isShowTitle && (
        <SmallTitle
          color={color}
          offset={(symbols.length - 1) * gap}
          style={{ color }}>
          {tokenPairTitle(tokens)}
        </SmallTitle>
      )}
    </SmallContainer>
  );
};

export default TokenPair;
