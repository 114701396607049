// explorer url
export const TESTNET_CHAIN_EXPLORER_URL_BIFROST =
  'https://explorer.testnet.thebifrost.io/';
export const TESTNET_CHAIN_EXPLORER_URL_ETHEREUM =
  'https://sepolia.etherscan.io/';
export const TESTNET_CHAIN_EXPLORER_URL_BASE_SEPOLIA =
  'https://sepolia.basescan.org/';
export const TESTNET_CHAIN_EXPLORER_URL_ARBITRUM_SEPOLIA =
  'https://sepolia.arbiscan.io/';
export const TESTNET_CHAIN_EXPLORER_URL_BSC = 'https://testnet.bscscan.com/';
export const TESTNET_CHAIN_EXPLORER_URL_POLYGON =
  'https://mumbai.polygonscan.com/';
export const TESTNET_CHAIN_EXPLORER_URL_BASE = 'https://goerli.basescan.org/';
export const TESTNET_CHAIN_EXPLORER_URL_ARBITRUM =
  'https://goerli.arbiscan.io/';
