import {
  isValidAddress,
  Transaction,
  useWallet,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import { useCallback, useMemo, useState } from 'react';
import Loading from 'components/Loading';
import TokenImage from 'components/styled/tokenImage';
import { TOKEN_BFC } from 'configs/tokens';
import useTokenImage from 'hooks/api/biholder/useTokenImage';
import useFirestore from 'hooks/useFirestore';
import useTransactionHistories from 'hooks/useTransactionHistories';
import { lpRewardProxyContract } from 'lib/contract';
import { formatAuto } from 'lib/contract/dex';
import uuid from 'lib/uuid';
import LpRewardPoolData from 'types/LpRewardPoolData';
import { getIsUserReject } from 'utils/chainrunner';
import { openErrorToast } from 'utils/toastUtils';
import { Props as DialogProps } from '.';
import DialogConfirm from './DialogConfirm';

export type Props = DialogProps & {
  amount?: string;
  lpRewardPoolData?: LpRewardPoolData;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 8px 0px;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
`;
const Text = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
`;

const DialogLpStakeClaim: React.FC<Props> = ({
  open,
  amount,
  lpRewardPoolData,
  onClose: handleClose,
}) => {
  const { t } = useTranslation('dialog');

  const { addPoolAction } = useFirestore();

  const { addTransaction } = useTransactionHistories();

  const { account, wallet } = useWallet();

  const tokenBfcImage = useTokenImage(TOKEN_BFC);

  const [isLoading, setIsLoading] = useState(false);

  const proxyAddress = useMemo(
    () => lpRewardPoolData?.rewardContract?.proxyAddress,
    [lpRewardPoolData?.rewardContract?.proxyAddress]
  );
  const tokens = useMemo(
    () => lpRewardPoolData?.tokens,
    [lpRewardPoolData?.tokens]
  );
  const title = useMemo(() => t('lpStakeClaim.title'), [t]);
  const content = useMemo(
    () => (
      <Container>
        <Content>
          <TokenImage
            size={48}
            image={tokenBfcImage}
            symbol={TOKEN_BFC.symbol}
          />
          <div>{amount ? formatAuto(amount, 'token-short') : '-'} BFC</div>
        </Content>
        <Text>
          {(t('lpStakeClaim.content') as string)
            .split('\n')
            .map((line, lineIndex) => (
              <div key={lineIndex}>{line}</div>
            ))}
        </Text>
      </Container>
    ),
    [amount, t, tokenBfcImage]
  );

  const claim = useCallback(async () => {
    if (
      !(
        account &&
        isValidAddress(account) &&
        proxyAddress &&
        isValidAddress(proxyAddress)
      ) ||
      isLoading
    ) {
      return;
    }

    setIsLoading(true);

    const storeId = uuid();
    const type = 'lpRewardClaim';
    const amounts = [`${amount}`];

    try {
      const transaction: Transaction = await lpRewardProxyContract.getReward({
        wallet,
        to: proxyAddress,
      });

      const transactionHash = transaction.hash;
      addPoolAction?.({
        type,
        id: storeId,
        amounts,
        transactionHash,
      });
      await addTransaction?.(
        {
          ...transaction,
          type,
          tokens,
          amounts,
        },
        {
          process: t('common:message.lpReward.claim.process'),
          success: t('common:message.lpReward.claim.success'),
        }
      );
      addPoolAction?.({
        type,
        id: storeId,
        amounts,
        transactionHash,
        complete: true,
      });

      handleClose?.({}, 'flowFinish');
    } catch (error) {
      if (getIsUserReject(error)) {
        return;
      }

      addPoolAction?.({
        type,
        id: storeId,
        amounts,
        error,
      });
      // handle out of slippage
      openErrorToast(error);
    } finally {
      setIsLoading(false);
    }

    handleClose?.({}, 'closeClick');
  }, [
    account,
    addPoolAction,
    addTransaction,
    amount,
    handleClose,
    isLoading,
    proxyAddress,
    t,
    tokens,
    wallet,
  ]);

  const handleConfirm = useCallback(() => {
    claim();
  }, [claim]);

  const confirmProps = useMemo(
    () => ({
      disabled: isLoading,
      children: isLoading ? <Loading /> : t('lpStakeClaim.action'),
      onClick: handleConfirm,
    }),
    [handleConfirm, isLoading, t]
  );

  return (
    <DialogConfirm
      open={open}
      width={360}
      title={title}
      content={content}
      confirmProps={confirmProps}
      onClose={handleClose}
    />
  );
};

export default DialogLpStakeClaim;
