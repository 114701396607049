import {
  compareLowerStr,
  useWallet,
  formatDecimal,
  isNormalPositive,
  UnknownNumber,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import BN from 'bignumber.js';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MAX_VALUE_UINT256 } from 'configs/maxValue';
import TOKENS from 'configs/tokens';
import useFirestore from 'hooks/useFirestore';
import useIsBifrostChain from 'hooks/useIsBifrostChain';
import useIsConnected from 'hooks/useIsConnected';
import useTransactionHistories from 'hooks/useTransactionHistories';
import { sendWithSendParams } from 'lib/contract/dex';
import {
  callTokenAllowance,
  getApproveEstimatedGas,
  getApproveSendData,
} from 'lib/contract/erc20';
import log from 'lib/log';
import uuid from 'lib/uuid';
import { TransactionHistoryCategory } from 'types/TransactionHistory';
import { getIsUserReject } from 'utils/chainrunner';
import { getIsCoinAddress } from 'utils/stringUtils';
import { openErrorToast } from 'utils/toastUtils';

const useApprove = (
  tokenAddress?: string,
  spender?: string,
  amountToUse?: BN
) => {
  const { t } = useTranslation('common');
  const { wallet, account: owner } = useWallet();
  const isConnected = useIsConnected();
  const isBifrostChain = useIsBifrostChain();

  const { addCommonAction } = useFirestore();

  const { addTransaction } = useTransactionHistories();

  const [allowance, setAllowance] = useState(new BN(0));

  const isCoin = useMemo(
    () => !tokenAddress || getIsCoinAddress(tokenAddress),
    [tokenAddress]
  );
  const isApproved = useMemo(
    () => (isCoin ? true : allowance.gte(amountToUse ?? 0)),
    [allowance, amountToUse, isCoin]
  );

  const updateAllowance = useCallback(async () => {
    if (!(isConnected && isBifrostChain && tokenAddress && spender)) return;

    try {
      const newAllowance = await callTokenAllowance(
        tokenAddress,
        spender,
        wallet
      );
      setAllowance(newAllowance);
    } catch (error) {
      openErrorToast(error);
    }
  }, [isBifrostChain, isConnected, spender, tokenAddress, wallet]);
  const approve = useCallback(
    async (
      category: TransactionHistoryCategory,
      options?: { reset?: boolean; amount?: UnknownNumber }
    ) => {
      if (!(owner && tokenAddress && spender)) return false;

      const storeId = uuid();
      const type = 'approve';
      const tokens = TOKENS.filter((token) =>
        compareLowerStr(token.address, tokenAddress)
      );
      const amount = options?.reset
        ? '0'
        : options?.amount && isNormalPositive(options?.amount, true)
          ? formatDecimal(options.amount, 0)
          : MAX_VALUE_UINT256;
      const amounts = [`${amount}`];

      try {
        const { sendParams, encodedParams } = await getApproveSendData(
          tokenAddress,
          { from: owner, to: spender, amount }
        );
        const gasLimit = await getApproveEstimatedGas(wallet, encodedParams);
        const tx = await sendWithSendParams(
          wallet,
          sendParams,
          undefined,
          gasLimit
        );

        const transactionHash = tx.hash;
        addCommonAction?.(category, {
          type,
          id: storeId,
          tokens,
          amounts,
          transactionHash,
        });
        await addTransaction?.(
          {
            ...tx,
            type,
            tokens,
            amounts,
          },
          {
            process: t('common:message.approve.process'),
            success: t('common:message.approve.success'),
          }
        );
        addCommonAction?.(category, {
          type,
          id: storeId,
          tokens,
          amounts,
          transactionHash,
          complete: true,
        });

        await updateAllowance();

        return true;
      } catch (error) {
        if (getIsUserReject(error)) {
          return false;
        }

        log(error);
        addCommonAction?.(category, {
          type,
          id: storeId,
          tokens,
          amounts,
          error,
        });
        openErrorToast(error);
        return false;
      }
    },
    [
      owner,
      tokenAddress,
      spender,
      wallet,
      addCommonAction,
      addTransaction,
      t,
      updateAllowance,
    ]
  );

  useEffect(() => {
    if (!(owner && isConnected && isBifrostChain)) return;

    updateAllowance();
  }, [updateAllowance, owner, isConnected, isBifrostChain]);

  return {
    isApproved,
    allowance,
    approve,
  };
};

export default useApprove;
