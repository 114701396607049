import IS_TESTNET from 'lib/devEnv/envIsTestnet';
import {
  MAINNET_CHAIN_NAME_BIFROST,
  MAINNET_CHAIN_NAME_ETHEREUM,
  MAINNET_CHAIN_NAME_BSC,
  MAINNET_CHAIN_NAME_POLYGON,
  MAINNET_CHAIN_NAME_BASE,
} from './mainnet/chainName';
import {
  TESTNET_CHAIN_NAME_BIFROST,
  TESTNET_CHAIN_NAME_ETHEREUM,
  TESTNET_CHAIN_NAME_BSC,
  TESTNET_CHAIN_NAME_POLYGON,
  TESTNET_CHAIN_NAME_BASE,
} from './testnet/chainName';

export const CHAIN_NAME_BIFROST = IS_TESTNET
  ? TESTNET_CHAIN_NAME_BIFROST
  : MAINNET_CHAIN_NAME_BIFROST;
export const CHAIN_NAME_ETHEREUM = IS_TESTNET
  ? TESTNET_CHAIN_NAME_ETHEREUM
  : MAINNET_CHAIN_NAME_ETHEREUM;
export const CHAIN_NAME_BSC = IS_TESTNET
  ? TESTNET_CHAIN_NAME_BSC
  : MAINNET_CHAIN_NAME_BSC;
export const CHAIN_NAME_POLYGON = IS_TESTNET
  ? TESTNET_CHAIN_NAME_POLYGON
  : MAINNET_CHAIN_NAME_POLYGON;
export const CHAIN_NAME_BASE = IS_TESTNET
  ? TESTNET_CHAIN_NAME_BASE
  : MAINNET_CHAIN_NAME_BASE;

const CHAIN_NAMES = [
  CHAIN_NAME_BIFROST,
  CHAIN_NAME_ETHEREUM,
  CHAIN_NAME_BSC,
  CHAIN_NAME_POLYGON,
  CHAIN_NAME_BASE,
];

export default CHAIN_NAMES;
