import {
  compareLowerStr,
  isValidAddress,
  useWallet,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import { AxiosResponse } from 'axios';
import { useAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { API_PATH_EVERDEX_LIQUIDITY_POSITIONS } from 'configs/apiPath';
import { CHAIN_ID_BIFROST } from 'configs/chains/chainIds';
import EXCEPT_PAIR_ADDRESSES from 'configs/exceptPairs';
import { everdex } from 'lib/axiosInstance';
import { isLoadingPositionsAtom, positionsAtom } from 'store/pool';
import Position from 'types/api/everdex/Position';
import ResponseEverdex from 'types/api/everdex/ResponseEverdex';
import ResponseEverdexList from 'types/api/everdex/ResponseEverdexList';

export const getEverdexPositions = (
  address: string,
  cursor?: string
): Promise<AxiosResponse<ResponseEverdex<ResponseEverdexList<Position>>>> =>
  everdex.get<ResponseEverdex<ResponseEverdexList<Position>>>(
    `${API_PATH_EVERDEX_LIQUIDITY_POSITIONS.replace('{chainId}', `${CHAIN_ID_BIFROST}`).replace('{address}', address)}?limit=10&${cursor ? `cursor=${cursor}` : ''}`
  );

export const getPositions = async (address: string): Promise<Position[]> => {
  const positions: Position[] = [];

  let cursor: string | undefined;

  const {
    data: { data },
  } = await getEverdexPositions(address);

  positions.push(...(data?.items ?? []));

  cursor = data?.cursor;

  while (cursor) {
    const {
      data: { data },
    } = await getEverdexPositions(address, cursor);

    positions.push(...(data?.items ?? []));

    cursor = data?.cursor;
  }

  return positions;
};

const usePoolPositions = () => {
  const { account } = useWallet();

  const [positions, setPositions] = useAtom(positionsAtom);
  const [isLoading, setIsLoading] = useAtom(isLoadingPositionsAtom);

  const filteredPositions = useMemo(
    () =>
      account && isValidAddress(account)
        ? positions.filter(
            (position) =>
              !EXCEPT_PAIR_ADDRESSES.find((address) =>
                compareLowerStr(address, position.poolAddress)
              )
          )
        : [],
    [account, positions]
  );

  const sync = useCallback(async () => {
    if (!(!isLoading && account && isValidAddress(account))) {
      return;
    }

    setIsLoading(true);

    try {
      const newPositions = await getPositions(account);

      if (newPositions.length) {
        setPositions(newPositions);
      }
    } catch (error) {}

    setIsLoading(false);
  }, [account, isLoading, setIsLoading, setPositions]);

  return {
    positions: filteredPositions,
    isLoading,
    sync,
  };
};

export default usePoolPositions;
