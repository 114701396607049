/* eslint-disable no-console */
import * as Sentry from '@sentry/nextjs';
import IS_DEV from './devEnv/isDev';

const log = IS_DEV ? console.log : (...data: any[]) => {};

export const logWran = IS_DEV ? console.warn : (...data: any[]) => {};

export const logError = IS_DEV
  ? console.error
  : (...data: any[]) => {
      Sentry.captureException(data);
    };

export default log;
