import {
  compareLowerStr,
  formatDecimal,
  isValidAmount,
} from '@bifrost-platform/bifront-sdk-react-wallet';
import { ADD_EMPTY } from 'configs/address';
import { TokenAddress } from 'types/Token';

export const formatValueString = (value: string, decimal: number = 18) => {
  if (value === '') {
    return value;
  }
  const validateResult = isValidAmount(value, decimal);
  if (
    validateResult.valid ??
    (validateResult.code === 4 && Number(value) === 0)
  ) {
    if (value.includes('.')) {
      const [integerPlaces, decimalPlaces] = value.split('.');
      return `${integerPlaces}${
        decimalPlaces ? `.${decimalPlaces.slice(0, decimal)}` : ''
      }`;
    } else {
      return value.replaceAll('e', '').replaceAll('-', '');
    }
  }
  return formatDecimal(value, decimal);
};

export const getIsCoinAddress = (address?: TokenAddress) =>
  compareLowerStr(address ?? '', ADD_EMPTY);
