import { Wallet } from '@bifrost-platform/bifront-sdk-react-wallet';
import { FRAG_STABLE_LP_GET_VIRTUAL_PRICE } from 'configs/fragment';
import { call, pickNumberResponse } from 'lib/contract/dex';
import { ContractFunctionCaller } from 'types/ContractFunction';

export const getVirtualPrice: ContractFunctionCaller = async (option, args) =>
  call({ ...option, fragment: FRAG_STABLE_LP_GET_VIRTUAL_PRICE }, args);

// get_virtual_price
export const callStableLpGetVirtualPrice = async (
  tokenAddress: string,
  wallet?: Wallet,
  onlyBifrost?: boolean
) =>
  pickNumberResponse(
    await getVirtualPrice({ wallet, to: tokenAddress, onlyBifrost })
  );
